import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_USERS_LIST, SET_USER_SETTINGS } from "../actions/types";
import { setUsersList, setUsersListPage, setLoading } from "../actions";
import { getUsersList, updateUserSettingsApi } from "../api/handler";

function* handleLoadUsersList(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getUsersList, args.param);
    if (response.status === 200) {
      yield put(setUsersList(response.data.data));
      yield put(
        setUsersListPage({
          totalRecords: response.data.totalRecords,
          totalPages: response.data.totalPages
        })
      );
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleSetUserSettings({ userSettings }) {
  yield put(setLoading(true));
  try {
    yield call(updateUserSettingsApi, userSettings);
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

export default function* usersSaga() {
  yield all([
    takeLatest(GET_USERS_LIST, handleLoadUsersList),
    takeLatest(SET_USER_SETTINGS, handleSetUserSettings)
  ]);
}
