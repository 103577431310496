import { takeEvery, call, put, all } from "redux-saga/effects";
import { GET_VEHICLE_SELECTOR_LIST } from "../actions/types";
import {
  setLoading,
  setVehicleSelectorList,
  setVehicleSelectorListPage
} from "../actions";
import { getVehicleSelectorList } from "../api/handler";

function* handleLoadVehicleSelectorList(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getVehicleSelectorList, args.mode, args.param);
    if (response.status === 200) {
      yield put(setVehicleSelectorList(args.mode, response.data.data));
      yield put(
        setVehicleSelectorListPage(
          {
            totalRecords: response.data.totalRecords,
            totalPages: response.data.totalPages,
            currentTotalRecords: response.data.currentTotalRecords
          },
          args.mode
        )
      );
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

export default function* vehicleSelectorSaga() {
  yield all([
    takeEvery(GET_VEHICLE_SELECTOR_LIST, handleLoadVehicleSelectorList)
  ]);
}
