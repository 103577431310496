import React, { cloneElement, useState } from "react";
import "../../styles/campaigns/campaignLeads.css";
import { Dialog } from "@progress/kendo-react-dialogs";
import { getIcon } from "../../utils/iconUtils";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import fetchClient from "../../api/fetch";
import { getStatusDropdown } from "../../utils/statusUtil";
import statusData from "../shared/statusData";

const LeadsBulkAction = ({
  closeDialog,
  leadIds,
  setLoading,
  queryBuilder,
  getCampaignLeadsList,
  setSelectedLeads,
  filter,
  unselected,
  setUnselected,
  id,
  currentUser
}) => {
  const { t } = useTranslation();
  const [specialists, setSpecialists] = useState([]);
  const [payload, setPayload] = useState({
    leadIds,
    assignTo: null,
    status: null,
    campaignId: id
  });

  const [dropdownValues, setDropdownValues] = useState({
    assignTo: {
      userid: 5,
      userFullName: getTranslation("Select", t)
    },
    status: {
      status: getTranslation("Select", t),
      value: 0
    }
  });

  const handleOpenSpecialists = () => {
    fetchClient()
      .get("/v1/users/specialists")
      .then((res) => {
        setSpecialists(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const handleDropdownChange = (e, id) => {
    setPayload({ ...payload, [e.target.name]: e.target.value[id] });
    setDropdownValues({ ...dropdownValues, [e.target.name]: e.target.value });
  };

  const handleDropDownChangeStatus = (e) => {
    const selectedValue = e.target.value;
    const translatedStatus = getTranslation(selectedValue.status, t);
  
    handleDropdownChange(e,"userId");
    handleDropdownChange(e,"value");
    setDropdownValues({
      ...dropdownValues,
      status: { ...selectedValue, status: translatedStatus },
      classKey: selectedValue.classKey,
      value: selectedValue.value,
    });
  };

  const handleAccept = () => {
    let params = "";
    if (
      payload.leadIds.length === 0 &&
      (filter.length > 0 || unselected.length > 0)
    ) {
      params += `?filters=[${filter}]&leadId=[${unselected.map(
        (item) => '"' + item + '"'
      )}]`;
    }
    setLoading(true);
    fetchClient()
      .put("/v1/leads/bulkaction" + params, payload)
      .then((res) => getCampaignLeadsList(id, queryBuilder()))
      .catch((err) => console.log(err))
      .finally(() => {
        setSelectedLeads([""]);
        setUnselected([]);
        setLoading(false);
      });
    closeDialog();
  };

  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <p
        className={`mb-0 ${getStatusDropdown(
          itemProps.dataItem.status
        )} bulk-status`}
      >
        {getTranslation(itemProps.dataItem.status, t)}
      </p>
    );

    return cloneElement(li, li.props, itemChildren);
  };

  return (
    <Dialog className="campaign-bulk-action">
      <div className="d-flex flex-column gap-4 p-3">
        <img
          className="align-self-end"
          onClick={closeDialog}
          src={getIcon("close.svg")}
          alt="close icon"
        />
        <h1 className="h1 mb-4 text-center">
          {getTranslation("Bulk actions", t)}
        </h1>
        <div className="d-flex flex-column inputs">
          <div className="d-flex flex-column gap-2">
            <label>{getTranslation("Assign to", t)}</label>
            <DropDownList
              name="assignTo"
              value={dropdownValues.assignTo}
              onOpen={handleOpenSpecialists}
              data={specialists}
              onChange={(e) => handleDropdownChange(e, "userId")}
              textField="userFullName"
              disabled={currentUser.userInfo.accessLevel === 1}
            />
          </div>
          <div>
            <label>{getTranslation("Set status to", t)}</label>
            <DropDownList
              name="status"
              data={statusData}
              textField="status"
              value={dropdownValues.status}
              onChange={handleDropDownChangeStatus}
              itemRender={itemRender}
            />
          </div>
        </div>
        <div className="btn-group d-flex justify-content-center gap-3 mt-2">
          <button className="bg-none-btn" onClick={closeDialog}>
            {getTranslation("Cancel", t)}
          </button>
          <button
            className="primary-btn"
            onClick={handleAccept}
            disabled={!payload.assignTo && !payload.status}
          >
            {getTranslation("Accept", t)}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default LeadsBulkAction;
