import { Navigate } from "react-router-dom";

const ProtectedRoute = (props) => {
  const { identifier, children, currentUser, path, action } = props;
  let shouldAccess = false;
  switch (identifier) {
    case "level":
      shouldAccess = currentUser.userInfo.accessLevel === 6;
      break;
    case "access":
      shouldAccess = currentUser.menu.includes(path);
      break;
    case "permission":
      shouldAccess = currentUser.permissions.find(
        (item) => item.moduleName === path
      )[action];
      break;
    default:
      break;
  }

  if (!shouldAccess) {
    return <Navigate to="/dashboard" {...props} />;
  }
  return children;
};

export default ProtectedRoute;
