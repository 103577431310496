/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from "@progress/kendo-react-indicators";
import "../../styles/shared/loadingPage.css";
import { useLocation } from "react-router-dom";
import { createPortal } from "react-dom";
import { useEffect, useRef } from "react";

const LoadingPage = ({ isLoading, loadingCount, dashboardLoadType }) => {
  const location = useLocation();

  const fullPages = [
    "/dashboard",
    "/service-alerts",
    "/search-module",
    "/portfolio",
    "/customer-record",
    "/reports/usage-report",
    "/reports/conversion-report"
  ];

  const grid = useRef(document.querySelector(".k-grid-container"));

  const getDomNode = () => {
    let targetDOM;
    grid.current = document.querySelector(".k-grid-container");

    if (
      !loadingCount &&
      (fullPages.includes(location.pathname) ||
        location.pathname.includes("/edit-proposed-deal") ||
        location.pathname.includes("/customer-record"))
    ) {
      targetDOM = document.querySelector(".main-contents");
    } else if (loadingCount && location.pathname === "/dashboard") {
      if (dashboardLoadType === "campaigns") {
        targetDOM = document.querySelector(
          ".dashboard-campaign .k-grid-container"
        );
      } else if (dashboardLoadType === "performance") {
        targetDOM = document.querySelector(
          ".dashboard-performance .dashboard-performance-container"
        );
      } else {
        targetDOM = document.querySelector(
          ".dashboard-tasks .k-grid-container"
        );
      }
    } else if (
      loadingCount &&
      location.pathname === "/reports/conversion-report" &&
      !grid.current
    ) {
      targetDOM = document.querySelector(".conversion-report-chart");
    } else if (
      loadingCount &&
      location.pathname === "/reports/usage-report" &&
      !grid.current
    ) {
      targetDOM = document.querySelector(".usage-chart-box .usage-chart-cont");
    } else if (grid.current === null) {
      targetDOM = document.querySelector(".main-contents");
    } else {
      targetDOM = document.querySelector(".k-grid-container");
    }
    return targetDOM;
  };

  useEffect(() => {
    getDomNode();
  }, [isLoading]);

  const loading = (
    <div
      className={`loading ${
        (!grid.current &&
          location.pathname !== "/reports/conversion-report" &&
          location.pathname !== "/reports/usage-report") ||
        ((fullPages.includes(location.pathname) ||
          location.pathname.includes("/edit-proposed-deal") ||
          location.pathname.includes("/customer-record")) &&
          !loadingCount)
          ? "full"
          : "grid"
      }`}
    >
      <Loader type="converging-spinner" />
    </div>
  );
  return getDomNode() ? createPortal(loading, getDomNode()) : null;
};

export default LoadingPage;
