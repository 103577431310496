import React from "react";
import "../../styles/shared/header.css";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const MarketBrandDialog = ({
  handleMarketChange,
  handleBrandChange,
  handleMarketDropdownOpen,
  handleBrandDropdownOpen,
  marketData,
  brandData,
  selectedMarket,
  selectedBrand,
  selectedHeader,
  currentSettings,
  onCancel,
  onApply,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog>
        <div className="marketBrand-dialog-box">
          <span className="dialog-title">
            {getTranslation("Change market and brand", t)}
          </span>
          <div className="market-dropdown">
            <p>{getTranslation("Market", t)}</p>
            <DropDownList
              className="market-dropdown-list"
              name="market"
              data={marketData}
              textField="marketDescription"
              dataItemKey="marketId"
              value={selectedMarket}
              onChange={(e) => {
                handleMarketChange(e);
              }}
              onOpen={handleMarketDropdownOpen}
              disabled={selectedHeader === "brand"}
            />
          </div>

          <div className="brand-dropdown">
            <p>{getTranslation("Brand", t)}</p>
            <DropDownList
              className="brand-dropdown-list"
              name="brand"
              data={brandData}
              dataItemKey="brandId"
              textField="brandDescription"
              value={selectedBrand}
              onChange={(e) => {
                handleBrandChange(e);
              }}
              onOpen={handleBrandDropdownOpen}
            />
          </div>
          <div className="dialog-footer">
            <div>
              <button className="dialog-btn bg-none-btn" onClick={onCancel}>
                {getTranslation("Cancel", t)}
              </button>
            </div>
            <div>
              <button
                className="dialog-btn primary-btn"
                onClick={onApply}
                disabled={
                  (selectedHeader === "market"
                    ? currentSettings.marketId === selectedMarket.marketId
                    : selectedHeader === "brand"
                    ? currentSettings.brandId === selectedBrand.brandId
                    : false) || selectedBrand.brandDescription === "Select"
                }
              >
                {getTranslation("Accept", t)}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default MarketBrandDialog;
