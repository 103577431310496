import React from "react";
import "../../styles/reports/reports.css";
import usageIcon from "../../assets/icons/usage-icon.svg";
import funnelIcon from "../../assets/icons/funnel-icon.svg";
import { useNavigate } from "react-router-dom";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const Reports = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  return (
    <div className="reports">
      <p className="h1 mb-4">{getTranslation("Reports", t)}</p>

      <div className="d-flex flex-column gap-3">
        <button
          className="reports-btn conversion-report-module"
          onClick={() => Navigate("/reports/conversion-report")}
        >
          <img src={funnelIcon} alt="funnel icon" />
          <span>{getTranslation("Conversion report", t)}</span>
        </button>
        <button
          className="reports-btn usage-report-module"
          onClick={() => Navigate("/reports/usage-report")}
        >
          <img src={usageIcon} alt="usage icon" />
          <span>{getTranslation("Usage report", t)}</span>
        </button>
      </div>
    </div>
  );
};

export default Reports;
