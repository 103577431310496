import React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { getIcon } from "../../utils/iconUtils";
import "../../styles/settings/settings.css";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const SettingsModalConfirmation = ({ handleProceedSave, handleCancelSave }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog>
        <div className="settings-modal-confirmation d-flex flex-column">
          <img
            className="close"
            onClick={handleCancelSave}
            src={getIcon("close.svg")}
            alt="close icon"
          />
          <p>{getTranslation("Do you want to save the changes made?", t)}</p>
          <span className="text-center">
          {getTranslation("All of the finance calculations for leads already in campaign will be modified.", t)}
          </span>
          <div className="button-group d-flex justify-content-evenly">
            <button className="secondary-btn" onClick={handleCancelSave}>
            {getTranslation("Cancel", t)}
            </button>
            <button className="primary-btn" onClick={handleProceedSave}>
              {getTranslation("Proceed", t)}
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SettingsModalConfirmation;
