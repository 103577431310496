import { takeLatest, all, call, put } from "redux-saga/effects";
import { getSearchModuleListApi } from "../api/handler";
import { setLoading, setLoadingCount, setSearchModuleList, setSearchModuleListPage } from "../actions";
import { GET_SEARCH_MODULE_LIST } from "../actions/types";

function* handleLoadSearchModuleList(args) {
    yield put(setLoading(true));
    try {
      const response = yield call(getSearchModuleListApi, args.param);
      if (response.status === 200) {
        yield put(setSearchModuleList(response.data.data));
        yield put(
          setSearchModuleListPage({
            totalRecords: response.data.totalRecords,
            totalPages: response.data.totalPages
          })
        );
      }
    } catch (e) {
      console.warn(e);
    }
    yield put(setLoading(false));
    yield put(setLoadingCount(1));
}

export default function* searchModuleSaga() {
  yield all([takeLatest(GET_SEARCH_MODULE_LIST, handleLoadSearchModuleList)]);
}
