import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  GET_CAMPAIGN_LIST,
  GET_CAMPAIGN_LEADS_LIST,
  SET_CAMPAIGN_LEADS
} from "../actions/types";
import {
  setCampaignList,
  setCampaignListPage,
  setCampaignLeadsList,
  setCampaignLeadsListPage,
  setLoading,
} from "../actions";
import {
  getCampaignList,
  getCampaignLeadsList,
  saveCampaignLeadsApi
} from "../api/handler";

function* handleLoadCampaignList(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getCampaignList, args.param);
    if (response.status === 200) {
      yield put(setCampaignList(response.data.data));
      yield put(
        setCampaignListPage({
          totalRecords: response.data.totalRecords,
          totalPages: response.data.totalPages
        })
      );
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleLoadCampaignLeadsList({ campaignId, param }) {
  yield put(setLoading(true));
  try {
    const response = yield call(getCampaignLeadsList, campaignId, param);
    if (response.status === 200) {
      yield put(setCampaignLeadsList(response.data.data));
      yield put(
        setCampaignLeadsListPage({
          totalRecords: response.data.totalRecords,
          totalPages: response.data.totalPages
        })
      );
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleSaveCampaignLeads({ data }) {
  yield put(setLoading(true));
  try {
    yield call(saveCampaignLeadsApi, data);
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

export default function* campaignSaga() {
  yield all([
    takeLatest(GET_CAMPAIGN_LIST, handleLoadCampaignList),
    takeLatest(GET_CAMPAIGN_LEADS_LIST, handleLoadCampaignLeadsList),
    takeLatest(SET_CAMPAIGN_LEADS, handleSaveCampaignLeads)
  ]);
}
