import React, { useState } from "react";
import "../../styles/shared/header.css";
import { getIcon } from "../../utils/iconUtils";
import LanguageDialog from "./LanguageDialog";
import MarketBrandDialog from "./MarketBrandDialog";
import SavingModal from "./SavingModal";
import { useTranslation } from "react-i18next";
import Cookie from "../../api/Cookies";
import { useNavigate } from "react-router-dom";

const Header = ({
  getHeaderUserMarkets,
  getHeaderUserBrands,
  headerUserMarkets,
  headerUserBrands,
  currentUser,
  updateUserSettings,
  isLoading
}) => {
  // Set selected language from LocalStorage
  let selectedLng = localStorage.getItem("lng") || "en";
  let selectedLanguage = "";
  switch (selectedLng) {
    case "lva":
      selectedLanguage = "Latvian";
      break;
    case "est":
      selectedLanguage = "Estonian";
      break;
    case "ltu":
      selectedLanguage = "Lithuanian";
      break;
    case "en":
      selectedLanguage = "English";
      break;
    case "hk":
      selectedLanguage = "Traditional Chinese";
      break;
      case "bg":
      selectedLanguage = "Bulgarian";
      break;
    case "esp":
      selectedLanguage = "Spanish";
      break;
    default:
      selectedLanguage = "English";
      break;
  }
  const { i18n } = useTranslation();
  const Navigation = useNavigate();
  //useStates
  const [languageVisible, setLanguageVisible] = useState(false);
  const [marketBrandVisible, setMarketBrandVisible] = useState(false);
  const [selectedHeader, setSelectedHeader] = useState("");
  const [dropdownState, setDropdownState] = useState({
    language: selectedLanguage
  });
  const [selectedMarket, setSelectedMarket] = useState({
    subdomain: currentUser.settings.subdomain,
    marketDescription: currentUser.settings.marketDescription,
    marketId: currentUser.settings.marketId
  });
  const [selectedBrand, setSelectedBrand] = useState({
    brandDescription: currentUser.settings.brandDescription,
    brandId: currentUser.settings.brandId
  });
  const [dialogValues, setDialogValues] = useState({
    back: false
  });

  //Language Dummy Data
  const languageData = [
    {
      language: "English",
      id: 1
    },
    {
      language: "Latvian",
      id: 2
    },
    {
      language: "Lithuanian",
      id: 3
    },
    {
      language: "Estonian",
      id: 4
    },
    {
      language: "Traditional Chinese",
      id: 5
    },
    {
      language: "Bulgarian",
      id: 6
    },
    {
      language: "Spanish",
      id: 7
    }
  ];

  //Handle Language Toggle Dialog
  const toggleLanguageDialog = () => {
    setLanguageVisible(!languageVisible);
    setDropdownState({
      ...dropdownState,
      language: selectedLanguage
    });
  };
  //Handle Market and Brand Toggle Dialog
  const toggleMarketBrandDialog = () => {
    setMarketBrandVisible(!marketBrandVisible);
    setSelectedMarket({
      subdomain: currentUser.settings.subdomain,
      marketDescription: currentUser.settings.marketDescription,
      marketId: currentUser.settings.marketId
    });
    setSelectedBrand({
      brandDescription: currentUser.settings.brandDescription,
      brandId: currentUser.settings.brandId
    });
  };
  //Handle changing language value
  const handleLanguageChange = (e) => {
    setDropdownState({
      ...dropdownState,
      language: e.target.value.language
    });
  };

  //Handle accept language value
  const handleAcceptLanguage = () => {
    toggleLanguageDialog();
    let lng = "";
    switch (dropdownState.language) {
      case "Latvian":
        lng = "lva";
        break;
      case "Estonian":
        lng = "est";
        break;
      case "Lithuanian":
        lng = "ltu";
        break;
      case "Traditional Chinese":
        lng = "hk";
        break;
      case "Bulgarian":
        lng = "bg";
        break;
      case "Spanish":
          lng = "esp";
          break;
      default:
        lng = "en";
        break;
    }
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  //handle Market and Brand modal Cancel button
  const handleMarketBrandCancel = () => {
    setSelectedMarket({
      subdomain: currentUser.settings.subdomain,
      marketDescription: currentUser.settings.marketDescription,
      marketId: currentUser.settings.marketId
    });
    setSelectedBrand({
      brandDescription: currentUser.settings.brandDescription,
      brandId: currentUser.settings.brandId
    });

    toggleMarketBrandDialog();
  };

  //handle Market and Brand modal Apply button
  const handleMarketBrandApply = (e) => {
    setMarketBrandVisible(!marketBrandVisible);
    setDialogValues({ ...dialogValues, back: true });
  };

  //Handle No button in Modal Saving
  const handleModalNo = () => {
    setDialogValues({ ...dialogValues, back: false });
    setSelectedMarket({
      subdomain: currentUser.settings.subdomain,
      marketDescription: currentUser.settings.marketDescription,
      marketId: currentUser.settings.marketId
    });
    setSelectedBrand({
      brandDescription: currentUser.settings.brandDescription,
      brandId: currentUser.settings.brandId
    });
  };

  //Handle Yes button in Modal Saving
  const handleModalSaving = () => {
    const settings = currentUser.settings;
    let payload = {
      userSettingsId: settings.userSettingsId,
      userId: currentUser.userId,
      brandId: selectedBrand.brandId
    };

    if (selectedHeader === "market") {
      payload.marketId = selectedMarket.marketId;
    }
    setDialogValues({ ...dialogValues, back: false });
    updateUserSettings(JSON.stringify(payload));
    if (!isLoading) {
      Cookie.set("market", selectedMarket.subdomain);
      Navigation("/dashboard");
      window.location.reload();
    }
  };

  const handleMarketDropdownOpen = (e) => {
    getHeaderUserMarkets();
  };

  const handleBrandDropdownOpen = (e) => {
    getHeaderUserBrands(selectedMarket.marketId);
  };

  //Handle changing Market value
  const handleMarketChange = (e) => {
    if (e.target.name === "market") {
      setSelectedMarket(e.target.value);
      setSelectedBrand({ brandId: 0, brandDescription: "Select" });
    }
  };

  //Handle changing brand value
  const handleBrandChange = (e) => {
    if (e.target.name === "brand") {
      setSelectedBrand(e.target.value);
    }
  };

  return (
    <div className={"header-container d-flex justify-content-end"}>
      {/* LANGUAGE SELECT */}
      <button onClick={toggleLanguageDialog} className="language-wrapper">
        <span className="header-label">{selectedLanguage}</span>
        <img src={getIcon("language.svg")} alt="languageIcon" />
      </button>
      {languageVisible && (
        <LanguageDialog
          toggleLanguageDialog={toggleLanguageDialog}
          handleAcceptLanguage={handleAcceptLanguage}
          handleLanguageChange={handleLanguageChange}
          languageData={languageData}
          dropdownState={dropdownState}
          selectedLanguage={selectedLanguage}
        />
      )}
      {/* MARKET SELECT */}
      <button
        onClick={() => {
          setSelectedHeader("market");
          toggleMarketBrandDialog();
        }}
        className={"country-wrapper"}
      >
        <span className={"header-label"}>
          {currentUser.settings.marketDescription}
        </span>
        <img src={getIcon("country.svg")} alt="marketIcon" />
      </button>
      {/* BRAND SELECT */}
      <button
        onClick={() => {
          setSelectedHeader("brand");
          toggleMarketBrandDialog();
        }}
        className={"brand-wrapper"}
      >
        <span className={"header-label"}>
          {currentUser.settings.brandDescription}
        </span>
        <img src={getIcon("brand.svg")} alt="brandIcon" />
      </button>
      {marketBrandVisible && (
        <MarketBrandDialog
          toggleMarketBrandDialog={toggleMarketBrandDialog}
          handleMarketChange={handleMarketChange}
          handleBrandChange={handleBrandChange}
          handleMarketDropdownOpen={handleMarketDropdownOpen}
          handleBrandDropdownOpen={handleBrandDropdownOpen}
          marketData={headerUserMarkets}
          brandData={headerUserBrands}
          currentSettings={currentUser.settings}
          selectedMarket={selectedMarket}
          selectedBrand={selectedBrand}
          selectedHeader={selectedHeader}
          onCancel={handleMarketBrandCancel}
          onApply={handleMarketBrandApply}
          dialogValues={dialogValues}
          setDialogValues={dialogValues}
        />
      )}
      {selectedHeader === "market"
        ? dialogValues.back && (
            <SavingModal
              message={
                "Changing the market and brand will reset the application. Any unsaved changes will be lost."
              }
              onclickHandleNo={handleModalNo}
              onclickHandleYes={handleModalSaving}
            />
          )
        : dialogValues.back && (
            <SavingModal
              message={
                "Changing the brand will reset the application. Any unsaved changes will be lost."
              }
              onclickHandleNo={() =>
                setDialogValues({ ...dialogValues, back: false })
              }
              onclickHandleYes={handleModalSaving}
            />
          )}
    </div>
  );
};

export default Header;
