export const sampleTransactionalLog = [
  {
    id: 0,
    dateTime: "15/01/2024 3:00 P.M",
    changedItem: "Default Interest Rate",
    changedBy: "Test User",
    currentItemValue: "5.64 %",
    lastItemValue: "4.63 %"
  },
  {
    id: 1,
    dateTime: "15/02/2024 4:15 P.M",
    changedItem: "Default Interest Rate",
    changedBy: "Test User",
    currentItemValue: "7.2 %",
    lastItemValue: "5.64 %"
  },
  {
    id: 1,
    dateTime: "15/02/2024 4:15 P.M",
    changedItem: "Default Interest Rate",
    changedBy: "Test User",
    currentItemValue: "7.2 %",
    lastItemValue: "5.64 %"
  },
  {
    id: 1,
    dateTime: "15/02/2024 4:15 P.M",
    changedItem: "Default Interest Rate",
    changedBy: "Test User",
    currentItemValue: "7.2 %",
    lastItemValue: "5.64 %"
  },
  {
    id: 1,
    dateTime: "15/02/2024 4:15 P.M",
    changedItem: "Default Interest Rate",
    changedBy: "Test User",
    currentItemValue: "7.2 %",
    lastItemValue: "5.64 %"
  },
  {
    id: 1,
    dateTime: "15/02/2024 4:15 P.M",
    changedItem: "Default Interest Rate",
    changedBy: "Test User",
    currentItemValue: "7.2 %",
    lastItemValue: "5.64 %"
  },
  {
    id: 1,
    dateTime: "15/02/2024 4:15 P.M",
    changedItem: "Default Interest Rate",
    changedBy: "Test User",
    currentItemValue: "7.2 %",
    lastItemValue: "5.64 %"
  },
  {
    id: 1,
    dateTime: "15/02/2024 4:15 P.M",
    changedItem: "Default Interest Rate",
    changedBy: "Test User",
    currentItemValue: "7.2 %",
    lastItemValue: "5.64 %"
  }
];
