export const getStatusClass = (status) => {
  switch (status) {
    case "Appt.Attended":
    case "Appt.Scheduled":
    case "Appt. Attended":
    case "Appt. Scheduled":
      return `first-status`;
    case "Contacted":
      return "second-status";
    case "Lead":
      return "third-status";
    case "Opportunity":
    case "Future Opportunity":
    case "FutureOpportunity":
      return "fourth-status";
    case "Record":
      return "fifth-status";
    case "Do no contact":
    case "Do not contact":
    case "DoNoContact":
    case "Closed / Lost":
    case "Closed/Lost":
    case "Sold":
    case "Not avail. for contact":
    case "NotAvail.ForContact":
    case "Refinanced":
      return "sixth-status ";
    default:
      return;
  }
};

export const getStatusDropdown = (status) => {
  switch (status) {
    case "Appt.Attended":
    case "Appt.Scheduled":
    case "Appt. Attended":
    case "Appt. Scheduled":
      return `first-status`;
    case "Contacted":
      return "second-status";
    case "Lead":
      return "third-status";
    case "Opportunity":
    case "Future Opportunity":
    case "FutureOpportunity":
      return "custom-blue-status";
    case "Record":
      return "fifth-status";
    case "Do no contact":
    case "Do not contact":
    case "DoNoContact":
    case "Closed / Lost":
    case "Closed/Lost":
    case "Sold":
    case "Not avail. for contact":
    case "NotAvail.ForContact":
    case "Refinanced":
      return "sixth-status ";
    default:
      return;
  }
};

export const getStatusChart = (status) => {
  switch (status) {
    case "Opportunities":
      return "blue-status";
    case "Leads":
      return "green-status";
    case "Contacted":
      return "red-status";
    case "Appointed":
      return "purple-status";
    case "Attended":
      return "indigo-status";
    case "Sold":
      return "dark-status";
    default:
      return;
  }
};

//Set Funnel Color
export const getFunnelColor = (type) => {
  switch (type) {
    case "Opportunities":
      return "#16BAE7";
    case "Leads":
      return "#B7CD00";
    case "Contacted":
      return "#be3b04";
    case "Appointed":
      return "#9E61A4";
    case "Attended":
      return "#7887fb";
    case "Sold":
      return "#00233a";
    default:
      return;
  }
};
