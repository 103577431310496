import React from "react";
import "../../styles/campaigns/campaignBuilder.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const BuilderReplaceDeal = ({
  showVS,
  mode,
  financeTypeData,
  financeTypeValue,
  handleDropdownChange,
  payload,
  uncheckedIds,
  handleDecimalNumberChange,
  handleInterestRate,
  handleIntNumberChange,
  handleCheckboxInputChange,
  blockInvalidChar,
  totalRecords,
  blockDotInvalidChar,
  handleOpenFinanceTypeDropdown
}) => {
  const { t } = useTranslation();

  return (
    <div className="replacement-deal">
      <h2>{getTranslation("Replacement deal", t)}</h2>
      <div className="replace-deal-cont">
        <div>
          <p>{getTranslation("Finance type", t)}</p>
          <DropDownList
            name="replacementFinanceType"
            data={financeTypeData}
            textField="textField"
            onOpen={handleOpenFinanceTypeDropdown}
            value={financeTypeValue.replacementFinanceType}
            onChange={handleDropdownChange}
          />
        </div>
        <div>
          <p>{getTranslation("Vehicle", t)}</p>
          <Input
            readOnly={true}
            value={
              payload.campaignReplacementVehicles === null
                ? getTranslation(`No items selected`, t)
                : mode === "edit" &&
                  payload.campaignReplacementVehicles === null
                  ? getTranslation(`No items selected`, t)
                  : totalRecords.replacementVS === 0 ||
                    (payload.campaignReplacementVehicles?.length ===
                      totalRecords.replacementCurrent)
                    ? getTranslation("All", t)
                    : payload.campaignReplacementVehicles?.length === 0
                      ? uncheckedIds.replacementVS.length > 0 
                        ? totalRecords.replacementVS -
                          uncheckedIds.replacementVS.length >
                          0
                          ? `${totalRecords.replacementVS -
                          uncheckedIds.replacementVS.length
                          } ${getTranslation("items selected", t)}`
                          : getTranslation("All", t)
                        : totalRecords.replacementCurrent === totalRecords.replacementVS
                          ? getTranslation("All", t)
                          : `${totalRecords.replacementVS} ${getTranslation("items selected", t)}`
                      : `${payload.campaignReplacementVehicles?.length
                      } ${getTranslation("items selected", t)}`
            }
            className="k-input-flat"
          />
        </div>
        <div onClick={showVS}>
          <button className="secondary-btn w-100">
            {getTranslation("Vehicles selector", t)}
          </button>
        </div>
      </div>
      <h2>{getTranslation("Deposit", t)}</h2>
      <div className="deposit-cont">
        <div>
          <p>{getTranslation("Manufacturer deposit", t)}</p>
          <div className="d-flex align-items-center">
            <Input
              min={0}
              step="0.01"
              type="number"
              className="k-input-flat"
              onKeyDown={blockInvalidChar}
              name="replacementManufacturerDeposit"
              onChange={handleDecimalNumberChange}
              value={payload.replacementManufacturerDeposit}
            />
            <span className="percent">%</span>
            <Checkbox
              name="replacementManufacturerDepositPercentage"
              onChange={handleCheckboxInputChange}
              value={payload.replacementManufacturerDepositPercentage}
            />
          </div>
        </div>
        <div>
          <p>{getTranslation("Finance term", t)}</p>
          <Input
            min={0}
            step={1}
            type="number"
            pattern="^[-\d]\d*$"
            className="k-input-flat"
            name="replacementFinanceTerm"
            onChange={handleIntNumberChange}
            onKeyDown={blockDotInvalidChar}
            value={payload.replacementFinanceTerm}
          />
        </div>
        <div className="interest-input-box">
          <p>{getTranslation("Interest rate", t)}</p>
          <NumericTextBox 
            min={0}
            max={1}
            step="0.01"
            spinners={false}
            className="k-input-flat"
            onKeyDown={blockInvalidChar}
            name="replacementInterestRate"
            onChange={handleInterestRate}
            value={payload.replacementInterestRate}
            format="p2" 
          />
        </div>
        <div>
          <p>{getTranslation("Dealer deposit", t)}</p>
          <div className="d-flex align-items-center">
            <Input
              min={0}
              step="0.01"
              type="number"
              className="k-input-flat"
              name="replacementDealerDeposit"
              value={payload.replacementDealerDeposit}
              onChange={handleDecimalNumberChange}
              onKeyDown={blockInvalidChar}
            />
            <span className="percent">%</span>
            <Checkbox
              onChange={handleCheckboxInputChange}
              name="replacementDealerDepositPercentage"
              value={payload.replacementDealerDepositPercentage}
            />
          </div>
        </div>
        <div>
          <p>{getTranslation("Monthly payment difference", t)}</p>
          <div className="d-flex align-items-center">
            <Input
              min={0}
              step="0.01"
              type="number"
              className="k-input-flat"
              onKeyDown={blockInvalidChar}
              onChange={handleDecimalNumberChange}
              name="replacementMonthlyPaymentDifference"
              value={payload.replacementMonthlyPaymentDifference}
            />
            <span className="percent">%</span>
            <Checkbox
              onChange={handleCheckboxInputChange}
              name="monthlyPaymentDifferencePercentage"
              value={payload.monthlyPaymentDifferencePercentage}
            />
          </div>
        </div>
        <div>
          <p>{getTranslation("Additional customer deposit", t)}</p>
          <Input
            min={0}
            step="0.01"
            type="number"
            className="k-input-flat"
            onKeyDown={blockInvalidChar}
            name="additionalCustomerDeposit"
            onChange={handleDecimalNumberChange}
            value={payload.additionalCustomerDeposit}
          />
        </div>
      </div>
    </div>
  );
};

export default BuilderReplaceDeal;
