import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";

import "bootstrap/dist/css/bootstrap.css";
import "./styles/index.css";
import "./styles/custom.css";
import { BrowserRouter } from "react-router-dom";

// Translation feature
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .init({
    supportedLngs: ["en", "lva", "est", "ltu", "hk", "bg", "esp"],
    // debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    lng: localStorage.getItem("lng") || "en"
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
