/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, cloneElement } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardBody,
} from "@progress/kendo-react-layout";
import { Checkbox, TextArea } from "@progress/kendo-react-inputs";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { getIcon } from "../../utils/iconUtils";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import FinanceRecord from "./FinanceRecord";
import "../../styles/customerRecord/customerRecord.css";
import { format } from "date-fns";
import { getStatusClass, getStatusDropdown } from "../../utils/statusUtil";
import statusData from "../shared/statusData";
import {
  checkMarketAu,
  checkMarketChile,
  formatCurrency,
  validateUserPermission,
} from "../../utils/helpers";
import ActivityRecord from "./ActivityRecord";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const CustomerRecord = ({
  marketSettings,
  removeOpportunity,
  getCustomerRecordData,
  customerRecordData,
  clientPositionData,
  financeRecordData,
  getSpecialists,
  specialists,
  currentUser,
  getActivityRecord,
  updateActivityRecord,
  activityRecord,
  setLoadingCount,
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [dueDate, setDueDate] = useState(null);
  const [selectedSpecialist, setSelectedSpecialist] = useState({});
  const [description, setDescription] = useState(null);

  //Handles due date
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 3);

  useEffect(() => {
    if (customerRecordData) {
      setData([
        {
          option: getTranslation("Phone", t),
          yes: customerRecordData.consentPhone?.toLowerCase() === "true",
          no:
            customerRecordData.consentPhone?.toLowerCase() === "false" ||
            customerRecordData.consentPhone === null,
          maybe: customerRecordData.consentPhone?.toLowerCase() === "maybe",
        },
        {
          option: getTranslation("SMS", t),
          yes: customerRecordData.consentSMS?.toLowerCase() === "true",
          no:
            customerRecordData.consentSMS?.toLowerCase() === "false" ||
            customerRecordData.consentSMS === null,
          maybe: customerRecordData.consentSMS?.toLowerCase() === "maybe",
        },
        {
          option: getTranslation("Email", t),
          yes: customerRecordData.consentEmail?.toLowerCase() === "true",
          no:
            customerRecordData.consentEmail?.toLowerCase() === "false" ||
            customerRecordData.consentEmail === null,
          maybe: customerRecordData.consentEmail?.toLowerCase() === "maybe",
        },
      ]);
    }
  }, [customerRecordData, t]);

  useEffect(() => {
    setLoadingCount(0);
    if (clientPositionData) {
      setSelectedSpecialist({
        userFullName: clientPositionData.specialistName
          ? clientPositionData.specialistName
          : clientPositionData.defaulSpecialistName,
        userId: clientPositionData.specialistId
          ? clientPositionData.specialistId
          : null,
      });
      setSelectedStatus({
        status: clientPositionData.statusDescription,
        value: clientPositionData.status,
        classKey: clientPositionData.statusDescription,
      });
      setDueDate(
        clientPositionData.dueDate ? new Date(clientPositionData.dueDate) : null
      );
    }
  }, [
    clientPositionData.status,
    clientPositionData.dueDate,
    clientPositionData.defaulSpecialistName,
  ]);

  useEffect(() => {
    setLoadingCount(0);
    loadCustomerRecordState();
  }, [id]);

  const loadCustomerRecordState = () => {
    getCustomerRecordData(id);
    getSpecialists();
    getActivityRecord(id);
  };

  const handleBack = () => {
    switch (location.state?.prevPage) {
      case "campaigns":
        Navigate(
          `/campaigns/${
            clientPositionData.campaignId || location.state?.campaignId
          }/leads`,
          {
            state: { prevPage: location.state?.prevPage },
          }
        );
        break;
      case "dashboard":
        Navigate(
          `/campaigns/${
            clientPositionData.campaignId || location.state?.campaignId
          }/leads`,
          {
            state: { prevPage: location.state?.prevPage },
          }
        );
        break;
      case "search-module":
        Navigate("/search-module");
        break;
      case "service-alerts":
        Navigate("/service-alerts", { state: { prevPage: "customer-record" } });
        break;
      case "portfolio":
        Navigate("/portfolio", { state: { prevPage: "customer-record" } });
        break;
      case "dashboard-task":
        Navigate("/dashboard", { state: { prevPage: "customer-record" } });
        break;
      default:
        Navigate("/dashboard");
        break;
    }
  };

  const renderCheckboxCell = (props) => {
    const { dataItem, field } = props;
    return (
      <td>
        <Checkbox checked={dataItem[field]} readOnly={true} />
      </td>
    );
  };

  //Handles status dropdown
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    console.log(event.target.value);
  };

  // Handles Specialist dropdown
  const handleSpecialistChange = (event) => {
    setSelectedSpecialist(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.value);
  };

  // Render status dropdown list
  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <div
        className={`mb-0 ${getStatusDropdown(
          itemProps.dataItem.classKey
        )} status-common w-100`}
      >
        {itemProps.dataItem.status}
      </div>
    );
    return cloneElement(li, li.props, itemChildren);
  };

  const handleRemoveOpportunity = () => {
    const removeOpportunityData = {
      leadId: clientPositionData.leadId,
      specialistId: clientPositionData.specialistId,
      status: 1,
      dueDate: clientPositionData.dueDate,
      description: null,
      specialistName: clientPositionData.specialistName,
      statusDescription: "Record",
      ivId: clientPositionData.ivId,
    };
    const ivId = clientPositionData.ivId;
    window.history.replaceState(null, "", `/customer-record/${ivId}`);
    removeOpportunity(removeOpportunityData);
  };

  const handleUpdateActivityRecord = () => {
    const updateActivityRecordData = {
      specialistId:
        selectedSpecialist.userId !== clientPositionData.specialistId
          ? selectedSpecialist.userId
          : null,
      status:
        selectedStatus.value !== clientPositionData.status
          ? selectedStatus.value
          : null,
      dueDate:
        dueDate &&
        format(dueDate, "yyyy-MM-dd") !==
          format(new Date(clientPositionData.dueDate), "yyyy-MM-dd")
          ? format(dueDate, "yyyy-MM-dd")
          : null,
      description: description ? description : null,
      specialistName:
        selectedSpecialist.userId !== clientPositionData.specialistId
          ? selectedSpecialist.userFullName
          : null,
      statusDescription:
        selectedStatus.value !== clientPositionData.status
          ? selectedStatus.status
          : null,
    };

    let isEmpty = true;

    for (var key in updateActivityRecordData) {
      if (
        updateActivityRecordData[key] !== null &&
        updateActivityRecordData[key] !== ""
      )
        isEmpty = false;
    }

    if (!isEmpty) {
      updateActivityRecord(JSON.stringify(updateActivityRecordData), id);
      setDescription("");
    }
  };
  const pdfExportComponent = React.useRef(null);
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  return (
    <div className="customer-record">
      <div className="d-flex gap-5 align-items-center header mb-4">
        <img
          className="back-btn"
          onClick={handleBack}
          src={getIcon("arrow-back.svg")}
          alt="back icon"
        />
        <p className="h1 mb-0">
          {checkMarketAu() ? `-` : customerRecordData.customerName}
        </p>
        {validateUserPermission(
          currentUser.permissions,
          "canEdit",
          "Leads"
        ) && (
          <button
            className="header-button primary-btn"
            disabled={
              !["Lead", "Opportunity"].includes(
                clientPositionData.statusDescription
              ) || clientPositionData?.campaignName.toLowerCase() === "standard terms"
            }
            onClick={() => handleRemoveOpportunity()}
          >
            {getTranslation("Remove as opportunity", t)}
          </button>
        )}
      </div>

      <div className="customerRecordContainer">
        <div className="row m-0">
          <div className="col-xxl-3 col-xl-6 col-md-12 col-sm-12 mb-4 ps-0">
            <Card className="k-flex-20">
              <CardHeader className="k-hbox">
                <CardTitle>{getTranslation("Customer Record", t)}</CardTitle>
              </CardHeader>
              <div className="col-md-12 col-sm-7">
                <CardBody>
                  <CardSubtitle>{getTranslation("Salesman", t)} </CardSubtitle>
                  <p className="break">
                    {checkMarketAu()
                      ? `-`
                      : customerRecordData?.salesConsultant}
                  </p>
                  <CardSubtitle>
                    {getTranslation("Contract ID", t)}
                  </CardSubtitle>
                  <p className="break">
                    {checkMarketAu() ? `-` : customerRecordData?.contractId}
                  </p>
                  <CardSubtitle>{getTranslation("Address", t)}</CardSubtitle>
                  <p className="address-break">
                    {checkMarketAu()
                      ? `-`
                      : customerRecordData?.customerAddress}
                  </p>
                  <CardSubtitle>
                    {getTranslation("Postal code", t)}
                  </CardSubtitle>
                  <p className="break">
                    {checkMarketAu() ? `-` : customerRecordData?.postal}
                  </p>
                  <CardSubtitle>
                    {getTranslation("Mobile number", t)}
                  </CardSubtitle>
                  <p className="break">
                    {checkMarketAu() ? `-` : customerRecordData?.customerPhone}
                  </p>
                  <CardSubtitle>
                    {getTranslation("Alternative number", t)}
                  </CardSubtitle>
                  <p className="break">
                    {checkMarketAu()
                      ? `-`
                      : customerRecordData?.alternativeNumber}
                  </p>
                  <CardSubtitle>{getTranslation("Email", t)}</CardSubtitle>
                  <p className="break">
                    {checkMarketAu() ? `-` : customerRecordData?.customerEmail}
                  </p>
                  {checkMarketChile() ? (
                    <>
                      <CardSubtitle>
                        {getTranslation("Customer ID", t)}
                      </CardSubtitle>
                      <p className="break">
                        {customerRecordData?.idNumber}
                      </p>
                    </>
                  ) : (
                    ``
                  )}
                  <CardSubtitle>
                    {getTranslation("Consented method of contact", t)}
                  </CardSubtitle>
                  <Grid
                    className="contact-method"
                    data={data}
                    style={{ width: "500px" }}
                  >
                    <Column
                      field="option"
                      title={<span className="hidden-header"> </span>}
                      style={{ width: "200px" }}
                    />
                    <Column
                      field="yes"
                      title={getTranslation("Yes", t)}
                      cell={renderCheckboxCell}
                      style={{ width: "100px" }}
                    />
                    <Column
                      field="no"
                      title={getTranslation("No", t)}
                      cell={renderCheckboxCell}
                      style={{ width: "100px" }}
                    />
                    <Column
                      field="maybe"
                      title={getTranslation("Maybe", t)}
                      cell={renderCheckboxCell}
                      style={{ width: "100px" }}
                    />
                  </Grid>
                </CardBody>
              </div>
            </Card>
          </div>

          <div className="col-xxl-3 col-xl-6 col-md-12 col-sm-12 mb-4">
            <Card className="k-flex-30">
              <CardHeader className="k-hbox">
                <CardTitle>{getTranslation("Client position", t)}</CardTitle>
              </CardHeader>
              <CardBody>
                <div className="d-flex gap-3 mb-4 justify-content-between">
                  <div
                    className={`status-cell ${getStatusClass(
                      clientPositionData?.statusDescription
                    )}`}
                  >
                    {getTranslation(clientPositionData?.statusDescription, t)}
                  </div>
                  <div className="campaign-name">
                    <label>{clientPositionData?.campaignName || "N/A"}</label>
                  </div>
                </div>
                <div className="campaign-name mb-4 d-flex gap-3 branch">
                  <label>{getTranslation("Branch", t)}: </label>
                  <span className="text-center">
                    {customerRecordData?.branchName}
                  </span>
                </div>
                <div className="d-flex gap-3 mb-4">
                  <div className="campaign-name">
                    <label>{getTranslation("Status due date", t)}:</label>
                  </div>
                  <div className="campaign-name">
                    <label>
                      {clientPositionData.dueDate
                        ? format(
                            new Date(clientPositionData.dueDate),
                            "dd/MM/yyyy"
                          )
                        : ""}
                    </label>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-7">
                  <div className="dropdown-container">
                    <CardSubtitle>
                      {getTranslation("Specialist", t)}
                    </CardSubtitle>
                    <DropDownList
                      className="dropdown"
                      textField="userFullName"
                      dataItemKey="userId"
                      data={specialists}
                      value={selectedSpecialist}
                      disabled={
                        currentUser.userInfo.accessLevel === 1 ||
                        clientPositionData.status === 1 ||
                        !validateUserPermission(
                          currentUser.permissions,
                          "canEdit",
                          "Leads"
                        )
                      }
                      onChange={handleSpecialistChange}
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-7">
                  <div className="dropdown-container">
                    <CardSubtitle>{getTranslation("Status", t)}</CardSubtitle>
                    <DropDownList
                      className="dropdown"
                      data={statusData
                        .map((item) => ({
                          ...item,
                          status: getTranslation(item.status, t),
                        }))
                        .filter((status) => status.value !== 1)}
                      value={
                        selectedStatus ? (
                          <span
                            className={`full-width ${getStatusClass(
                              selectedStatus.classKey
                            )}`}
                          >
                            {getTranslation(selectedStatus.status, t)}
                          </span>
                        ) : undefined
                      }
                      disabled={clientPositionData.status === 1}
                      itemRender={itemRender}
                      onChange={handleStatusChange}
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-7">
                  <div className="dropdown-container">
                    <CardSubtitle>{getTranslation("Due date", t)}</CardSubtitle>
                    <DatePicker
                      className="datePicker"
                      format={"dd/MM/yyyy"}
                      value={dueDate}
                      onChange={(event) => setDueDate(event.target.value)}
                      min={minDate}
                      disabled={clientPositionData.status === 1}
                    />
                  </div>
                </div>

                <div>
                  <CardTitle>
                    {getTranslation("Update status & activity", t)}
                  </CardTitle>
                </div>
                <div>
                  <TextArea
                    className="textArea"
                    rows={4}
                    disabled={
                      clientPositionData.status === 1 ||
                      !validateUserPermission(
                        currentUser.permissions,
                        "canEdit",
                        "Leads"
                      )
                    }
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                  <button
                    className="primary-btn update-btn"
                    disabled={
                      clientPositionData.status === 1 ||
                      !validateUserPermission(
                        currentUser.permissions,
                        "canEdit",
                        "Leads"
                      )
                    }
                    onClick={() => handleUpdateActivityRecord()}
                  >
                    {getTranslation("Update", t)}
                  </button>
                </div>

                {/* Render Activity Record */}
                <ActivityRecord
                  activityRecord={activityRecord}
                  campaignName={clientPositionData.campaignName}
                  salesConsultant={customerRecordData.salesConsultant}
                />
              </CardBody>
            </Card>
          </div>
          <div className="col-xxl-6 col-xl-12 col-md-12 col-sm-12 mb-4 pe-0">
            <Card className="k-flex-auto">
              <CardHeader className="k-hbox">
                <CardTitle>{getTranslation("Finance Record", t)}</CardTitle>
              </CardHeader>
              <div className="col-md-12 col-sm-7">
                <CardBody>
                  <FinanceRecord
                    marketSettings={marketSettings}
                    financeData={financeRecordData}
                    currentUser={currentUser}
                    status={clientPositionData.status}
                    customerRecordData={customerRecordData}
                    clientPositionData={clientPositionData}
                    pdfExportComponent={pdfExportComponent}
                    exportDeskingSheet={exportPDFWithComponent}
                    formatCurrency={formatCurrency}
                  />
                </CardBody>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerRecord;
