import React, { cloneElement, useState } from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import "../../styles/shared/dateFilterCell.css";

export const DateFilterCell = (props) => {
  const [selectedOperator, setSelectedOperator] = useState({
    text: "",
    operator: "",
  });

  const onChange = (event) => {
    const value = event.target.value;
    props.onChange({
      value: value ? value : "",
      operator: value ? (props.operator ? props.operator : "eq") : "",
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onClearButtonClick = (event) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });
    setSelectedOperator({ text: "", operator: "" });
  };

  const onOperatorChange = (event) => {
    props.onChange({
      value: props.value,
      operator: event.target.value.operator,
      syntheticEvent: event.syntheticEvent,
    });
    setSelectedOperator(event.value);
  };

  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <div id="date-filter-cell">
        <span>{itemProps.dataItem.text}</span>
      </div>
    );
    return cloneElement(li, li.props, itemChildren);
  };

  return (
    <div className="date-filter-cell k-filtercell">
      <DatePicker
        {...props}
        format={"dd/MM/yyyy"}
        placeholder={"day/month/year"}
        value={props.value}
        onChange={onChange}
      />
      <DropDownList
        data={props.operators}
        textField="text"
        dataItemKey="operator"
        onChange={onOperatorChange}
        itemRender={itemRender}
        value={selectedOperator}
      />
      <Button
        title="Clear"
        disabled={!props.value && !props.operator}
        onClick={onClearButtonClick}
        icon="filter-clear"
      />
    </div>
  );
};
