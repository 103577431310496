import "../../styles/customerRecord/customerRecord.css";
import React from "react";
import { format } from "date-fns";
import { CardTitle } from "@progress/kendo-react-layout";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const ActivityRecord = ({ activityRecord, campaignName, salesConsultant }) => {
  const { t } = useTranslation();
  return (
    <div className="activity-record">
      <CardTitle>{getTranslation("Activity record", t)}</CardTitle>
      <div className="activity-log">
        {activityRecord.map((ar) => {
          return (
            <>
              {ar.activityType === 1 ? (
                <div className="activity-opportunity">
                  <label className="activity-opportunity-header">
                    {getTranslation(ar.header, t)}
                  </label>
                  <br />
                  <label>{getTranslation(ar.description, t)}</label>
                  <br />
                  <label className="activity-log-lbl">
                    {campaignName || "N/A"}
                  </label>
                  <label className="activity-log-lbl">
                    {format(new Date(`${ar.dateStamp}Z`), "dd/MM/yyyy HH:mm")}
                  </label>
                  <br />
                  <label className="">{getTranslation("Assigned to", t)}</label>
                  <br />
                  <label className="activity-log-lbl">{salesConsultant}</label>
                  <label className="activity-log-lbl">
                    {`${format(
                      new Date(`${ar.dateStamp}Z`),
                      "dd/MM/yyyy HH:mm"
                    )} by ${ar.updatedByEmail}`}
                  </label>
                </div>
              ) : (
                <div className="activity-log-output">
                  <label>{ar.header}</label>
                  <label className="activity-log-lbl description">
                    {ar.description}
                  </label>
                  <br />
                  <label className="activity-log-lbl">
                    {`${format(
                      new Date(`${ar.dateStamp}Z`),
                      "dd/MM/yyyy HH:mm"
                    )} by ${ar.updatedByEmail ? ar.updatedByEmail : ""}`}
                  </label>
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ActivityRecord;
