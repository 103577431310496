const statusIncData = [
  {
    status: "Appt.Attended",
    value: 9
  },
  {
    status: "Appt.Scheduled",
    value: 6
  },
  {
    status: "Lead",
    value: 4
  },
  {
    status: "Opportunity",
    value: 2
  },
  {
    status: "Future Opportunity",
    value: 3
  }
];

export default statusIncData;
