import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  GET_HEADER_USER_BRANDS,
  GET_HEADER_USER_MARKETS,
  GET_USER_BRANCHES
} from "../actions/types";
import {
  setLoading,
  setHeaderUserMarkets,
  setHeaderUserBrands,
  setUserBranches
} from "../actions";
import {
  getHeaderUserBrandsApi,
  getHeaderUserMarketsApi,
  getUserBranchesApi
} from "../api/handler";

function* handleLoadUserMarkets() {
  try {
    const response = yield call(getHeaderUserMarketsApi);
    if (response.status === 200) {
      yield put(setHeaderUserMarkets(response.data.data));
    }
  } catch (e) {
    console.warn(e);
  }
}

function* handleLoadUserBrands({ marketId }) {
  try {
    const response = yield call(getHeaderUserBrandsApi, marketId);
    if (response.status === 200) {
      yield put(setHeaderUserBrands(response.data.data));
    }
  } catch (e) {
    console.warn(e);
  }
}

function* handleLoadUserBranches({ brandId }) {
  try {
    const response = yield call(getUserBranchesApi, brandId);
    if (response.status === 200) {
      yield put(setUserBranches(response.data.data));
    }
  } catch (e) {
    console.warn(e);
  }
}

export default function* commonSagas() {
  yield all([
    takeLatest(GET_HEADER_USER_MARKETS, handleLoadUserMarkets),
    takeLatest(GET_HEADER_USER_BRANDS, handleLoadUserBrands),
    takeLatest(GET_USER_BRANCHES, handleLoadUserBranches)
  ]);
}
