const statusData = [
  {
    status: "Appt.Attended",
    classKey: "Appt.Attended",
    value: 9
  },
  {
    status: "Appt.Scheduled",
    classKey: "Appt.Scheduled",
    value: 6
  },
  {
    status: "Lead",
    classKey: "Lead",
    value: 4
  },
  {
    status: "Opportunity",
    classKey: "Opportunity",
    value: 2
  },
  {
    status: "Future Opportunity",
    classKey: "Future Opportunity",
    value: 3
  },
  {
    status: "Record",
    classKey: "Record",
    value: 1
  },
  {
    status: "Do not contact",
    classKey: "Do not contact",
    value: 12
  },
  {
    status: "Closed/Lost",
    classKey: "Closed/Lost",
    value: 13
  },
  {
    status: "Sold",
    classKey: "Sold",
    value: 14
  },
  {
    status: "Not avail. for contact",
    classKey: "Not avail. for contact",
    value: 15
  },
  {
    status: "Refinanced",
    classKey: "Refinanced",
    value: 16
  }
];

export default statusData;
