/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getIcon } from "../../utils/iconUtils";
import "../../styles/userManagement/userForm.css";
import UserInput from "./UserInput";
import UserDropdown from "./UserDropdown";
import fetchClient from "../../api/fetch";
import axios from "axios";
import SavingModal from "../shared/SavingModal";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const UserForm = ({
  currentUser,
  setAuth,
  setLoading,
  setSuccessModalValues
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const mode = location.pathname === "/user-management/new" ? "create" : "edit";
  const submitCount = useRef(0);

  const initialPayload = {
    name: "",
    surname: "",
    email: "",
    roleId: "",
    createdBy: "",
    regionId: [],
    marketId: [],
    brandId: [],
    branchId: []
  };

  const [roleValue, setRoleValue] = useState({
    accessLevel: 0,
    roleDescription: getTranslation("Select", t)
  });
  const [dialogValues, setDialogValues] = useState({
    success: false,
    back: false
  });
  const [dropdownData, setDropdownData] = useState({
    roleId: [],
    regionId: [],
    marketId: [],
    brandId: [],
    branchId: []
  });
  const [payload, setPayload] = useState(initialPayload);
  const [errors, setErrors] = useState({});
  const [errorMsgs, setErrorMsgs] = useState({
    missingMarket: "",
    missingBrand: ""
  });
  const [open, setOpen] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const firstRender = useRef(true);

  useEffect(() => {
    if (mode === "edit") {
      setLoading(true);
      fetchClient()
        .get(`/v1/users/${id}`)
        .then((res) => {
          const data = res.data.data;

          const userId = currentUser.userInfo.userId;
          const regionIds = data.regionId.toString();
          const marketIds = data.marketId.toString();
          const brandIds = data.brandId.toString();
          axios
            .all([
              fetchClient().get(`/v1/users/${userId}/roles`),
              fetchClient().get(`/v1/users/${userId}/regions`),
              fetchClient().get(
                `/v1/users/${userId}/markets?regionId=${regionIds}`
              ),
              fetchClient().get(
                `/v1/users/${userId}/brands?marketId=${marketIds}`
              ),
              fetchClient().get(
                `/v1/users/${userId}/branches?brandId=${brandIds}`
              )
            ])
            .then(
              axios.spread((role, region, market, brand, branch) => {
                const accessLevel = role.data.find(
                  (role) => role.roleId === data.roleId
                ).accessLevel;
                const getValue = (name, response) => {
                  return name !== "role"
                    ? isMultipleSelect(accessLevel)[name] &&
                      response.data.length > 0
                      ? [
                          {
                            id: 0,
                            [`${name}Description`]: getTranslation(
                              "Select All",
                              t
                            )
                          }
                        ].concat(response.data)
                      : response.data
                    : response.data;
                };
                setDropdownData({
                  ...dropdownData,
                  roleId: getValue("role", role),
                  regionId: getValue("region", region),
                  marketId: getValue("market", market),
                  brandId: getValue("brand", brand),
                  branchId: getValue("branch", branch)
                });
                setPayload({
                  ...data,
                  regionId: region.data.filter((region) =>
                    data.regionId.includes(region.regionId)
                  ),
                  marketId: market.data.filter((market) =>
                    data.marketId.includes(market.marketId)
                  ),
                  brandId: brand.data.filter((brand) =>
                    data.brandId.includes(brand.brandId)
                  ),
                  branchId: branch.data.filter((branch) =>
                    data.branchId.includes(branch.branchId)
                  )
                });
                setRoleValue({
                  roleDescription: role.data.find(
                    (role) => role.roleId === data.roleId
                  ).roleDescription,
                  accessLevel
                });
                firstRender.current = true;
              })
            )
            .catch((err) => console.log(err))
            .finally(() => {
              setLoading(false);
              submitCount.current++;
            });
        });
    } else setFields();
  }, [currentUser]);

  useEffect(() => {
    if (!firstRender.current) resetDependentDropdown("regionId", "marketId");
  }, [payload.regionId]);

  useEffect(() => {
    if (!firstRender.current) resetDependentDropdown("marketId", "brandId");
  }, [payload.marketId]);

  useEffect(() => {
    if (!firstRender.current) resetDependentDropdown("brandId", "branchId");
  }, [payload.brandId]);

  const resetDependentDropdown = (current, dependent) => {
    const currentName = current.slice(0, current.indexOf("Id"));
    const dependentName = dependent.slice(0, dependent.indexOf("Id"));
    // if current dropdown has multiple selection and dependent dropdown also has multiple selection
    if (isMultipleSelect()[currentName] && isMultipleSelect()[dependentName]) {
      const idsArr = payload[current].map((item) => item[current]);
      // remove selected items on dependent dropdown if an item is removed on current dropdown
      setPayload((prevState) => ({
        ...prevState,
        [dependent]: prevState[dependent].filter((item) =>
          idsArr.includes(item[current])
        )
      }));
    } else {
      // reset everything
      setPayload((prevState) => ({ ...prevState, [dependent]: [] }));
    }
  };

  useEffect(() => {
    if (submitCount.current > 0) {
      validate();
    }
  }, [payload]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      submitRequest();
    } else {
      setIsSubmit(false);
    }
  }, [isSubmit]);

  const setFields = () => {
    const { settings } = currentUser;
    const { accessLevel, regions, branches, userId } = currentUser.userInfo;
    let obj = {
      ...payload,
      regionId: accessLevel <= 5 ? [regions[0]] : payload.regionId,
      marketId:
        accessLevel <= 4
          ? [
              {
                marketId: settings.marketId,
                marketDescription: settings.marketDescription
              }
            ]
          : payload.marketId,
      brandId:
        accessLevel <= 3
          ? [
              {
                brandId: settings.brandId,
                brandDescription: settings.brandDescription
              }
            ]
          : payload.brandId,
      branchId: accessLevel <= 2 ? [branches[0]] : payload.branchId
    };
    if (accessLevel <= 2) {
      fetchClient()
        .get(`/v1/users/${userId}/roles`)
        .then((res) => {
          setPayload({ ...obj, roleId: res.data[0].roleId });
          setRoleValue(res.data[0]);
        })
        .catch((err) => {
          setPayload(obj);
          console.log(err);
        });
    } else setPayload(obj);
  };

  const resetFields = useCallback((selectedRole) => {
    const prevRole = roleValue.accessLevel;
    const { accessLevel } = currentUser.userInfo;
    // Do not reset if previously selected role has the same number of items that can be selected (multiple or single)
    setPayload((prevState) => ({
      ...prevState,
      marketId:
        accessLevel > 4
          ? (prevRole > 4 && selectedRole <= 4) ||
            (prevRole <= 4 && selectedRole > 4)
            ? []
            : prevState.marketId
          : prevState.marketId,
      brandId:
        accessLevel > 3
          ? (prevRole > 4 && selectedRole <= 4) ||
            (prevRole <= 4 && selectedRole > 4) ||
            (prevRole > 3 && selectedRole <= 3) ||
            (prevRole <= 3 && selectedRole > 3)
            ? []
            : prevState.brandId
          : prevState.brandId,
      branchId:
        accessLevel > 2
          ? (prevRole > 4 && selectedRole <= 4) ||
            (prevRole <= 4 && selectedRole > 4) ||
            (prevRole > 2 && selectedRole <= 2) ||
            (prevRole <= 2 && selectedRole > 2) ||
            (prevRole > 3 && selectedRole <= 3) ||
            (prevRole <= 3 && selectedRole > 3)
            ? []
            : prevState.branchId
          : prevState.branchId
    }));
  });

  const isMultipleSelect = (fetchedAccessLvl = null) => {
    const accessLevel = fetchedAccessLvl ?? roleValue.accessLevel;
    return {
      region: false,
      market: !(accessLevel <= 4),
      brand: !(accessLevel <= 2),
      branch: !(accessLevel <= 2)
    };
  };

  const isDisabled = () => {
    const accessLevel = currentUser.userInfo.accessLevel;
    return {
      region: accessLevel <= 5,
      market: accessLevel <= 4,
      brand: accessLevel <= 3,
      branch: accessLevel <= 2
    };
  };

  const handleChange = (e) => {
    // to trigger useEffects connected to payload
    firstRender.current = false;
    switch (e.target.name) {
      case "roleId":
        setRoleValue(e.target.value);
        setPayload({
          ...payload,
          roleId: e.target.value.roleId
        });
        resetFields(e.target.value.accessLevel);
        break;
      case "regionId":
      case "marketId":
      case "brandId":
      case "branchId":
        // check if selectAll option is included in the multiselect values
        const selectAll = e.target.value.some((item) => item.id === 0);
        // get name of array without "Id"
        const index = e.target.name.indexOf("Id");
        if (selectAll) {
          // if payload array is equal to the available options minus one,
          // meaning all of the options are selected
          if (
            payload[e.target.name].length ===
            dropdownData[e.target.name].length - 1
          ) {
            // deselect all if select all option is clicked again
            setPayload({ ...payload, [e.target.name]: [] });
          } else {
            // select all options but remove the "select all" object
            setPayload({
              ...payload,
              [e.target.name]: dropdownData[e.target.name].filter(
                (item) =>
                  getTranslation(
                    item[`${e.target.name.slice(0, index)}Description`],
                    t
                  ) !== getTranslation("Select All", t)
              )
            });
          }
        } else {
          const name = e.target.name.slice(0, e.target.name.indexOf("Id"));
          // check if multiple items should be selected
          if (isMultipleSelect()[name]) {
            // if not all options are selected, only store the checked ones
            setPayload({ ...payload, [e.target.name]: e.target.value });
          } else {
            setPayload({
              ...payload,
              [e.target.name]: e.target.value.length
                ? [e.target.value.pop()]
                : []
            });
          }
        }
        break;
      default:
        setPayload({ ...payload, [e.target.name]: e.target.value });
        break;
    }
  };

  const handleBack = () => {
    if (JSON.stringify(payload) === JSON.stringify(initialPayload)) {
      Navigate("/user-management");
    } else {
      setDialogValues({ ...dialogValues, back: true });
    }
  };

  const handleOpenDropdown = (dependencyName, name) => {
    const userId = currentUser.userInfo.userId;
    let ids;
    if (dependencyName) {
      ids = payload[dependencyName]
        .map((item) => item[dependencyName])
        .toString();
    }
    const endpoints = {
      roleId: `/v1/users/${userId}/roles`,
      regionId: `/v1/users/${userId}/regions`,
      marketId: `/v1/users/${userId}/markets?regionId=${ids}`,
      brandId: `/v1/users/${userId}/brands?marketId=${ids}`,
      branchId: `/v1/users/${userId}/branches?brandId=${ids}`
    };

    const index = name.indexOf("Id");
    fetchClient()
      .get(endpoints[name])
      .then((res) => {
        setDropdownData({
          ...dropdownData,
          [name]:
            name !== "roleId"
              ? isMultipleSelect()[name.slice(0, index)] && res.data.length > 0
                ? [
                    {
                      id: 0,
                      [name.slice(0, index) + "Description"]: getTranslation(
                        "Select All",
                        t
                      )
                    }
                  ].concat(res.data)
                : res.data
              : res.data
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setOpen({ ...open, [name]: true }));
  };

  const handleCloseDropdown = (e) =>
    setOpen({ ...open, [e.target.name]: false });

  const validate = () => {
    const err = {};
    if (!payload.name) {
      err.name = true;
    }
    if (!payload.surname) {
      err.surname = true;
    }
    const regex = new RegExp("[a-zA-Z0-9]+@[a-z]+.[a-z]{2,3}");
    if (!payload.email.match(regex)) {
      err.email = true;
    }
    if (!payload.roleId) {
      err.roleId = true;
    }
    if (payload.regionId.length === 0) {
      err.regionId = true;
    }
    if (payload.marketId.length === 0) {
      err.marketId = true;
    }
    if (payload.brandId.length === 0) {
      err.brandId = true;
    }
    if (roleValue.accessLevel === 1 || roleValue.accessLevel === 2) {
      if (payload.branchId.length === 0) {
        err.branchId = true;
      }
    }
    setErrors(err);
  };

  const handleSave = () => {
    submitCount.current++;
    setIsSubmit(true);
    validate();
  };

  const submitRequest = () => {
    // configure payload arrays to only send ids
    const data = {
      ...payload,
      createdBy:
        mode === "create" ? currentUser.userInfo.userId : payload.createdBy,
      regionId: payload.regionId.map((item) => item.regionId),
      marketId: payload.marketId.map((item) => item.marketId),
      brandId: payload.brandId.map((item) => item.brandId),
      branchId: payload.branchId.map((item) => item.branchId)
    };

    (mode === "create" ? fetchClient().post : fetchClient().put)(
      "/v1/users",
      data
    )
      .then((res) => {
        setSuccessModalValues({
          message: `User ${
            mode === "create" ? "created" : "saved"
          } successfully`,
          isVisible: true
        });
        setTimeout(() => {
          setSuccessModalValues({ message: "", isVisible: false });
          Navigate("/user-management");
        }, 3000);
      })
      .catch((err) => {
        if (err.response.data === "Email already exists.") {
          setErrors({ ...errors, emailExists: true });
          setIsSubmit(false);
        } else if (err.response.data.includes("Region(s) without Market:")) {
          setErrors({ ...errors, missingMarket: true });
          setErrorMsgs({ ...errorMsgs, missingMarket: err.response.data });
          setIsSubmit(false);
        } else if (err.response.data.includes("Market(s) without Brands:")) {
          setErrors({ ...errors, missingBrand: true });
          setErrorMsgs({ ...errorMsgs, missingBrand: err.response.data });
          setIsSubmit(false);
        } else {
          setAuth(false);
          setTimeout(() => {
            Navigate("/system-error");
          }, 250);
          console.log(err);
        }
      });
  };

  useEffect(() => {
    if (!roleValue.accessLevel) setRoleValue({ ...roleValue, roleDescription: getTranslation("Select", t) })
  }, [t])

  return (
    <div className="user-form">
      <div className="d-flex gap-5 align-items-center mb-4 header">
        <img
          onClick={handleBack}
          src={getIcon("arrow-back.svg")}
          alt="back icon"
        />
        {mode === "create" && (
          <p className="h1">{getTranslation("Create User", t)}</p>
        )}
        {mode === "edit" && (
          <p className="h1">{getTranslation("Edit User", t)}</p>
        )}
      </div>
      <div className="module-cont row gx-5">
        <h2 className="mb-5">{getTranslation("User Table", t)}</h2>
        <UserInput
          handleChange={handleChange}
          label="Name"
          value={payload.name}
          name="name"
          error={errors.name}
        />
        <UserInput
          handleChange={handleChange}
          label="Surname"
          value={payload.surname}
          name="surname"
          error={errors.surname}
        />
        <UserInput
          handleChange={handleChange}
          label="Email"
          value={payload.email}
          name="email"
          errors={errors}
          error={errors.email || errors.emailExists}
        />
        <UserDropdown
          label="Role"
          name="roleId"
          error={errors.roleId}
          value={roleValue}
          open={open.roleId}
          handleOpen={() => handleOpenDropdown("", "roleId")}
          handleClose={handleCloseDropdown}
          handleChange={handleChange}
          textField="roleDescription"
          data={dropdownData.roleId}
          disabled={currentUser.userInfo.accessLevel === 2}
        />
        <UserDropdown
          label="Region"
          name="regionId"
          error={errors.regionId}
          value={payload.regionId}
          open={open.regionId}
          dataItemKey="regionId"
          handleOpen={() => handleOpenDropdown("", "regionId")}
          handleClose={handleCloseDropdown}
          textField="regionDescription"
          data={dropdownData.regionId}
          handleChange={handleChange}
          disabled={isDisabled().region}
        />
        <UserDropdown
          label="Market"
          name="marketId"
          error={errors.marketId || errors.missingMarket}
          errors={errors}
          errorMsgs={errorMsgs}
          value={payload.marketId}
          open={open.marketId}
          dataItemKey="marketId"
          handleOpen={(e) => handleOpenDropdown("regionId", "marketId")}
          handleClose={handleCloseDropdown}
          disabled={isDisabled().market ? true : payload.regionId.length === 0}
          textField="marketDescription"
          data={dropdownData.marketId}
          handleChange={handleChange}
        />
        <UserDropdown
          label="Brands"
          name="brandId"
          error={errors.brandId || errors.missingBrand}
          errors={errors}
          errorMsgs={errorMsgs}
          value={payload.brandId}
          open={open.brandId}
          dataItemKey="brandId"
          handleChange={handleChange}
          handleOpen={() => handleOpenDropdown("marketId", "brandId")}
          handleClose={handleCloseDropdown}
          disabled={isDisabled().brand ? true : payload.marketId.length === 0}
          textField="brandDescription"
          data={dropdownData.brandId}
        />
        <UserDropdown
          label="Branch"
          name="branchId"
          error={errors.branchId}
          value={payload.branchId}
          open={open.branchId}
          dataItemKey="branchId"
          handleChange={handleChange}
          handleOpen={() => handleOpenDropdown("brandId", "branchId")}
          handleClose={handleCloseDropdown}
          disabled={isDisabled().branch ? true : payload.brandId.length === 0}
          textField="branchDescription"
          data={dropdownData.branchId}
        />
        <button onClick={handleSave} className="primary-btn col-lg-1 ms-4 mt-3">
          {getTranslation("Save", t)}
        </button>
      </div>
      {dialogValues.back && (
        <SavingModal
          message={getTranslation("Do you want to leave without saving?", t)}
          onclickHandleNo={() =>
            setDialogValues({ ...dialogValues, back: false })
          }
          onclickHandleYes={() => Navigate("/user-management")}
        />
      )}
    </div>
  );
};

export default UserForm;
