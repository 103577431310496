export const usageChartColors = [
  "#16bae7",
  "#9e61a4",
  "#b7cd00",
  "#e40521",
  "#65a8ba",
  "#ed850c",
  "#0d98ff",
  "#97b05f",
  "#5e308a",
  "#c42759",
  "#00233a"
];
