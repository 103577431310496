/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import "../../styles/shared/sidebar.css";
import { connect } from "react-redux";
import { getIcon } from "../../utils/iconUtils";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Cookie from "../../api/Cookies";
import { formatLogo } from "./brandLogo";
import { fetchImg } from "../../utils/iconUtils";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../common/translation";

const Sidebar = ({
  currentUser,
  setAuth,
  setLoading,
  setLoadingCount,
  setSearchModuleParams,
  logout
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  let location = useLocation();

  const menuItems = [
    {
      name: "Dashboard",
      icon: getIcon("dashboard.svg"),
      active: location.pathname === "/dashboard",
      navigate: "/dashboard",
      resetFilters: () => {
        localStorage.removeItem("dashboardFilters");
        localStorage.removeItem("campaignLeadsFilters");
      }
    },
    {
      name: "Campaigns",
      icon: getIcon("campaigns.png"),
      active: matchPath("/campaigns/*", location.pathname),
      navigate: "/campaigns",
      resetFilters: () => {
        localStorage.removeItem("campaignFilters");
        localStorage.removeItem("campaignLeadsFilters");
      }
    },
    {
      name: "Service Alerts",
      icon: getIcon("serviceAlerts.png"),
      active: location.pathname === "/service-alerts",
      navigate: "/service-alerts",
      resetFilters: () => localStorage.removeItem("serviceAlertsFilters")
    },
    {
      name: "Portfolio",
      icon: getIcon("portfolio.png"),
      active: location.pathname === "/portfolio",
      navigate: "/portfolio"
    },
    {
      name: "Search Module",
      icon: getIcon("searchModule.png"),
      active: location.pathname === "/search-module",
      navigate: "/search-module",
      resetFilters: () => {
        setSearchModuleParams({
          sort: "",
          filters: [],
          statusFilters: "",
          leadType: "",
          name: "",
          bank: "",
          postalCode: "",
          currentVehicle: "",
          contractId: "",
          phoneNumber: "",
          licensePlate: "",
          vinNumber: ""
        });
        localStorage.removeItem("searchModuleFilters");
      }
    },
    // NO REQUIREMENTS YET DEFINED
    // {
    //   name: "Marketing Tools",
    //   icon: getIcon("marketingTools.png"),
    //   active: location.pathname === "/marketing-tools",
    //   navigate: "/marketing-tools"
    // },
    {
      name: "Reports",
      icon: getIcon("reports.png"),
      active: matchPath("/reports/*", location.pathname),
      navigate: "/reports"
    },
    {
      name: "User Management",
      icon: getIcon("user.svg"),
      active:
        matchPath("/user-management/*", location.pathname) ||
        matchPath("/access-control", location.pathname),
      navigate: "/user-management",
      resetFilters: () => localStorage.removeItem("usersFilters")
    },
    {
      name: "Settings",
      icon: getIcon("settings.png"),
      active: location.pathname === "/settings",
      navigate: "/settings"
    }
  ];

  //useStates
  const [toggleSidebar, setToggleSidebar] = useState(false);

  //useEffects
  //Auto toggle if screen is less than 768px
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768 && !toggleSidebar) {
        setToggleSidebar(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [toggleSidebar]);

  //sidebar handle toggle
  const handleSidebarToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setToggleSidebar(!toggleSidebar);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Cookie.remove("token");
    Cookie.remove("rftoken");
    Cookie.remove("subdomain");
    Cookie.remove("market");
    const storage = Object.keys(localStorage);
    for (const key in storage) {
      if (!["lng", "lastInteractionTime"].includes(storage[key]))
        localStorage.removeItem(storage[key]);
    }
    setAuth(false);
    setLoadingCount(0);
    logout();
    Navigate("/login");
  };

  // Load side menu items
  return (
    <div
      className={`sidebar-container ${
        !toggleSidebar ? "sidebar-container-open" : ""
      }`}
    >
      <div className="sidebar-upper">
        {/* INCHCAPE LOGO & TITLE */}
        <div className="sidebar-header">
          <div>
            <img
              src={
                !toggleSidebar
                  ? getIcon("inchcapeLogo.png")
                  : getIcon("inchcapeLoguito.png")
              }
              alt="inchcape logo"
            />
          </div>
          <span>Retention Engine</span>
        </div>
        {/* LOGO BRANDS */}
        <div className="sidebar-logo">
          <div>
            <img
              draggable={false}
              src={fetchImg(
                "logo/" + formatLogo(currentUser.settings.brandDescription)
              )}
              onError={(e) => {
                e.target.style.display = "none";
              }}
              alt={"icon"}
            />
          </div>
        </div>
        {/* USER NAME & POSITION */}
        <div className="sidebar-user-content d-flex align-items-center">
          <span className="user-icon">
            <img src={getIcon("avatar.png")} alt="user icon" />
          </span>
          <div className="user-items">
            <span className="profile-name">
              {currentUser.userInfo.userFullName}
            </span>
            <span className="profile-email">{currentUser.userInfo.email}</span>
            <span className="profile-role">
              {currentUser.userInfo.roleDescription}
            </span>
          </div>
        </div>
        {/* SIDEBAR CONTENT */}
        <div className="content-sides">
          <div className="sidebar-content">
            <Nav className="sidebar-navigation flex-column">
              {menuItems.map((item, idx) => {
                if (currentUser.menu.includes(item.name)) {
                  return (
                    <Nav.Link
                      key={idx}
                      className="sidebar-links"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setLoading(false);
                        setLoadingCount(0);
                        if (item.resetFilters) {
                          item.resetFilters();
                        }
                        Navigate(item.navigate);
                      }}
                      active={item.active}
                    >
                      <span>
                        <img
                          src={item.icon}
                          alt={`${item.name.toLowerCase()} icon`}
                        />
                      </span>
                      <span>{getTranslation(item.name, t)}</span>
                    </Nav.Link>
                  );
                }
              })}
            </Nav>
          </div>
          <div>
            <Nav.Link className="logout-nav" onClick={(e) => handleLogout(e)}>
              <span>
                <img src={getIcon("logOut.png")} alt="logout icon" />
              </span>
              <span>{getTranslation("Log Out", t)}</span>
            </Nav.Link>
          </div>
        </div>
      </div>
      {/* SIDEBAR FOOTER */}
      <div className="sidebar-lower">
        <div className="sidebar-footer">
          <Nav className="sidebar-navigation-footer flex-column">
            <Nav.Link
              className="sidebar-links"
              onClick={(e) => handleSidebarToggle(e)}
            >
              <span>
                <img
                  src={
                    toggleSidebar
                      ? getIcon("altToggle.svg")
                      : getIcon("toggle.png")
                  }
                  alt="toggle icon"
                />
              </span>
              <span>{getTranslation("Toggle Sidebar", t)}</span>
            </Nav.Link>
          </Nav>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
