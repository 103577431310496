import placeholder from "../../assets/images/empty-car.png";

const cars = [
  {
    name: "BMW 3-Series",
    img: placeholder,
    origPrice: "$429",
    matchPay: "$31",
    isModel: true,
    isVersion: false,
    isModelFullName: false
  },
  {
    name: "BMW 5-Series",
    img: placeholder,
    origPrice: "$435",
    matchPay: "$35",
    isModel: true,
    isVersion: false,
    isModelFullName: false
  },
  {
    name: "BMW 7-Series",
    img: placeholder,
    origPrice: "$429",
    matchPay: "$31",
    isModel: true,
    isVersion: false,
    isModelFullName: false
  },
  {
    name: "BMW X5",
    img: placeholder,
    origPrice: "$435",
    matchPay: "$35",
    isModel: true,
    isVersion: false,
    isModelFullName: false
  },
  {
    name: "BMW X7",
    img: placeholder,
    origPrice: "$429",
    matchPay: "$31",
    isModel: true,
    isVersion: false,
    isModelFullName: false
  },
  {
    name: "BMW 3 Version 1",
    img: placeholder,
    origPrice: "$429",
    matchPay: "$31",
    isModel: false,
    isVersion: true,
    isModelFullName: false,
    model: "BMW 3-Series"
  },
  {
    name: "BMW 3 Version 2",
    img: placeholder,
    origPrice: "$429",
    matchPay: "$31",
    isModel: false,
    isVersion: true,
    isModelFullName: false,
    model: "BMW 3-Series"
  },
  {
    name: "BMW 7 Version",
    img: placeholder,
    origPrice: "$435",
    matchPay: "$35",
    isModel: false,
    isVersion: true,
    isModelFullName: false,
    model: "BMW 7-Series"
  },
  {
    name: "BMW 5 Version",
    img: placeholder,
    origPrice: "$429",
    matchPay: "$31",
    isModel: false,
    isVersion: true,
    isModelFullName: false,
    model: "BMW 5-Series"
  },
  {
    name: "BMW X5 Version",
    img: placeholder,
    origPrice: "$435",
    matchPay: "$35",
    isModel: false,
    isVersion: true,
    isModelFullName: false,
    model: "BMW X5"
  },
  {
    name: "BMW X7 Version",
    img: placeholder,
    origPrice: "$429",
    matchPay: "$31",
    isModel: false,
    isVersion: true,
    isModelFullName: false,
    model: "BMW X7"
  },
  {
    name: "BMW 3-Series Touring LCI 318i 1",
    img: placeholder,
    origPrice: "$429",
    matchPay: "$31",
    isModel: false,
    isVersion: false,
    isModelFullName: true,
    model: "BMW 3-Series",
    version: "BMW 3 Version 1"
  },
  {
    name: "BMW 3-Series Touring LCI 318i 2",
    img: placeholder,
    origPrice: "$429",
    matchPay: "$31",
    isModel: false,
    isVersion: false,
    isModelFullName: true,
    model: "BMW 3-Series",
    version: "BMW 3 Version 1"
  },
  {
    name: "BMW 3-Series Touring LCI 318i 3",
    img: placeholder,
    origPrice: "$429",
    matchPay: "$31",
    isModel: false,
    isVersion: false,
    isModelFullName: true,
    model: "BMW 3-Series",
    version: "BMW 3 Version 1"
  },
  {
    name: "BMW 3-Series Touring LCI 318i 4",
    img: placeholder,
    origPrice: "$429",
    matchPay: "$31",
    isModel: false,
    isVersion: false,
    isModelFullName: true,
    model: "BMW 3-Series",
    version: "BMW 3 Version 2"
  },
  {
    name: "BMW 3-Series Touring LCI 318i 5",
    img: placeholder,
    origPrice: "$429",
    matchPay: "$31",
    isModel: false,
    isVersion: false,
    isModelFullName: true,
    model: "BMW 3-Series",
    version: "BMW 3 Version 2"
  },
  {
    name: "BMW 5-Series Touring LCI 320i",
    img: placeholder,
    origPrice: "$435",
    matchPay: "$35",
    isModel: false,
    isVersion: false,
    isModelFullName: true,
    model: "BMW 5-Series",
    version: "BMW 5 Version"
  },
  {
    name: "BMW 7-Series Touring LCI 320d MHEV",
    img: placeholder,
    origPrice: "$429",
    matchPay: "$31",
    isModel: false,
    isVersion: false,
    isModelFullName: true,
    model: "BMW 7-Series",
    version: "BMW 7 Version"
  },
  {
    name: "BMW X5-Series Touring LCI 318i",
    img: placeholder,
    origPrice: "$435",
    matchPay: "$35",
    isModel: false,
    isVersion: false,
    isModelFullName: true,
    model: "BMW X5",
    version: "BMW X5 Version"
  },
  {
    name: "BMW X5-Series Touring LCI 320d MHEV",
    img: placeholder,
    origPrice: "$429",
    matchPay: "$31",
    isModel: false,
    isVersion: false,
    isModelFullName: true,
    model: "BMW X5",
    version: "BMW X5 Version"
  },
  {
    name: "BMW X7-Series Touring LCI 320d MHEV",
    img: placeholder,
    origPrice: "$429",
    matchPay: "$31",
    isModel: false,
    isVersion: false,
    isModelFullName: true,
    model: "BMW X7",
    version: "BMW X7 Version"
  }
];

export default cars;
