import { replaceAll } from '../../common';

export const getTranslation = (input, t) => {
    if (!t) return input;
    if (!input || input === null || input === undefined) return input;


    let translationKey = replaceAll(input);
    let translatedValue = t(translationKey);

    return translatedValue !== translationKey
        ? translatedValue
        : input;
}