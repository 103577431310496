import style from "./Landing.module.css";
import lines from "../../imagenes/line/Lines.svg";
import logo from "../../imagenes/logo/inchcapeLogo.png";
import { useNavigate } from "react-router-dom";
import fetchClient from "../../../../api/fetch";
import { useEffect, useState } from "react";

const Landing = () => {
  const Navigate = useNavigate();
  const searchValue = window.location.search.split("=")[1];
  const hasSessionId =
    searchValue !== undefined && searchValue !== null && searchValue !== "";
  const origin = window.location.origin;
  const [loginIndicator, setLoginIndicator] = useState("Login");

  const onLoginClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (origin.includes("localhost")) {
      //TO TEST IT IN LOCAL AS SAME FUNCTIONALITY
      setLoginIndicator("Logging in...");
      setTimeout(() => {
        Navigate("/pinglogin");
      }, 800);
    } else {
      setLoginIndicator("Logging in...");
      fetchClient()
        .get("/v1/sso")
        .then((response) => {
          if (response.data) {
            window.location.href = response.data;
          }
        })
        .catch((err) => {
          console.log(err);
          setLoginIndicator("Login");
        });
    }
  };

  useEffect(() => {
    if (hasSessionId) {
      setLoginIndicator("Logging in...");
    }
  }, [hasSessionId]);

  return (
    <div className={style.main}>
      <div className={style.container}>
        <img
          className={`${style.lines} align-self-end align-self-sm-center`}
          src={lines}
          alt="lines icon"
        />
        <div
          className={`${style.contents} justify-content-sm-between justify-content-evenly`}
        >
          <img src={logo} alt="inchcape logo" />
          <div>
            <p>RETENTION</p>
            <p>ENGINE</p>
          </div>
          <button
            className="primary-btn"
            style={{
              cursor: loginIndicator === "Logging in..." ? "default" : "pointer"
            }}
            onClick={hasSessionId ? undefined : (e) => onLoginClick(e)}
          >
            {loginIndicator}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Landing;
