import {
  SET_CAMPAIGN_LIST,
  GET_CAMPAIGN_LIST,
  SET_CAMPAIGN_LEADS_LIST,
  GET_CAMPAIGN_LEADS_LIST,
  SET_LOADING,
  SET_AUTH,
  GET_CURRENT_USER,
  SET_CURRENT_USER,
  GET_USERS_LIST,
  SET_USERS_LIST,
  SET_USERS_LIST_PAGE,
  SET_CAMPAIGN_LIST_PAGE,
  SET_CAMPAIGN_LEADS_LIST_PAGE,
  GET_MARKET_SETTINGS,
  SET_MARKET_SETTINGS,
  GET_VEHICLE_SELECTOR_LIST,
  SET_VEHICLE_SELECTOR_LIST,
  SET_VEHICLE_SELECTOR_LIST_PAGE,
  GET_LEADS_LIST,
  SET_LEADS_LIST,
  SET_LEADS_LIST_PAGE,
  SET_LEADS_REFERENCE_KEY,
  GET_LEADS_REFERENCE_KEY,
  GET_LEADS_COUNT,
  SET_LEADS_COUNT,
  GET_HEADER_USER_MARKETS,
  GET_HEADER_USER_BRANDS,
  SET_HEADER_USER_MARKETS,
  SET_HEADER_USER_BRANDS,
  SET_USER_SETTINGS,
  SET_CAMPAIGN_LEADS,
  GET_USER_BRANCHES,
  SET_USER_BRANCHES,
  GET_PORTFOLIO_LIST,
  SET_PORTFOLIO_LIST,
  SET_PORTFOLIO_LIST_PAGE,
  SET_LOADING_COUNT,
  SET_DASHBOARD_LOAD_TYPE,
  GET_SERVICE_ALERTS_LIST,
  SET_SERVICE_ALERTS_LIST,
  SET_SERVICE_ALERTS_LIST_PAGE,
  GET_SEARCH_MODULE_LIST,
  SET_SEARCH_MODULE_LIST,
  SET_SEARCH_MODULE_LIST_PAGE,
  SET_SEARCH_MODULE_PARAMS,
  SET_SUCCESS_MODAL_VALUES,
  EXPORT_CAMPAIGN,
  SET_REMOVE_OPPORTUNITY_DATA,
  GET_CUSTOMER_RECORD_DATA,
  SET_CUSTOMER_RECORD_DATA,
  GET_CLIENT_POSITION_DATA,
  SET_CLIENT_POSITION_DATA,
  GET_FINANCE_RECORD_DATA,
  SET_FINANCE_RECORD_DATA,
  GET_SPECIALISTS,
  SET_SPECIALISTS,
  GET_CUSTOMER_RECORD_USER_LIST,
  SET_CUSTOMER_RECORD_USER_LIST,
  SET_SERVICE_ALERTS_FILTERS,
  GET_ACTIVITY_RECORD,
  SET_ACTIVITY_RECORD,
  UPDATE_ACTIVITY_RECORD,
  GET_REPORTS_USAGE_GRID_LIST,
  SET_REPORTS_USAGE_GRID_LIST,
  SET_REPORTS_USAGE_GRID_LIST_PAGE,
  EXPORT_USAGE_REPORT,
  GET_REPORTS_CONVERSION_GRID_LIST,
  SET_REPORTS_CONVERSION_GRID_LIST,
  SET_REPORTS_CONVERSION_GRID_LIST_PAGE,
  EXPORT_CONVERSION_REPORT,
  LOGOUT_ACTION
} from "./types";

/* Declare actions using the created types */

export const loadCampaignList = (args) => ({
  type: GET_CAMPAIGN_LIST,
  param: args
});

export const setCampaignList = (campaignList) => ({
  type: SET_CAMPAIGN_LIST,
  campaignList
});

export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  isLoading
});

export const setAuth = (isAuth) => ({
  type: SET_AUTH,
  isAuth
});

export const loadCurrentUser = () => ({
  type: GET_CURRENT_USER
});

export const setCurrentUser = (currentUser) => ({
  type: SET_CURRENT_USER,
  currentUser
});

export const loadUsersList = (args) => ({
  type: GET_USERS_LIST,
  param: args
});

export const setUsersList = (usersList) => ({
  type: SET_USERS_LIST,
  usersList
});

export const setUsersListPage = (usersListPage) => ({
  type: SET_USERS_LIST_PAGE,
  usersListPage
});

export const setCampaignListPage = (campaignListPage) => ({
  type: SET_CAMPAIGN_LIST_PAGE,
  campaignListPage
});

export const loadCampaignLeadsList = (campaignId, args) => ({
  type: GET_CAMPAIGN_LEADS_LIST,
  campaignId,
  param: args
});

export const setCampaignLeadsList = (campaignLeadsList) => ({
  type: SET_CAMPAIGN_LEADS_LIST,
  campaignLeadsList
});

export const setCampaignLeadsListPage = (campaignLeadsListPage) => ({
  type: SET_CAMPAIGN_LEADS_LIST_PAGE,
  campaignLeadsListPage
});

export const loadMarketSettings = () => ({
  type: GET_MARKET_SETTINGS
});

export const setMarketSettings = (marketSettings) => ({
  type: SET_MARKET_SETTINGS,
  marketSettings
});

export const loadVehicleSelectorList = (args) => ({
  type: GET_VEHICLE_SELECTOR_LIST,
  param: args.param,
  mode: args.mode
});

export const setVehicleSelectorList = (mode, vehicleList) => ({
  type: SET_VEHICLE_SELECTOR_LIST,
  mode,
  vehicleList
});

export const setVehicleSelectorListPage = (vehicleSelectorListPage, mode) => ({
  type: SET_VEHICLE_SELECTOR_LIST_PAGE,
  vehicleSelectorListPage,
  mode
});
export const loadLeadsCount = (payload) => ({
  type: GET_LEADS_COUNT,
  payload
});

export const setLeadsCount = (leadsCount) => ({
  type: SET_LEADS_COUNT,
  leadsCount
});

export const loadLeadsList = (args) => ({
  type: GET_LEADS_LIST,
  param: args
});

export const setLeadsList = (leadsList) => ({
  type: SET_LEADS_LIST,
  leadsList
});

export const setLeadsListPage = (leadsListPage) => ({
  type: SET_LEADS_LIST_PAGE,
  leadsListPage
});

export const loadLeadsReferenceKey = () => ({
  type: GET_LEADS_REFERENCE_KEY
});

export const setLeadsReferenceKey = (leadsReferenceKey) => ({
  type: SET_LEADS_REFERENCE_KEY,
  leadsReferenceKey
});

export const loadHeaderUserMarkets = () => ({
  type: GET_HEADER_USER_MARKETS
});

export const loadHeaderUserBrands = (marketId) => ({
  type: GET_HEADER_USER_BRANDS,
  marketId
});

export const setHeaderUserMarkets = (headerUserMarkets) => ({
  type: SET_HEADER_USER_MARKETS,
  headerUserMarkets
});

export const setHeaderUserBrands = (headerUserBrands) => ({
  type: SET_HEADER_USER_BRANDS,
  headerUserBrands
});

export const updateUserSettings = (userSettings) => ({
  type: SET_USER_SETTINGS,
  userSettings
});

export const loadUserBranches = (brandId) => ({
  type: GET_USER_BRANCHES,
  brandId
});

export const setUserBranches = (userBranches) => ({
  type: SET_USER_BRANCHES,
  userBranches
});

export const saveCampaignLeads = (data) => ({
  type: SET_CAMPAIGN_LEADS,
  data
});

export const loadPortfolioList = (args) => ({
  type: GET_PORTFOLIO_LIST,
  param: args
});

export const setPortfolioList = (portfolioList) => ({
  type: SET_PORTFOLIO_LIST,
  portfolioList
});

export const setPortfolioListPage = (portfolioListPage) => ({
  type: SET_PORTFOLIO_LIST_PAGE,
  portfolioListPage
});

export const setLoadingCount = (count) => ({
  type: SET_LOADING_COUNT,
  count
});

export const setDashboardLoadType = (dashboardType) => ({
  type: SET_DASHBOARD_LOAD_TYPE,
  dashboardType
});

export const loadServiceAlertsList = (args) => ({
  type: GET_SERVICE_ALERTS_LIST,
  param: args
});

export const setServiceAlertsList = (serviceAlertsList) => ({
  type: SET_SERVICE_ALERTS_LIST,
  serviceAlertsList
});

export const setServiceAlertsListPage = (serviceAlertsListPage) => ({
  type: SET_SERVICE_ALERTS_LIST_PAGE,
  serviceAlertsListPage
});

export const loadSearchModuleList = (args) => ({
  type: GET_SEARCH_MODULE_LIST,
  param: args
});

export const setSearchModuleList = (searchModuleList) => ({
  type: SET_SEARCH_MODULE_LIST,
  searchModuleList
});

export const setSearchModuleListPage = (searchModuleListPage) => ({
  type: SET_SEARCH_MODULE_LIST_PAGE,
  searchModuleListPage
});

export const setSearchModuleParams = (searchModuleParams) => ({
  type: SET_SEARCH_MODULE_PARAMS,
  searchModuleParams
});

export const setSuccessModalValues = (successModalValues) => ({
  type: SET_SUCCESS_MODAL_VALUES,
  successModalValues
});

export const exportCampaign = (campaignId) => ({
  type: EXPORT_CAMPAIGN,
  campaignId
});

export const removeOpportunity = (removeOpportunityData) => ({
  type: SET_REMOVE_OPPORTUNITY_DATA,
  removeOpportunityData
});

export const loadCustomerRecordData = (id) => ({
  type: GET_CUSTOMER_RECORD_DATA,
  id
});

export const setCustomerRecordData = (customerRecordData) => ({
  type: SET_CUSTOMER_RECORD_DATA,
  customerRecordData
});

export const loadClientPositionData = () => ({
  type: GET_CLIENT_POSITION_DATA
});

export const setClientPositionData = (clientPositionData) => ({
  type: SET_CLIENT_POSITION_DATA,
  clientPositionData
});

export const loadFinanceRecordData = () => ({
  type: GET_FINANCE_RECORD_DATA
});

export const setFinanceRecordData = (financeRecordData) => ({
  type: SET_FINANCE_RECORD_DATA,
  financeRecordData
});

export const loadCustomerRecordUserList = () => ({
  type: GET_CUSTOMER_RECORD_USER_LIST
});

export const setCustomerRecordUserList = (customerRecordUserList) => ({
  type: SET_CUSTOMER_RECORD_USER_LIST,
  customerRecordUserList
});

export const loadSpecialists = () => ({
  type: GET_SPECIALISTS
});

export const setSpecialists = (specialists) => ({
  type: SET_SPECIALISTS,
  specialists
});

export const loadActivityRecord = (id) => ({
  type: GET_ACTIVITY_RECORD,
  id
});

export const setActivityRecord = (activityRecord) => ({
  type: SET_ACTIVITY_RECORD,
  activityRecord
});

export const updateActivityRecord = (updateActivityRecordData, id) => ({
  type: UPDATE_ACTIVITY_RECORD,
  updateActivityRecordData,
  id
});

export const loadReportsUsageGridList = (args) => ({
  type: GET_REPORTS_USAGE_GRID_LIST,
  param: args
});

export const setReportsUsageGridList = (reportsUsageGridList) => ({
  type: SET_REPORTS_USAGE_GRID_LIST,
  reportsUsageGridList
});

export const setReportsUsageGridListPage = (reportsUsageGridListPage) => ({
  type: SET_REPORTS_USAGE_GRID_LIST_PAGE,
  reportsUsageGridListPage
});

export const loadReportsConversionGridList = (args) => ({
  type: GET_REPORTS_CONVERSION_GRID_LIST,
  param: args
});

export const setReportsConversionGridList = (reportsConversionGridList) => ({
  type: SET_REPORTS_CONVERSION_GRID_LIST,
  reportsConversionGridList
});

export const setReportsConversionGridListPage = (
  reportsConversionGridListPage
) => ({
  type: SET_REPORTS_CONVERSION_GRID_LIST_PAGE,
  reportsConversionGridListPage
});

export const exportUsageReport = (filters) => ({
  type: EXPORT_USAGE_REPORT,
  filters
});

export const exportConversionReport = (filters) => ({
  type: EXPORT_CONVERSION_REPORT,
  filters
});

export const logoutAction = () => ({
  type: LOGOUT_ACTION
});
