import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  setLoading,
  setLoadingCount,
  setReportsConversionGridList,
  setReportsConversionGridListPage,
  setReportsUsageGridList,
  setReportsUsageGridListPage
} from "../actions";
import {
  getReportsConversionGridList,
  getReportsUsageGridList
} from "../api/handler";
import {
  GET_REPORTS_CONVERSION_GRID_LIST,
  GET_REPORTS_USAGE_GRID_LIST
} from "../actions/types";

function* handleLoadReportsUsageGridList(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getReportsUsageGridList, args.param);
    if (response.status === 200) {
      yield put(setReportsUsageGridList(response.data.data));
      yield put(
        setReportsUsageGridListPage({
          totalRecords: response.data.totalRecords,
          totalPages: response.data.totalPages
        })
      );
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
  yield put(setLoadingCount(1));
}

function* handleLoadReportsConversionGridList(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getReportsConversionGridList, args.param);
    if (response.status === 200) {
      yield put(setReportsConversionGridList(response.data.data));
      yield put(
        setReportsConversionGridListPage({
          totalRecords: response.data.totalRecords,
          totalPages: response.data.totalPages
        })
      );
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
  yield put(setLoadingCount(1));
}

export default function* reportsSaga() {
  yield all([
    takeLatest(GET_REPORTS_USAGE_GRID_LIST, handleLoadReportsUsageGridList),
    takeLatest(
      GET_REPORTS_CONVERSION_GRID_LIST,
      handleLoadReportsConversionGridList
    )
  ]);
}
