import React from "react";
import "../../styles/campaigns/campaignModal.css";
import { Dialog } from "@progress/kendo-react-dialogs";
import fetchClient from "../../api/fetch";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../common/translation";

const DeleteCampaignModal = ({
  setShowDeleteCampaignModal,
  campaignId,
  getCampaignList,
  queryBuilder,
  setSuccessModalValues
}) => {
  const { t } = useTranslation();
  const handleAccept = () => {
    fetchClient()
      .delete(`/v1/campaigns/${campaignId}`)
      .then(() => {
        setShowDeleteCampaignModal(false);
        setSuccessModalValues({
          message: getTranslation("Campaign deleted successfully", t),
          isVisible: true
        });
        setTimeout(() => {
          setSuccessModalValues({
            message: "",
            isVisible: false
          });
          getCampaignList(queryBuilder());
        }, 3000);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Dialog onClose={() => setShowDeleteCampaignModal(false)}>
      <div className="delete-campaign-box d-flex flex-column gap-3 justify-content-between">
        <h1 className="h1 text-center mb-3 pt-3">
          {getTranslation("Do you want to delete this campaign?", t)}
        </h1>
        <div className="buttons d-flex justify-content-center gap-2">
          <button
            className="bg-none-btn"
            onClick={() => setShowDeleteCampaignModal(false)}
          >
            <p className="m-0">{getTranslation("No", t)}</p>
          </button>
          <button className="gap-2 primary-btn" onClick={() => handleAccept()}>
            <p className="m-0">{getTranslation("Yes", t)}</p>
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteCampaignModal;
