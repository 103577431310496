import React from "react";
import campaignData from "./sampleCampaignData";
import "../../styles/dashboard/dashboard.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useNavigate } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../common/translation";

const DashboardCampaign = ({ data }) => {
  const Navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <Grid
        data={data.map((item) => ({
          ...item,
          validFrom: item.validFrom ? new Date(item.validFrom) : null,
          validTo: item.validTo ? new Date(item.validTo) : null,
        }))}
        onRowClick={(e) =>
          Navigate(`/campaigns/${e.dataItem.campaignId}/leads`, {
            state: {prevPage: "dashboard"}
          })
        }
      >
        <GridColumn
          field="campaignName"
          title={getTranslation("Campaign name", t)}
        />
        <GridColumn
          field="validFrom"
          title={getTranslation("From", t)}
          format="{0:dd/MM/yyyy}"
        />
        <GridColumn
          field="validTo"
          title={getTranslation("To", t)}
          format="{0:dd/MM/yyyy}"
        />
      </Grid>
    </>
  );
};

export default DashboardCampaign;
