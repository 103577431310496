import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const VSFilterDropdown = ({
  name,
  label,
  value,
  data,
  handleOpen,
  handleChange,
  open,
  handleClose
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <label>{getTranslation(label, t)}</label>
      <DropDownList
        name={name}
        value={getTranslation(value, t)}
        data={data}
        onChange={handleChange}
        opened={open || false}
        onOpen={handleOpen}
        onClose={handleClose}
      />
    </div>
  );
};

export default VSFilterDropdown;
