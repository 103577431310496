/* Declare action types and import to index */

export const SET_LOADING = "IS_LOADING";
export const SET_AUTH = "SET_AUTH";
export const GET_MARKET_SETTINGS = "GET_MARKET_SETTINGS";
export const SET_MARKET_SETTINGS = "SET_MARKET_SETTINGS";
export const GET_HEADER_USER_MARKETS = "GET_HEADER_USER_MARKETS";
export const SET_HEADER_USER_MARKETS = "SET_HEADER_USER_MARKETS";
export const GET_HEADER_USER_BRANDS = "GET_HEADER_USER_BRANDS";
export const SET_HEADER_USER_BRANDS = "SET_HEADER_USER_BRANDS";
export const GET_USER_BRANCHES = "GET_USER_BRANCHES";
export const SET_USER_BRANCHES = "SET_USER_BRANCHES";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";

export const SET_CAMPAIGN_LIST = "SET_CAMPAIGN_LIST";
export const GET_CAMPAIGN_LIST = "GET_CAMPAIGN_LIST";

export const SET_CAMPAIGN_LEADS_LIST = "SET_CAMPAIGN_LEADS_LIST";
export const GET_CAMPAIGN_LEADS_LIST = "GET_CAMPAIGN_LEADS_LIST";
export const SET_CAMPAIGN_LEADS_LIST_PAGE = "SET_CAMPAIGN_LEADS_LIST_PAGE";

export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const GET_USERS_LIST = "GET_USERS_LIST";
export const SET_USERS_LIST = "SET_USERS_LIST";
export const SET_USERS_LIST_PAGE = "SET_USERS_LIST_PAGE";
export const SET_CAMPAIGN_LIST_PAGE = "SET_CAMPAIGN_LIST_PAGE";

export const GET_VEHICLE_SELECTOR_LIST = "GET_VEHICLE_SELECTOR_DATA";
export const SET_VEHICLE_SELECTOR_LIST = "SET_VEHICLE_SELECTOR_DATA";
export const SET_VEHICLE_SELECTOR_LIST_PAGE = "SET_VEHICLE_SELECTOR_LIST_PAGE";
export const GET_LEADS_COUNT = "GET_LEADS_COUNT";
export const SET_LEADS_COUNT = "SET_LEADS_COUNT";
export const GET_LEADS_LIST = "GET_LEADS_LIST";
export const SET_LEADS_LIST = "SET_LEADS_LIST";
export const SET_LEADS_LIST_PAGE = "SET_LEADS_LIST_PAGE";
export const GET_LEADS_REFERENCE_KEY = "GET_LEADS_REFERENCE_KEY";
export const SET_LEADS_REFERENCE_KEY = "SET_LEADS_REFERENCE_KEY";
export const SET_CAMPAIGN_LEADS = "SET_CAMPAIGN_LEADS";
export const GET_PORTFOLIO_LIST = "GET_PORTFOLIO_LIST";
export const SET_PORTFOLIO_LIST = "SET_PORTFOLIO_LIST";
export const SET_PORTFOLIO_LIST_PAGE = "SET_PORTFOLIO_LIST_PAGE";
export const SET_LOADING_COUNT = "SET_LOADING_COUNT";
export const SET_DASHBOARD_LOAD_TYPE = "SET_DASHBOARD_LOAD_TYPE";
export const GET_SERVICE_ALERTS_LIST = "GET_SERVICE_ALERTS_LIST";
export const SET_SERVICE_ALERTS_LIST = "SET_SERVICE_ALERTS_LIST";
export const SET_SERVICE_ALERTS_LIST_PAGE = "SET_SERVICE_ALERTS_LIST_PAGE";

export const GET_SEARCH_MODULE_LIST = "GET_SEARCH_MODULE_LIST";
export const SET_SEARCH_MODULE_LIST = "SET_SEARCH_MODULE_LIST";
export const SET_SEARCH_MODULE_LIST_PAGE = "SET_SEARCH_MODULE_LIST_PAGE";
export const GET_SEARCH_MODULE_PARAMS = "GET_SEARCH_MODULE_PARAMS";
export const SET_SEARCH_MODULE_PARAMS = "SET_SEARCH_MODULE_PARAMS";

export const GET_SUCCESS_MODAL_VALUES = "GET_SUCCESS_MODAL_VALUES";
export const SET_SUCCESS_MODAL_VALUES = "SET_SUCCESS_MODAL_VALUES";

export const SET_REMOVE_OPPORTUNITY_DATA = "SET_REMOVE_OOPORTUNITY_DATA";
export const SET_CUSTOMER_RECORD_DATA = "SET_CUSTOMER_RECORD_DATA";
export const GET_CUSTOMER_RECORD_DATA = "GET_CUSTOMER_RECORD_DATA";
export const SET_CLIENT_POSITION_DATA = "SET_CLIENT_POSITION_DATA";
export const GET_CLIENT_POSITION_DATA = "GET_CLIENT_POSITION_DATA";
export const SET_FINANCE_RECORD_DATA = "SET_FINANCE_RECORD_DATA";
export const GET_FINANCE_RECORD_DATA = "GET_FINANCE_RECORD_DATA";
export const SET_CUSTOMER_RECORD_USER_LIST = "SET_CUSTOMER_RECORD_USER_LIST";
export const GET_CUSTOMER_RECORD_USER_LIST = "GET_CUSTOMER_RECORD_USER_LIST";
export const GET_ACTIVITY_RECORD = "GET_ACTIVITY_RECORD";
export const SET_ACTIVITY_RECORD = "SET_ACTIVITY_RECORD";
export const UPDATE_ACTIVITY_RECORD = "UPDATE_ACTIVITY_RECORD";

export const GET_REPORTS_USAGE_GRID_LIST = "GET_REPORTS_USAGE_GRID_LIST";
export const SET_REPORTS_USAGE_GRID_LIST = "SET_REPORTS_USAGE_GRID_LIST";
export const SET_REPORTS_USAGE_GRID_LIST_PAGE =
  "SET_REPORTS_USAGE_GRID_LIST_PAGE";
export const GET_REPORTS_CONVERSION_GRID_LIST =
  "GET_REPORTS_CONVERSION_GRID_LIST";
export const SET_REPORTS_CONVERSION_GRID_LIST =
  "SET_REPORTS_CONVERSION_GRID_LIST";
export const SET_REPORTS_CONVERSION_GRID_LIST_PAGE =
  "SET_REPORTS_CONVERSION_GRID_LIST_PAGE";

export const SET_SPECIALISTS = "SET_SPECIALISTS";
export const GET_SPECIALISTS = "GET_SPECIALISTS";

export const EXPORT_CAMPAIGN = "EXPORT_CAMPAIGN";
export const EXPORT_USAGE_REPORT = "EXPORT_USAGE_REPORT";
export const EXPORT_CONVERSION_REPORT = "EXPORT_CONVERSION_REPORT";

export const LOGOUT_ACTION = "LOGOUT_ACTION";
