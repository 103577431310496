import { takeLatest, call, put, all } from "redux-saga/effects";
import {
  setLoading,
  setLoadingCount,
  setServiceAlertsList,
  setServiceAlertsListPage
} from "../actions";
import { getServiceAlertsList } from "../api/handler";
import { GET_SERVICE_ALERTS_LIST } from "../actions/types";

function* handleLoadServiceAlertsList(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getServiceAlertsList, args.param);
    if (response.status === 200) {
      yield put(setServiceAlertsList(response.data.data));
      yield put(
        setServiceAlertsListPage({
          totalRecords: response.data.totalRecords,
          totalPages: response.data.totalPages
        })
      );
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
  yield put(setLoadingCount(1));
}

export default function* serviceAlertsSaga() {
  yield all([takeLatest(GET_SERVICE_ALERTS_LIST, handleLoadServiceAlertsList)]);
}
