import axios from "axios";
import Cookie from "./Cookies";
import store from "../store";

const BASE_URL = `${window.location.origin}/api`;

const fetchClient = () => {
  const client = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookie.get("token")}`,
      "x-market": Cookie.get("market") || "system"
    }
  });

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      switch (error.response.status) {
        case 401:
          getchToken()
            .post(`${BASE_URL}/v1/auth/refresh`)
            .then((res) => {
              Cookie.set("token", res.data.data.token);
              Cookie.set("rftoken", res.data.data.refreshToken);

              error.config.headers[
                "Authorization"
              ] = `Bearer ${res.data.token}`;

              return axios.request(error.config);
            })
            .catch((err) => {
              Cookie.remove("token");
              Cookie.remove("rftoken");
              store.dispatch({ type: "SET_AUTH", action: false });
              window.location.href = `${window.location.origin}/invalid-identity`;
            });

          break;

        case 403:
          // window.location.href = `${window.location.origin}/invalid-market`;
          break;

        case 405:
          window.location.href = `${window.location.origin}/invalid-identity`;
          break;

        case 404:
          // NOT FOUND

          break;

        default:
          break;
      }
      return Promise.reject(error);
    }
  );

  if (store.getState()) {
    const userInfoState = store.getState().currentUserReducer;
    const hasSettings =
      userInfoState &&
      userInfoState.settings &&
      userInfoState.settings.subdomain;
    if (
      hasSettings &&
      userInfoState.settings.subdomain !== Cookie.get("market")
    ) {
      window.location.reload();
    }
  }

  return client;
};

export const getchToken = () => {
  return axios.create({
    baseURL: BASE_URL,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookie.get("rftoken")}`,
      "x-market": Cookie.get("market") || "system"
    }
  });
};

export default fetchClient;
