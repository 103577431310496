export const serviceAlertsGridColumn = [
  {
    width: "110px",
    title: "Select All",
    isShown: true,
    canBeHidden: false
  },
  {
    column: "customerName",
    width: "200px",
    title: "Customer Name",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },

  {
    column: "equityFormatted",
    width: "160px",
    title: "Equity",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "paymentDifferenceFormatted",
    width: "160px",
    title: "Payment Diff",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "monthsRemaining",
    width: "160px",
    title: "Months Remaining",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "lastActivityDate",
    width: "200px",
    title: "Last Activity",
    filter: "date",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "dealer",
    width: "160px",
    title: "Dealer",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "financeTypeName",
    width: "160px",
    title: "Finance Type",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "salesConsultant",
    width: "200px",
    title: "Owner",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "campaignName",
    width: "160px",
    title: "Campaign",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "newOrUsed",
    width: "160px",
    title: "New/Used",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "licensePlate",
    width: "160px",
    title: "License Plate",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "currentVehicle",
    width: "160px",
    title: "Current Vehicle",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "proposedVehicle",
    width: "160px",
    title: "Proposed Vehicle",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "costOfChangeFormatted",
    width: "160px",
    title: "Cost to Change",
    filter: "text",
    isShown: true,
    canBeHidden: true
  }
];
