import "../../styles/editProposedDeal/editProposedDeal.css";
import React, { useState, useRef } from "react";
import { getIcon } from "../../utils/iconUtils";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import pencil from "../../assets/icons/pencil.svg";
import save from "../../assets/icons/edit-save.svg";
import cancel from "../../assets/icons/edit-cancel.svg";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import { checkMarketAu } from "../../utils/helpers";

const FinanceRecord = ({
  data,
  currencySymbol,
  payload,
  setPayload,
  handleChange,
  handleSave,
  selectedCar
}) => {
  const { t } = useTranslation();
  const [showNewUsedDropdown, setShowNewUsedDropdown] = useState(true);
  const [showEquityDropdown, setShowEquityDropdown] = useState(true);
  const [showTotalDepositDropdown, setShowTotalDepositDropdown] =
    useState(true);
  const [showTermKilometerDropdown, setShowTermKilometerDropdown] =
    useState(true);
  const [isReadOnly, setIsReadOnly] = useState({
    interestRate: true,
    purchasePrice: true
  });
  const dataHolder = useRef({
    interestRate: "",
    purchasePrice: ""
  });

  // Render table header
  const headerCell = (props) => {
    const { title } = props;

    return (
      <div className="tableHeaderCell">
        <label
          className={
            title === "Proposed"
              ? `${"headerTitle"} ${"proposedHeaderTitle"}`
              : "headerTitle"
          }
        >
          <span>{getTranslation(title, t)}</span>
        </label>
      </div>
    );
  };

  const handleEditToggle = (e, mode, colField) => {
    setIsReadOnly({ ...isReadOnly, [colField]: !isReadOnly[colField] });
    switch (mode) {
      case "edit":
        dataHolder.current[colField] = payload.dealCalc[e.target.name];
        // only allow one field to be edited at a time
        if (
          e.target.name === "replacementInterestRatePercentage" &&
          !isReadOnly.purchasePrice
        ) {
          setPayload({
            ...payload,
            dealCalc: {
              ...payload.dealCalc,
              purchasePrice: dataHolder.current.purchasePrice
            }
          });
          setIsReadOnly({ interestRate: false, purchasePrice: true });
        } else if (
          e.target.name === "purchasePrice" &&
          !isReadOnly.interestRate
        ) {
          setPayload({
            ...payload,
            dealCalc: {
              ...payload.dealCalc,
              replacementInterestRatePercentage: dataHolder.current.interestRate
            }
          });
          setIsReadOnly({ interestRate: true, purchasePrice: false });
        }
        break;
      case "cancel":
        setPayload({
          ...payload,
          dealCalc: {
            ...payload.dealCalc,
            [e.target.name]: dataHolder.current[colField]
          }
        });
        break;
      case "save":
        const newValue =
          Number(payload.dealCalc[e.target.name]) % 1 // check if it is decimal
            ? Number(payload.dealCalc[e.target.name]).toFixed(2)
            : Number(payload.dealCalc[e.target.name]);
        setPayload({
          ...payload,
          dealCalc: { ...payload.dealCalc, [e.target.name]: newValue }
        });
        handleSave(newValue, colField);
        break;

      default:
        break;
    }
  };

  //Render table row
  const rowCell = (td, props) => {
    const { dataItem, field } = props;
    const details = getTranslation(dataItem.details, t);
    let colField;
    if (
      details === getTranslation("Interest Rate", t) ||
      details === getTranslation("Purchased Price", t)
    ) {
      const index = dataItem.field.indexOf("Formatted");
      colField = dataItem.field.slice(0, index);
    }
    const newUsedDetails =
      details === getTranslation("Registration date", t) ||
      details === getTranslation("Start date", t) ||
      details === getTranslation("Start kilometers", t) ||
      details === getTranslation("Start age", t);
    const equityDetails =
      details === getTranslation("Trade-in Value", t) ||
      details === getTranslation("Settlement Value", t);
    const totalDepositDetails =
      details === getTranslation("Total manufacturer deposit", t) ||
      details === getTranslation("Total dealer deposit", t) ||
      details === getTranslation("Total customer deposit", t);
    const termTotalKilometerDetails =
      details === getTranslation("Term", t) ||
      details === getTranslation("Total Kilometers", t);

    if (
      (details === getTranslation("Upfront Fees", t) ||
        details === getTranslation("Full Name", t) ||
        details === getTranslation("Comparison Rate", t)) &&
      !checkMarketAu()
    ) {
      return;
    }

    return (
      //add rows to hide
      <td
        {...td.props}
        hidden={
          (showNewUsedDropdown && newUsedDetails) ||
          (showEquityDropdown && equityDetails) ||
          (showTotalDepositDropdown && totalDepositDetails) ||
          (showTermKilometerDropdown && termTotalKilometerDetails)
            ? "hidden"
            : ""
        }
        className={
          newUsedDetails ||
          equityDetails ||
          totalDepositDetails ||
          termTotalKilometerDetails
            ? "expandedDetails"
            : "rowCell"
        }
      >
        {field === "proposed" &&
        (details === getTranslation("Interest Rate", t) ||
          details === getTranslation("Purchased Price", t)) ? (
          <div
            className={`editable ${isReadOnly[colField] ? "read-only" : ""}`}
          >
            {!isReadOnly[colField] && (
              <span>
                {details === getTranslation("Interest Rate", t)
                  ? "%"
                  : currencySymbol}
              </span>
            )}
            <Input
              onChange={handleChange}
              name={
                dataItem.field === "interestRateFormatted"
                  ? "replacementInterestRatePercentage"
                  : "purchasePrice"
              }
              value={
                Object.keys(payload).length > 0
                  ? isReadOnly[colField]
                    ? dataItem.proposed
                    : payload.dealCalc[
                        dataItem.field === "interestRateFormatted"
                          ? "replacementInterestRatePercentage"
                          : "purchasePrice"
                      ]
                        .toString()
                        .replaceAll(",", "")
                  : ""
              }
              readOnly={isReadOnly[colField]}
            />
            <div className="d-flex me-auto gap-2">
              {!isReadOnly[colField] && (
                <img
                  name={
                    dataItem.field === "interestRateFormatted"
                      ? "replacementInterestRatePercentage"
                      : "purchasePrice"
                  }
                  onClick={(e) => handleEditToggle(e, "save", colField)}
                  src={save}
                  alt="save changes icon"
                />
              )}
              {!isReadOnly[colField] && (
                <img
                  name={
                    dataItem.field === "interestRateFormatted"
                      ? "replacementInterestRatePercentage"
                      : "purchasePrice"
                  }
                  onClick={(e) => handleEditToggle(e, "cancel", colField)}
                  src={cancel}
                  alt="cancel changes icon"
                />
              )}
              {isReadOnly[colField] && (
                <img
                  className={selectedCar ? "" : "disabled"}
                  name={
                    dataItem.field === "interestRateFormatted"
                      ? "replacementInterestRatePercentage"
                      : "purchasePrice"
                  }
                  src={pencil}
                  onClick={(e) => handleEditToggle(e, "edit", colField)}
                  alt="edit item icon"
                />
              )}
            </div>
          </div>
        ) : (
          <div
            className={
              details === getTranslation("Monthly payment", t) &&
              field !== "existing"
                ? "monthlyPayment"
                : details === getTranslation("Monthly payment", t) &&
                  field === "existing"
                ? "monthlyPaymentAmount"
                : field === "proposed"
                ? "proposedRow"
                : field === "details" &&
                  (newUsedDetails ||
                    equityDetails ||
                    totalDepositDetails ||
                    termTotalKilometerDetails)
                ? "expandedDetailsRow"
                : "grid-row"
            }
          >
            <span>
              {field === "details"
                ? getTranslation(dataItem[field], t)
                : ["newOrUsed", "financeTypeName"].includes(dataItem.field)
                ? getTranslation(dataItem[field], t)
                : dataItem[field]}
            </span>
            {
              // Add dropdown caret button
              (details === getTranslation("New/Used", t) &&
                field === "details") ||
              (details === getTranslation("Equity", t) &&
                field === "details") ||
              (details === getTranslation("Total Deposit", t) &&
                field === "details") ||
              (details === getTranslation("Term/Total Km", t) &&
                field === "details") ? (
                <button
                  className={"caretIconBtn"}
                  onClick={() => {
                    handleDropdowns(details);
                  }}
                >
                  <img
                    src={
                      (showNewUsedDropdown &&
                        details === getTranslation("New/Used", t)) ||
                      (showEquityDropdown &&
                        details === getTranslation("Equity", t)) ||
                      (showTotalDepositDropdown &&
                        details === getTranslation("Total Deposit", t)) ||
                      (showTermKilometerDropdown &&
                        details === getTranslation("Term/Total Km", t))
                        ? getIcon("caret-down.svg")
                        : getIcon("caret-up.svg")
                    }
                    alt="caretIcon"
                  />
                </button>
              ) : null
            }
          </div>
        )}
      </td>
    );
  };

  //Handles dropdowns in Finance record
  const handleDropdowns = (details) => {
    if (details === getTranslation("New/Used", t)) {
      setShowNewUsedDropdown(!showNewUsedDropdown);
    } else if (details === getTranslation("Equity", t)) {
      setShowEquityDropdown(!showEquityDropdown);
    } else if (details === getTranslation("Total Deposit", t)) {
      setShowTotalDepositDropdown(!showTotalDepositDropdown);
    } else if (details === getTranslation("Term/Total Km", t)) {
      setShowTermKilometerDropdown(!showTermKilometerDropdown);
    }
  };

  return (

    <>
    {
      data && data.financeGrid && (
      <div className="grid-container">
        <Grid
          className="financeRecordTableGrid"
          data={data.financeGrid}
          cellRender={rowCell}
        >
          <GridColumn
            headerCell={headerCell}
            field="details"
            title="Details"
            width="200px"
          />
          <GridColumn
            headerCell={headerCell}
            field="existing"
            title="Existing"
            width="180px"
          />
          <GridColumn
            headerCell={headerCell}
            field="proposed"
            title="Proposed"
            width="180px"
          />
        </Grid>

        <label>
          *
          {getTranslation(
            "All payments are shown as replacement values, campaign offers and settlement figures which may be subject to change",
            t
          )}
          .
        </label>
      </div>)
    }
    </>
  );
};

export default FinanceRecord;
