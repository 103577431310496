import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_SPECIALISTS } from "../actions/types";
import { setLoading, setSpecialists } from "../actions";
import { getSpecialistsApi } from "../api/handler";

function* handleLoadSpecialists() {
  yield put(setLoading(true));
  try {
    const response = yield call(getSpecialistsApi);
    if (response.status === 200) {
      yield put(setSpecialists(response.data.data));
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

export default function* specialistsSaga() {
  yield all([
    takeLatest(GET_SPECIALISTS, handleLoadSpecialists),
  ]);
}
