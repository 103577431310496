import Cookie from "../../api/Cookies";

export const campaignListColumns = [
  {
    width: "100px",
    title: "Actions",
    isShown: true,
    canBeHidden: false
  },
  {
    column: "campaignName",
    width: "180px",
    title: "Name",
    filter: "text",
    isShown: true,
    canBeHidden: false
  },
  {
    column: "validFrom",
    width: "200px",
    title: "Valid from",
    filter: "date",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "validTo",
    width: "200px",
    title: "Valid to",
    filter: "date",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "userFullName",
    width: "190px",
    title: "Last modified by",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "updatedDate",
    width: "200px",
    title: "Modified date",
    filter: "date",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "branchName",
    width: "130px",
    title: "Dealer",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "spFullName",
    width: "190px",
    title: "Specialist",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "total",
    width: "80px",
    title: "Total",
    filter: "numeric",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "opportunity",
    width: "120px",
    title: "Opportunity",
    filter: "numeric",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "futureOpportunity",
    width: "160px",
    title: "Future Opportunity",
    filter: "numeric",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "record",
    width: "90px",
    title: "Record",
    filter: "numeric",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "lead",
    width: "100px",
    title: "Lead",
    filter: "numeric",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "appointmentScheduled",
    width: "190px",
    title: "Appointment Scheduled",
    filter: "numeric",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "attendedAppointment",
    width: "200px",
    title: "Attended Appointment",
    filter: "numeric",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "doNotContact",
    width: "130px",
    title: "Do not contact",
    filter: "numeric",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "closedLost",
    width: "120px",
    title: "Closed/Lost",
    filter: "numeric",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "sold",
    width: "90px",
    title: "Sold",
    filter: "numeric",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "refinanced",
    width: "120px",
    title: "Refinanced",
    filter: "numeric",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "notAvailForContact",
    width: "200px",
    title: "Not available for contact",
    filter: "numeric",
    isShown: true,
    canBeHidden: true
  }
];

export const campaignLeadsListColumns = [
  {
    width: "120px",
    title: "",
    isShown: true,
    canBeHidden: false
  },
  {
    column: "customerName",
    width: "225px",
    title: "Customer Name",
    filter: "text",
    isShown: true,
    canBeHidden: false
  },
  {
    column: "status",
    width: "200px",
    title: "Status",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "type",
    width: "180px",
    title: "Lead Type",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "specialistName",
    width: "225px",
    title: "Specialist",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "lastActivity",
    width: "200px",
    title: "Last Activity",
    filter: "date",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "currentVehicle",
    width: "200px",
    title: "Current Vehicle",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "replacementVehicle",
    width: "200px",
    title: "Proposed Vehicle",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "monthsRemainingFormatted",
    width: "180px",
    title: "Months Remaining",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "paymentDifferenceFormatted",
    width: "180px",
    title: "Payment Difference",
    filter: "text",
    isShown: true,
    canBeHidden: true
  },
  {
    column: "financeType",
    width: "180px",
    title: "Finance Type",
    filter: "text",
    isShown: true,
    canBeHidden: true
  }
];

export const previewLeadsListColumn = [
  {
    width: "120px",
    title: "",
    isShown: true
  },
  {
    column: "licensePlate",
    width: "160px",
    title: "License Plate",
    filter: "text",
    isShown: true
  },
  {
    column: "paymentDifferenceFormatted",
    width: "160px",
    title: "Payment Difference",
    filter: "text",
    isShown: true
  },
  {
    column: "customerName",
    width: "250px",
    title: "Customer",
    filter: "text",
    isShown: true
  },
  {
    column: "currentVehicle",
    width: "200px",
    title: "Current Vehicle",
    filter: "text",
    isShown: true
  },
  {
    column: "replacementVehicle",
    width: "200px",
    title: "Replacement Vehicle",
    filter: "text",
    isShown: true
  },
  {
    column: "paymentFormatted",
    width: "160px",
    title: "Payment",
    filter: "text",
    isShown: true
  },
  {
    column: "newPaymentFormatted",
    width: "160px",
    title: "New Payment",
    filter: "text",
    isShown: true
  },
  {
    column: "equityFormatted",
    width: "160px",
    title: "Equity",
    filter: "text",
    isShown: true
  },
  {
    column: "dealer",
    width: "200px",
    title: "Dealer",
    filter: "text",
    isShown: true
  },
  {
    column: "costOfChangeFormatted",
    width: "160px",
    title: "Cost to change",
    filter: "text",
    isShown: true
  },
  {
    column: "source",
    width: "200px",
    title: "Source",
    filter: "text",
    sortable: false,
    isShown: true
  }
];
