/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { getIcon } from "../../utils/iconUtils";
import "../../styles/userManagement/userManagement.css";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import { useNavigate } from "react-router-dom";
import fetchClient from "../../api/fetch";
import { validateUserPermission } from "../../utils/helpers";
import accessControlIcon from "../../assets/icons/accessControl.svg";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import { DateFilterCell } from "../shared/DateFilterCell";
import defaultMs from "../../api/debounce";
import { format } from "date-fns";

const UserManagement = ({
  usersList,
  getUsersList,
  usersListPage,
  currentUser,
  setLoading
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const [page, setPage] = useState({
    skip: 0,
    take: 10
  });
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState([]);
  const [filter, setFilter] = useState(undefined);
  const [searchValue, setSearchValue] = useState("");
  const [params, setParams] = useState({
    sort: "",
    filter: "",
    search: ""
  });
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      if (localStorage.getItem("usersFilters")) {
        setLoading(true);
        const usersFilters = JSON.parse(localStorage.getItem("usersFilters"));
        setPage(usersFilters.page);
        setParams(usersFilters.params);
        setPageSizeValue(usersFilters.pageSizeValue);
        setSearchValue(usersFilters.searchValue);
        setFilter({
          ...usersFilters.filter,
          filters: usersFilters.filter?.filters?.map((filter) => {
            return ["lastLoginDate", "createdDate"].includes(filter.field)
              ? { ...filter, value: new Date(filter.value) }
              : filter;
          })
        });
        localStorage.removeItem("usersFilters");
      } else {
        getUsersList(queryBuilder());
      }
      firstRender.current = false;
    } else {
      const getData = setTimeout(() => getUsersList(queryBuilder()), defaultMs);
      return () => clearTimeout(getData);
    }
  }, [params]);

  const queryBuilder = (newpage = null) => {
    const filter = `filters=[${params.filter.concat(params.search)}]`;

    return `?${params.sort}${
      params.sort ? "&" : ""
    }${filter}&page=${JSON.stringify(newpage ? newpage : page)}`;
  };

  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...e.page,
      skip: e.page.skip,
      take
    });
    getUsersList(queryBuilder({ ...e.page, skip: e.page.skip, take }));
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value) {
      setParams({
        ...params,
        search: JSON.stringify({
          field: "userFullName",
          value: e.target.value,
          condition: "contains"
        })
      });
      setPage({ ...page, skip: 0 });
    } else {
      setParams({
        ...params,
        search: ""
      });
    }
  };

  const handleSort = (e) => {
    setSort(e.sort);
    if (e.sort.length > 0) {
      let obj = { ...e.sort[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      setParams({ ...params, sort: `sort=${obj}` });
    } else {
      setParams({ ...params, sort: "" });
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.dataState.filter);
    if (e.dataState.filter !== undefined) {
      setParams({
        ...params,
        filter: e.dataState.filter.filters.map((item) => {
          let obj = { ...item };
          if (obj.value instanceof Date) {
            obj.value = format(obj.value, 'yyyy-MM-dd');
          }
          obj["condition"] = obj["operator"];
          delete obj["operator"];
          obj = JSON.stringify(obj);
          return encodeURIComponent(obj);
        })
      });
      setPage({
        ...page,
        skip: 0
      });
    } else {
      setParams({ ...params, filter: "" });
    }
  };

  const changeUserState = (userId, currentStatus) => {
    fetchClient()
      .put(`/v1/users/${userId}?isDisable=${!currentStatus}`)
      .then((res) => getUsersList(queryBuilder()))
      .catch((err) => console.log(err));
  };

  const userSwitch = ({ dataItem }) => {
    return (
      <td>
        <Switch
          checked={!dataItem.isDisabled}
          onChange={() => changeUserState(dataItem.userId, dataItem.isDisabled)}
          dataItem={dataItem}
          disabled={
            !validateUserPermission(
              currentUser.permissions,
              "canEdit",
              "User Management"
            )
          }
        />
      </td>
    );
  };

  const storeFilters = () => {
    localStorage.setItem(
      "usersFilters",
      JSON.stringify({
        sort,
        page,
        pageSizeValue,
        filter,
        params,
        searchValue
      })
    );
  };

  return (
    <div className="user-management">
      <p className="h1 mb-4">{getTranslation("User Management", t)}</p>
      <div className="module-cont">
        <div className="header d-flex mb-2">
          <div className="search d-flex gap-4">
            <div className="d-flex flex-column">
              <label>{getTranslation("Search", t)}</label>
              <input
                name="search"
                onChange={handleSearch}
                className="primary-input"
                type="text"
                value={searchValue}
              />
            </div>
            <button className="primary-btn h-100">
              <img src={getIcon("searchInput.png")} alt="search icon" />
            </button>
          </div>
          {validateUserPermission(
            currentUser.permissions,
            "canCreate",
            "User Management"
          ) && (
            <button
              onClick={() => {
                storeFilters();
                Navigate(`/user-management/new`);
              }}
              className="offset-1 d-flex justify-content-center align-items-center gap-2 primary-btn"
            >
              <img src={getIcon("plus.svg")} alt="add icon" />
              <p className="m-0">{getTranslation("New user", t)}</p>
            </button>
          )}
          {/* {currentUser.userInfo.accessLevel === 6 && (
            <button
              onClick={() => {
                storeFilters();
                Navigate(`/access-control`);
              }}
              className="offset-1 d-flex justify-content-center align-items-center gap-2 primary-btn"
            >
              <img src={accessControlIcon} alt="add icon" />
              <p className="m-0">{getTranslation("Access control", t)}</p>
            </button>
          )} */}
        </div>
        <div className="grid-cont">
          <Grid
            data={usersList.map((item) => ({
              ...item,
              lastLoginDate: item.lastLoginDate
                ? new Date(item.lastLoginDate)
                : null,
              createdDate: new Date(item.createdDate)
            }))}
            skip={page.skip}
            take={page.take}
            filter={filter}
            sortable={true}
            sort={sort}
            onSortChange={handleSort}
            total={usersListPage.totalRecords}
            pageable={{
              buttonCount:
                usersListPage.totalPages > 10 ? 10 : usersListPage.totalPages,
              pageSizes: [5, 10, 15],
              pageSizeValue
            }}
            filterable={true}
            onDataStateChange={handleFilterChange}
            onPageChange={pageChange}
            onRowClick={(e) => {
              if (
                validateUserPermission(
                  currentUser.permissions,
                  "canEdit",
                  "User Management"
                )
              ) {
                storeFilters();
                Navigate(`/user-management/${e.dataItem.userId}`);
              }
            }}
          >
            <Column
              title={getTranslation("Actions", t)}
              width="100px"
              cell={userSwitch}
              filterable={false}
              sortable={false}
            />
            <Column
              field="name"
              title={getTranslation("First Name", t)}
              width="180px"
            />
            <Column
              field="surname"
              title={getTranslation("Last Name", t)}
              width="180px"
            />
            <Column
              field="roleDescription"
              title={getTranslation("Role", t)}
              width="250px"
            />
            <Column
              field="region"
              title={getTranslation("Region", t)}
              width="200px"
            />
            <Column
              field="market"
              title={getTranslation("Market", t)}
              width="200px"
            />
            <Column
              field="brand"
              title={getTranslation("Brand", t)}
              width="200px"
            />
            <Column
              field="branch"
              title={getTranslation("Branch", t)}
              width="200px"
            />
            <Column
              field="lastLoginDate"
              title={getTranslation("Last Login", t)}
              filter="date"
              width="200px"
              filterCell={DateFilterCell}
              format="{0:dd/MM/yyyy}"
            />
            <Column
              field="createdDate"
              title={getTranslation("Creation Date", t)}
              filter="date"
              width="200px"
              filterCell={DateFilterCell}
              format="{0:dd/MM/yyyy}"
            />
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default UserManagement;
