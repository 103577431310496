import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartTooltip
} from "@progress/kendo-react-charts";
import React from "react";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../common/translation";
import { getStatusChart } from "../../utils/statusUtil";

const DashboardPerformance = ({ data }) => {
  const { t } = useTranslation();
  const tooltipRender = (props) => {
    if (props.point) {
      return props.point.dataItem.statusName;
    }
  };

  return (
    <>
      <div className="dashboard-performance-container">
        <div className="performance-status-holder">
          {data?.map((item) => {
            return (
              <div
                className={`${getStatusChart(
                  item.statusName
                )} chart-status d-flex justify-content-between w-100`}
              >
                <span>{getTranslation(item.statusName, t)}</span>
                <span>
                  {item.statusName !== "Opportunities" && (
                    <span>{`${
                      item.percent !== 0 ? item.percent + " %" : ""
                    }`}</span>
                  )}
                </span>
              </div>
            );
          })}
        </div>

        <div className="performance-chart-holder">
          <Chart>
            <ChartSeries>
              <ChartSeriesItem
                type="funnel"
                data={data}
                categoryField="statusName"
                field="value"
                colorField="color"
                dynamicSlope={true}
                dynamicHeight={false}
              >
                <ChartSeriesLabels background="none" format="N0" />
              </ChartSeriesItem>
            </ChartSeries>
            <ChartTooltip render={tooltipRender} />
            <ChartLegend visible={false} />
          </Chart>
        </div>
      </div>
    </>
  );
};

export default DashboardPerformance;
