import React from "react";
import { useNavigate } from "react-router-dom";
import "../../components/landingPage/landingPage.css";
import lines from "../../imagenes/line/Lines.svg";
import logo from "../../imagenes/logo/inchcapeLogo.png";
import latviaFlag from "../../imagenes/icon/latvia-flag.svg";
import estoniaFlag from "../../imagenes/icon/estonia-flag.svg";
import lithuaniaFlag from "../../imagenes/icon/lithuania-flag.svg";
import australiaFlag from "../../imagenes/icon/australia-flag.svg";
import Cookie from "../../../../api/Cookies";
import fetchClient from "../../../../api/fetch";

const LandingPage = ({ setAuth }) => {
  const Navigation = useNavigate();

  const onMarketClick = (e, marketName) => {
    e.preventDefault();
    const market = marketName.toLowerCase();
    // Set Cookie
    Cookie.set("market", market);
    setAuth(true);

    // Create UserSettings
    fetchClient()
      .post("/v1/usersettings/new")
      .then((response) => {
        // Redirect to Dashboard
        Navigation("/dashboard");
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="main d-flex flex-column flex-sm-row">
      <div className="left order-1 order-sm-1 d-flex flex-column align-items-center">
        <img
          className="lines align-self-end align-self-sm-center"
          src={lines}
          alt="lines icon"
        />
        <div className="contents">
          <div className="d-flex flex-column justify-content-evenly">
            <img src={logo} alt="inchcape logo" />
            <div className="d-flex gap-5">
              <div>
                <p>RETENTION</p>
                <p>ENGINE</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right order-2 order-sm-2">
        <div className="select-country-wrap d-flex flex-column  flex-wrap align-content-center justify-content-center w-100">
          <span className="select-country-title">Choose Your Country</span>
          <div>
            <span className="region">Europe</span>
            <div className="markets">
              <button onClick={(e) => onMarketClick(e, "estonia")}>
                <img src={estoniaFlag} alt="estonia flag" />
                <span>Estonia</span>
              </button>
              <button onClick={(e) => onMarketClick(e, "latvia")}>
                <img src={latviaFlag} alt="latvia flag" />
                <span>Latvia</span>
              </button>
              <button onClick={(e) => onMarketClick(e, "lithuania")}>
                <img src={lithuaniaFlag} alt="lithuania flag" />
                <span>Lithuania</span>
              </button>
            </div>
            <span className="region">Australasia</span>
            <div className="markets">
              <button disabled={true}>
                <img src={australiaFlag} alt="estonia flag" />
                <span>Australia</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
