/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../styles/dashboard/dashboard.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import DashboardCampaign from "./DashboardCampaign";
import DashboardTasks from "./DashboardTasks";
import DashboardPerformance from "./DashboardPerformance";
import fetchClient from "../../api/fetch";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import { getFunnelColor } from "../../utils/statusUtil";
import { useNavigate } from "react-router-dom";

const Dashboard = ({
  setLoading,
  loadingCount,
  setLoadingCount,
  setDashboardLoadType
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();

  //Dropdown Filters
  const dropdownFilter = [
    { text: getTranslation("This week", t), value: "This week" },
    { text: getTranslation("This month", t), value: "This month" },
    { text: getTranslation("This year", t), value: "This year" }
  ];
  //useStates
  const [campaignDropdownValue, setCampaignDropdownValue] = useState(
    dropdownFilter[0]
  );
  const [taskDropdownValue, setTaskDropdownValue] = useState(dropdownFilter[0]);
  const [performanceDropdownValue, setPerformanceDropdownValue] = useState(
    dropdownFilter[0]
  );
  const [sort, setSort] = useState([]);
  const [pageSizeValue, setPageSizeValue] = useState();
  const [page, setPage] = useState({
    skip: 0,
    take: 20
  });
  const [statusFilter, setStatusFilter] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [dashboardData, setDashboardData] = useState({
    campaigns: [],
    task: [],
    performance: []
  });
  const [params, setParams] = useState({
    campaigns: "week",
    task: "week",
    performance: "week",
    sort: ""
  });
  const firstRender = useRef(true);

  //useEffect
  useEffect(() => {
    setCampaignDropdownValue({
      ...campaignDropdownValue,
      text: getTranslation(campaignDropdownValue.value, t)
    });
    setTaskDropdownValue({
      ...taskDropdownValue,
      text: getTranslation(taskDropdownValue.value, t)
    });
    setPerformanceDropdownValue({
      ...performanceDropdownValue,
      text: getTranslation(performanceDropdownValue.value, t)
    });
  }, [t]);

  //GET DASHBOARD CAMPAIGNS
  useEffect(() => {
    setDashboardLoadType("campaigns");
    setLoading(true);
    fetchClient()
      .get(`/v1/dashboard/campaigns?date=${params.campaigns}`)
      .then((res) => {
        setDashboardData((prevState) => ({
          ...prevState,
          campaigns: res.data.data
        }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        if (loadingCount) {
          setLoading(false);
        }
      });
  }, [params.campaigns]);

  //GET DASHBOARD PERFORMANCE
  useEffect(() => {
    setDashboardLoadType("performance");
    setLoading(true);
    fetchClient()
      .get(`/v1/dashboard/performance?date=${params.performance}`)
      .then((res) => {
        const data = res.data.data.map((e) => ({
          statusName: e.statusName,
          status: e.status,
          value: e.rawValue,
          percent: e.formatted,
          color: getFunnelColor(e.statusName)
        }));
        setDashboardData((prevState) => ({
          ...prevState,
          performance: data
        }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setLoadingCount(1);
      });
  }, [params.performance]);

  //GET DASHBOARD TASK
  useEffect(() => {
    if (firstRender.current) {
      if (localStorage.getItem("dashboardTaskFilters")) {
        setLoading(true);
        const dashboardTaskFilters = JSON.parse(
          localStorage.getItem("dashboardTaskFilters")
        );
        setPage(dashboardTaskFilters.page);
        setSort(dashboardTaskFilters.sort);
        setPageSizeValue(dashboardTaskFilters.pageSizeValue);
        setSelectedStatus(dashboardTaskFilters.selectedStatus);
        setStatusFilter(dashboardTaskFilters.statusFilter);
        setTaskDropdownValue(dashboardTaskFilters.taskDropdownValue);
        setCampaignDropdownValue(dashboardTaskFilters.campaignDropdownValue);
        setPerformanceDropdownValue(
          dashboardTaskFilters.performanceDropdownValue
        );
        setParams(dashboardTaskFilters.params);
        localStorage.removeItem("dashboardTaskFilters");
      } else {
        getTaskData();
      }
      firstRender.current = false;
    } else {
      const getData = setTimeout(() => getTaskData());
      return () => clearTimeout(getData);
    }
  }, [params.task, params.sort, page, statusFilter, taskDropdownValue.value]);

  // GET DASHBOARD TASK DATA
  const getTaskData = () => {
    setDashboardLoadType("tasks");
    setLoading(true);
    fetchClient()
      .get(`/v1/dashboard/tasks?` + queryBuilder())
      .then((res) => {
        setDashboardData((prevState) => ({
          ...prevState,
          task: res.data
        }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setLoadingCount(1);
      });
  };

  //query function for params
  const queryBuilder = (newpage = null) => {
    return `${params.sort}${
      params.sort ? "&" : ""
    }&statusFilters=[${statusFilter.toString()}]&page=${JSON.stringify(
      newpage ? newpage : page
    )}&date=${params.task}`;
  };

  const getValue = (val) => {
    if (val === "This week") {
      return "week";
    } else if (val === "This month") {
      return "month";
    } else if (val === "This year") {
      return "year";
    }
  };

  //Handle Campaigns Dropdown Filter
  const handleCampaignFilterChange = (e) => {
    setCampaignDropdownValue(e.target.value);
    setParams({ ...params, campaigns: getValue(e.target.value.value) });
  };

  //Handle Task Dropdown Filter
  const handleTaskFilterChange = (e) => {
    setTaskDropdownValue(e.target.value);
    setParams({ ...params, task: getValue(e.target.value.value) });
    setPage({ ...page, skip: 0 });
  };

  //Handle Performance Dropdown Filter
  const handlePerformanceFilterChange = (e) => {
    setPerformanceDropdownValue(e.target.value);
    setParams({ ...params, performance: getValue(e.target.value.value) });
  };

  //Dropdown custom style
  const itemRender = (li) => {
    const itemChildren = (
      <span className="dropdown-list-items">{li.props.children}</span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  //Dashboard task pageChange function
  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...e.page,
      skip: e.page.skip,
      take
    });
  };

  //Dashboard task sorting change function
  const handleSortChange = (e) => {
    setSort(e.sort);
    if (e.sort.length > 0) {
      let obj = { ...e.sort[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      setParams({ ...params, sort: `sort=${obj}` });
    } else {
      setParams({ ...params, sort: "" });
    }
  };

  //Dashboard task handle status change
  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value.map((item) => item.value));
    setSelectedStatus(e.value);
    setPage({ ...page, skip: 0 });
  };

  const handleTaskRowClick = (dataItem) => {
    // save dashboard task user activity
    localStorage.setItem(
      "dashboardTaskFilters",
      JSON.stringify({
        page: page,
        sort: sort,
        pageSizeValue: pageSizeValue,
        selectedStatus: selectedStatus,
        statusFilter: statusFilter,
        campaignDropdownValue: campaignDropdownValue,
        performanceDropdownValue: performanceDropdownValue,
        taskDropdownValue: taskDropdownValue,
        params: params
      })
    );
    Navigate(`/customer-record/${dataItem.leadId}`, {
      state: { prevPage: "dashboard-task" }
    });
  };

  return (
    <div className="dashboard-container">
      <p className="h1 mb-4">{getTranslation("Dashboard", t)}</p>
      <div className="dashboard-contents row p-0 m-0">
        <div className="col p-0 d-flex flex-column campaign-performance">
          <div className="campaign-wrap">
            <div className="dashboard-header d-flex justify-content-between align-items-center">
              <span className="dashboard-header-title">
                {getTranslation("Campaigns", t)}
              </span>
              <div className="dashboard-dropdown d-flex align-items-center">
                <p>{getTranslation("Show", t)}:</p>
                <DropDownList
                  itemRender={itemRender}
                  data={dropdownFilter}
                  textField="text"
                  value={campaignDropdownValue}
                  onChange={handleCampaignFilterChange}
                />
              </div>
            </div>
            <div className="dashboard-campaign">
              <DashboardCampaign data={dashboardData.campaigns} />
            </div>
          </div>
          <div className="performance-wrap">
            <div className="dashboard-header d-flex justify-content-between align-items-center">
              <span className="dashboard-header-title">
                {getTranslation("Performance", t)}
              </span>
              <div className="dashboard-dropdown d-flex align-items-center">
                <p>{getTranslation("Show", t)}:</p>
                <DropDownList
                  itemRender={itemRender}
                  data={dropdownFilter}
                  textField="text"
                  value={performanceDropdownValue}
                  onChange={handlePerformanceFilterChange}
                />
              </div>
            </div>
            <div className="dashboard-performance">
              <DashboardPerformance data={dashboardData.performance} />
            </div>
          </div>
        </div>
        <div className="task-wrap col p-0 ">
          <div className="dashboard-header d-flex justify-content-between align-items-center">
            <span className="dashboard-header-title">
              {getTranslation("Tasks", t)}
            </span>
            <div className="dashboard-dropdown d-flex align-items-center">
              <p>{getTranslation("Show", t)}:</p>
              <DropDownList
                itemRender={itemRender}
                data={dropdownFilter}
                textField="text"
                value={taskDropdownValue}
                onChange={handleTaskFilterChange}
              />
            </div>
          </div>
          <div className="dashboard-tasks">
            <DashboardTasks
              data={dashboardData.task}
              sort={sort}
              handleSortChange={handleSortChange}
              page={page}
              pageSizeValue={pageSizeValue}
              pageChange={pageChange}
              setStatusFilter={setStatusFilter}
              selectedStatus={selectedStatus}
              handleStatusChange={handleStatusChange}
              handleTaskRowClick={handleTaskRowClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
