import React from "react";
import "../../styles/settings/settings.css";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import { cloneElement } from "react";

const FieldNamesDropdown = ({
  data,
  name,
  value,
  dataItemKey,
  handleChange,
  textfield
}) => {
  const { t } = useTranslation();

  const itemRender = (li, itemProps) => {
    const valueIds = value.map((item) => item.value);

    const checkboxValue = () => {
      // if length of selected is equal to all options minus one,
      // then all area area selected
      return value?.length === data?.length - 1
        ? true
        : valueIds.includes(itemProps.dataItem.value);
    };

    const itemChildren = (
      <div className="reports-dropdown d-flex gap-2 option m-1">
        <Checkbox value={checkboxValue()} />
        <p className="mb-0">{li.props.children}</p>
      </div>
    );
    return cloneElement(li, li.props, itemChildren);
  };
  return (
    <>
      <MultiSelect
        className="k-input-flat"
        autoClose={false}
        placeholder={getTranslation("Select", t)}
        itemRender={itemRender}
        name={name}
        textField={textfield}
        data={data}
        dataItemKey={dataItemKey}
        value={value}
        onChange={handleChange}
        tags={
          value?.length >= 1
            ? value.length === data.length - 1
              ? [{ text: getTranslation("All", t), data: [...value] }]
              : [
                  {
                    text: value.map((item) => item.textField).join(", "),
                    data: [...value]
                  }
                ]
            : undefined
        }
        // tags={
        //   data[0]?.id === 0 && value.length === data.length - 1
        //     ? [
        //         {
        //           text: getTranslation(`All`, t),
        //           data: [...value]
        //         }
        //       ]
        //     : undefined
        // }
      />
    </>
  );
};

export default FieldNamesDropdown;
