import {
  SET_VEHICLE_SELECTOR_LIST,
  SET_VEHICLE_SELECTOR_LIST_PAGE
} from "../actions/types";

const initialState = {
  existingVehicles: [],
  replacementVehicles: [],
  listPage: {
    existing: {
      totalRecords: "",
      totalPages: "",
      currentTotalRecords: ""
    },
    replacement: {
      totalRecords: "",
      totalPages: "",
      currentTotalRecords: ""
    }
  }
};
const vehicleSelectorReducer = (state = initialState, action) => {
  if (action.type === SET_VEHICLE_SELECTOR_LIST) {
    switch (action.mode) {
      case "existing":
        return {
          ...state,
          existingVehicles: action.vehicleList
        };
      case "replacement":
        return {
          ...state,
          replacementVehicles: action.vehicleList
        };
      default:
        return state;
    }
  } else if (action.type === SET_VEHICLE_SELECTOR_LIST_PAGE) {
    return {
      ...state,
      listPage: {
        ...state.listPage,
        [action.mode]: action.vehicleSelectorListPage
      }
    };
  } else {
    return state;
  }
};

export default vehicleSelectorReducer;
