import React, { cloneElement } from "react";
import "../../styles/dashboard/dashboard.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import { getStatusClass } from "../../utils/statusUtil";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { useNavigate } from "react-router-dom";
import statusIncData from "../shared/statusIncData";
import "hammerjs";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../common/translation";
import { checkMarketAu } from "../../utils/helpers";

const DashboardTasks = ({
  data,
  sort,
  handleSortChange,
  page,
  pageChange,
  pageSizeValue,
  selectedStatus,
  handleStatusChange,
  handleTaskRowClick
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();

  const itemRender = (li, itemProps) => {
    let statusBox;
    switch (itemProps.dataItem.status) {
      case "Appt.Attended":
      case "Appt.Scheduled":
        statusBox = "first-status";
        break;
      case "Lead":
        statusBox = "third-status";
        break;
      case "Opportunity":
      case "Future Opportunity":
        statusBox = "fourth-status";
        break;
      default:
        statusBox = "";
        break;
    }
    const ids = selectedStatus.map((item) => item.value);
    const itemChildren = (
      <div className="status-items d-flex option m-1 w-100 justify-items-center">
        <Checkbox value={ids.includes(itemProps.dataItem.value)} />
        <div className={`${statusBox} status-common w-100`}>
          <span>{getTranslation(itemProps.dataItem.status, t)}</span>
        </div>
      </div>
    );
    return cloneElement(li, li.props, itemChildren);
  };

  const headerCell = () => {
    // console.log(statusData.splice(7, 8, 9, 10, 11, 1));
    return (
      <div className="table-header-cell">
        <MultiSelect
          className="status-wrapper"
          data={statusIncData}
          value={selectedStatus}
          textField="status"
          dataItemKey="value"
          name="status"
          autoClose={false}
          itemRender={itemRender}
          onChange={handleStatusChange}
          tags={[
            { text: getTranslation(`Status`, t), data: [...selectedStatus] }
          ]}
        />
      </div>
    );
  };

  const statusCell = (props) => {
    const { dataItem } = props;
    const { statusName } = dataItem;
    return (
      <td className="status-row-cell">
        <div
          className={getStatusClass(statusName)}
          onClick={() => handleTaskRowClick(props.dataItem)}
        >
          <span className="status-label">{getTranslation(statusName, t)}</span>
        </div>
      </td>
    );
  };

  return (
    <>
      <Grid
        data={data.data?.map((item) => ({
          ...item,
          dueDate: item.dueDate ? new Date(item.dueDate) : null
        }))}
        skip={page.skip}
        take={page.take}
        total={data.totalRecords}
        pageable={{
          info: false,
          buttonCount: data.totalPages > 10 ? 10 : data.totalPages,
          pageSizes: [5, 10, 20],
          pageSizeValue
        }}
        onPageChange={pageChange}
        sortable={true}
        sort={sort}
        onSortChange={handleSortChange}
        onRowClick={({ dataItem }) => handleTaskRowClick(dataItem)}
      >
        {!checkMarketAu() && (
          <GridColumn
            field="customerName"
            title={getTranslation("Customer", t)}
            sortable={false}
          />
        )}
        <GridColumn
          cell={statusCell}
          field="statusName"
          title={getTranslation("Status", t)}
          headerCell={headerCell}
        />
        <GridColumn
          field="dueDate"
          title={getTranslation("Due Date", t)}
          format="{0:dd/MM/yyyy}"
        />
      </Grid>
    </>
  );
};

export default DashboardTasks;
