import Cookie from "../api/Cookies";

export const getSubdomain = () => {
  var host = window.location.host;
  var subdomain = host.split(".")[0];

  return subdomain;
};

export const validateUserPermission = (permissions, action, module) => {
  let hasPermission = false;

  for (let i in permissions) {
    if (permissions[i].moduleName === module && permissions[i][action]) {
      hasPermission = permissions[i][action];
    }
  }

  return hasPermission;
};

export const formatCurrency = (value, symbol) => {
  let val = value?.toString();
  const isNegative = val?.includes("-");

  if (val && isNegative) {
    const splitValue = val.split("-");
    const numberValue = Number(splitValue[1]);
    const formattedValue = numberValue.toLocaleString(undefined, {
      minimumFractionDigits: numberValue % 1 === 0 ? 0 : 2
    });
    val = `-(${symbol}${formattedValue})`;
    return val;
  } else {
    const numberValue = Number(val);
    const formattedValue = numberValue.toLocaleString(undefined, {
      minimumFractionDigits: numberValue % 1 === 0 ? 0 : 2
    });
    return val ? `${symbol}${formattedValue}` : null;
  }
};

export const checkMarketAu = () => Cookie.get("market") === "australia";
export const checkMarketBrunei = () => Cookie.get("market") === "brunei";
export const checkMarketChile = () => Cookie.get("market") === "chile";