/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../styles/reports/usageReport.css";
import { getIcon } from "../../utils/iconUtils";
import { useNavigate } from "react-router-dom";
import chartIcon from "../../assets/icons/chart-icon.png";
import chartIdleIcon from "../../assets/icons/chart-idle.png";
import gridIcon from "../../assets/icons/grid-icon.png";
import gridIdleIcon from "../../assets/icons/grid-idle.png";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem
} from "@progress/kendo-react-charts";
import "hammerjs";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import ReportsDropdown from "./ReportsDropdown";
import { DateInput, DateRangePicker } from "@progress/kendo-react-dateinputs";
import fetchClient from "../../api/fetch";
import { format } from "date-fns";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import defaultMs from "../../api/debounce";
import { usageChartColors } from "./usageChartColors";

const UsageReport = ({
  currentUser,
  reportsUsageGridList,
  getReportsUsageGridList,
  reportsUsageGridListPage,
  exportUsageReportToExcel,
  setLoading,
  setLoadingCount
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();

  // Filter dropdown data
  const filterChart = [
    {
      text: getTranslation("Total active days", t),
      value: "Total active days"
    },
    {
      text: getTranslation("Days since last login", t),
      value: "Days since last login"
    }
  ];

  // Dropdown initial payload
  const initialPayload = {
    roleId: [],
    regionId: [],
    marketId: [],
    brandId: [],
    branchId: []
  };

  const initialDateValue = {
    start: null,
    end: null
  };

  // useStates
  const [dropdownValue, setDropdownValue] = useState(filterChart[0]);
  const [usageView, setUsageView] = useState("chart");
  const [payload, setPayload] = useState(initialPayload);
  const [dropdownData, setDropdownData] = useState(initialPayload);
  const [usageDateValue, setUsageDateValue] = useState(initialDateValue);
  const [usageChartData, setUsageChartData] = useState([]);
  const [usageGridSetting, setUsageGridSetting] = useState({
    sort: [],
    filter: undefined,
    pageSizeValue: 10,
    page: {
      skip: 0,
      take: 10
    }
  });
  const [params, setParams] = useState({
    sort: "",
    filter: ""
  });
  const isOpened = useRef(false);
  const firstRender = useRef(true);

  useEffect(() => {
    setDropdownValue({
      ...dropdownValue,
      text: getTranslation(dropdownValue.value, t)
    });
  }, [t]);

  const getUsageChart = (queryparams) => {
    fetchClient()
      .get("/v1/reports/userLogins/graph?" + queryparams)
      .then((res) => {
        const data = res.data.data;
        for (let i = 0, j = 0; i < data.length; i++) {
          data[i].color = usageChartColors[j];
          j++;
          if (j && !(j % 11)) j = 0;
        }
        setUsageChartData([...data]);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setLoadingCount(1);
      });
  };

  useEffect(() => {
    let getData;
    if (usageView === "chart") {
      if (!isOpened.current) {
        let queryparams = "";
        for (const item in payload) {
          const tempData = dropdownData[item];
          const index = item.indexOf("Id");
          if (payload[item].length === 0 && tempData.length === 0) {
            continue;
          } else if (payload[item].length === 0 && tempData.length !== 0) {
            queryparams += `${queryparams ? "&" : ""}${item.slice(
              0,
              index
            )}=NA`;
          } else {
            const ids = payload[item].map((i) => i[item]).toString();
            queryparams += `${queryparams ? "&" : ""}${item.slice(
              0,
              index
            )}=${ids}`;
          }
        }
        if (usageDateValue.start) {
          queryparams += `${queryparams ? "&" : ""}startDate=${format(
            usageDateValue.start,
            "yyyy-MM-dd HH:mm:ss.SSS"
          )}`;
        }
        if (usageDateValue.end) {
          queryparams += `${queryparams ? "&" : ""}endDate=${format(
            usageDateValue.end,
            "yyyy-MM-dd HH:mm:ss.SSS"
          )}`;
        }
        setLoading(true);
        getData = setTimeout(() => getUsageChart(queryparams), defaultMs);
      }
    } else {
      if (firstRender.current) {
        getReportsUsageGridList(queryBuilder());
        firstRender.current = false;
      } else {
        if (!isOpened.current) {
          setLoading(true);
          getData = setTimeout(
            () => getReportsUsageGridList(queryBuilder()),
            defaultMs
          );
        }
      }
    }
    return () => clearTimeout(getData);
  }, [payload, usageView, usageDateValue, usageGridSetting.page, params]);

  useEffect(() => {
    // if a region is removed, values in market should only include those in remaining regions
    const currentRegions = payload.regionId.map((region) => region.regionId);
    setPayload({
      ...payload,
      marketId: payload.marketId.filter((market) =>
        currentRegions.includes(market.regionId)
      )
    });
  }, [payload.regionId]);

  useEffect(() => {
    // if a market is removed, values in brand should only include those in remaining markets
    const currentMarkets = payload.marketId.map((market) => market.marketId);
    setPayload({
      ...payload,
      brandId: payload.brandId.filter((brand) =>
        currentMarkets.includes(brand.marketId)
      )
    });
  }, [payload.marketId]);

  useEffect(() => {
    //If a brand is removed, values in branch should only include those in remaining brands
    const currentBrands = payload.brandId.map((brand) => brand.brandId);
    setPayload({
      ...payload,
      branchId: payload.branchId.filter((branch) =>
        currentBrands.includes(branch.brandId)
      )
    });
  }, [payload.brandId]);

  const queryBuilder = () => {
    let dropdownParams = "";
    for (const item in payload) {
      const tempData = dropdownData[item];
      const index = item.indexOf("Id");

      if (payload[item].length === 0 && tempData.length === 0) {
        continue;
      } else if (payload[item].length === 0 && tempData.length !== 0) {
        dropdownParams += `&${item.slice(0, index)}=NA`;
      } else {
        const ids = payload[item].map((i) => i[item]).toString();
        dropdownParams += `&${item.slice(0, index)}=${ids}`;
      }
    }
    if (usageDateValue.start) {
      dropdownParams += `&startDate=${format(
        usageDateValue.start,
        "yyyy-MM-dd HH:mm:ss.SSS"
      )}`;
    }
    if (usageDateValue.end) {
      dropdownParams += `&endDate=${format(
        usageDateValue.end,
        "yyyy-MM-dd HH:mm:ss.SSS"
      )}`;
    }
    return `?filters=[${params.filter}]${params.sort}&page=${JSON.stringify(
      usageGridSetting.page
    )}${dropdownParams}`;
  };

  const filterBuilder = () => {
    let dropdownParams = "";
    for (const item in payload) {
      const tempData = dropdownData[item];
      const index = item.indexOf("Id");
      if (payload[item].length === 0 && tempData.length === 0) {
        continue;
      } else if (payload[item].length === 0 && tempData.length !== 0) {
        dropdownParams += `&${item.slice(0, index)}=NA`;
      } else {
        if (payload[item].length === tempData.length - 1) {
          dropdownParams += `&${item.slice(0, index)}=ALL`;
        } else {
          const ids = payload[item].map((i) => i[item]).toString();
          dropdownParams += `&${item.slice(0, index)}=${ids}`;
        }
      }
    }
    if (usageDateValue.start) {
      dropdownParams += `&startDate=${format(
        usageDateValue.start,
        "yyyy-MM-dd HH:mm:ss.SSS"
      )}`;
    }
    if (usageDateValue.end) {
      dropdownParams += `&endDate=${format(
        usageDateValue.end,
        "yyyy-MM-dd HH:mm:ss.SSS"
      )}`;
    }
    return `&filters=[${params.filter}]&${params.sort}${dropdownParams}`;
  };

  //Grid pageChange
  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.page.take;
    if (targetEvent.value) {
      setUsageGridSetting({
        ...usageGridSetting,
        pageSizeValue: targetEvent.value
      });
    }
    setUsageGridSetting((prevState) => ({
      ...prevState,
      page: {
        ...prevState.page,
        skip: e.page.skip,
        take
      }
    }));
  };

  const handleBack = () => {
    Navigate("/reports");
  };

  const dropdownHandleChange = (e) => {
    setDropdownValue(e.target.value);
  };

  const handleOpenUsageDropdown = (name, route) => {
    isOpened.current = true;
    const userId = currentUser.userInfo.userId;
    let tempData = "";
    let params = "";
    for (const item in payload) {
      tempData = dropdownData[item];
      if (
        (payload[item].length === 0 && tempData.length === 0) ||
        item === "roleId"
      ) {
        continue;
      } else if (payload[item].length === 0 && tempData.length !== 0) {
        params += `${params ? "&" : ""}${item}=NA`;
      } else {
        const ids = payload[item].map((i) => i[item]).toString();
        params += `${params ? "&" : ""}${item}=${ids}`;
      }
    }
    const index = name.indexOf("Id");
    fetchClient()
      .get(
        name === "roleId"
          ? `/v1/users/${userId}/roles?isReport=true`
          : `/v1/users/${userId}/${route}?` + params
      )
      .then((res) => {
        tempData = dropdownData[name];
        setDropdownData({
          ...dropdownData,
          [name]:
            res.data.length > 0
              ? [
                  {
                    id: 0,
                    [name.slice(0, index) + "Description"]: getTranslation(
                      "All",
                      t
                    )
                  }
                ].concat(res.data)
              : []
        });
        if (tempData.length === payload[name].length) {
          setPayload({ ...payload, [name]: res.data });
        }
      })
      .catch((err) => console.log(err))
      .finally();
  };

  const handleUsageDropdownChange = (e) => {
    isOpened.current = false;
    switch (e.target.name) {
      case "roleId":
      case "regionId":
      case "marketId":
      case "brandId":
      case "branchId":
        // check if selectAll option is included in the multiselect values
        const selectAll = e.target.value.some((item) => item.id === 0);
        // get name of array without "Id"
        const index = e.target.name.indexOf("Id");
        if (selectAll) {
          // if payload array is equal to the available options minus one,
          // meaning all of the options are selected
          if (
            payload[e.target.name].length ===
            dropdownData[e.target.name].length - 1
          ) {
            // deselect all if select all option is clicked again
            setPayload({ ...payload, [e.target.name]: [] });
          } else {
            // select all options but remove the "select all" object
            setPayload({
              ...payload,
              [e.target.name]: dropdownData[e.target.name].filter(
                (item) =>
                  item[`${e.target.name.slice(0, index)}Description`] !== getTranslation("All", t)
              )
            });
          }
        } else {
          // if not all options are selected, only store the checked ones
          setPayload({ ...payload, [e.target.name]: e.target.value });
        }
        break;
      default:
        setPayload({ ...payload, [e.target.name]: e.target.value });
        break;
    }
  };

  const handleDate = (e) => {
    setUsageDateValue(e.target.value);
  };

  const handleSort = (e) => {
    setUsageGridSetting({
      ...usageGridSetting,
      sort: e.sort
    });
    if (e.sort.length > 0) {
      let obj = { ...e.sort[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      setParams({ ...params, sort: `&sort=${obj}` });
    } else {
      setParams({ ...params, sort: "" });
    }
  };

  const handleFilterChange = (e) => {
    setUsageGridSetting({ ...usageGridSetting, filter: e.dataState.filter });
    if (e.dataState.filter !== undefined) {
      setParams({
        ...params,
        filter: e.dataState.filter.filters.map((item) => {
          let obj = { ...item };
          if (obj.value instanceof Date) {
            obj.value = format(obj.value, "yyyy-MM-dd");
          }
          obj["condition"] = obj["operator"];
          delete obj["operator"];
          obj = JSON.stringify(obj);
          return encodeURIComponent(obj);
        })
      });
      setUsageGridSetting((prevState) => ({
        ...prevState,
        page: { ...prevState.page, skip: 0 }
      }));
    } else {
      setParams({ ...params, filter: "" });
    }
  };

  const customStartDateInput = (props) => {
    return (
      <label>
        <DateInput {...props} label={getTranslation("Start", t)} />
      </label>
    );
  };

  const customEndDateInput = (props) => {
    return (
      <label>
        <DateInput {...props} label={getTranslation("End", t)} />
      </label>
    );
  };

  const handleExportUsageReport = async () => {
    try {
      exportUsageReportToExcel(filterBuilder());
    } catch (error) {
      console.error("Export error:", error);
    }
  };

  return (
    <div className="usage-report">
      <div className="d-flex gap-5 align-items-center mb-4 header">
        <img
          onClick={handleBack}
          src={getIcon("arrow-back.svg")}
          alt="back icon"
        />
        <p className="h1">{getTranslation("Usage report", t)}</p>
      </div>
      <div className="usage-report-cont w-100">
        <div className="usage-report-header">
          <button
            onClick={() => {
              firstRender.current = true;
              setUsageView("chart");
            }}
            className={`${usageView === "chart" ? "primary-btn" : "btn-idle"}`}
          >
            {usageView === "chart" ? (
              <img src={chartIcon} alt="chart icon" />
            ) : (
              <img src={chartIdleIcon} alt="chart idle icon" />
            )}
          </button>
          <button
            onClick={() => {
              firstRender.current = true;
              setUsageView("grid");
            }}
            className={`${usageView === "grid" ? "primary-btn" : "btn-idle"}`}
          >
            {usageView === "grid" ? (
              <img src={gridIcon} alt="chart icon" />
            ) : (
              <img src={gridIdleIcon} alt="chart idle icon" />
            )}
          </button>
          <button className="primary-btn" onClick={handleExportUsageReport}>
            <span>{getTranslation("Export to excel", t)}</span>
          </button>
        </div>
        <div className="usage-grid-header">
          <div>
            <p>{getTranslation("Region", t)}</p>
            <ReportsDropdown
              name="regionId"
              dataItemKey="regionId"
              data={dropdownData.regionId}
              textfield="regionDescription"
              value={payload.regionId}
              handleOpen={() => handleOpenUsageDropdown("regionId", "regions")}
              handleChange={handleUsageDropdownChange}
            />
          </div>
          <div>
            <p>{getTranslation("Market", t)}</p>
            <ReportsDropdown
              name="marketId"
              dataItemKey="marketId"
              data={dropdownData.marketId}
              textfield="marketDescription"
              value={payload.marketId}
              handleOpen={() => handleOpenUsageDropdown("marketId", "markets")}
              handleChange={handleUsageDropdownChange}
            />
          </div>
          <div>
            <p>{getTranslation("Brand", t)}</p>
            <ReportsDropdown
              name="brandId"
              dataItemKey="brandId"
              data={dropdownData.brandId}
              textfield="brandDescription"
              value={payload.brandId}
              handleOpen={() => handleOpenUsageDropdown("brandId", "brands")}
              handleChange={handleUsageDropdownChange}
            />
          </div>
          <div>
            <p>{getTranslation("Branch", t)}</p>
            <ReportsDropdown
              name="branchId"
              dataItemKey="branchId"
              data={dropdownData.branchId}
              textfield="branchDescription"
              value={payload.branchId}
              handleOpen={() => handleOpenUsageDropdown("branchId", "branches")}
              handleChange={handleUsageDropdownChange}
            />
          </div>

          <div>
            <p>{getTranslation("Role", t)}</p>
            <ReportsDropdown
              name="roleId"
              dataItemKey="roleId"
              data={dropdownData.roleId}
              textfield="roleDescription"
              value={payload.roleId}
              handleOpen={() => handleOpenUsageDropdown("roleId", "roles")}
              handleChange={handleUsageDropdownChange}
            />
          </div>
          <div className="daterange-picker">
            <DateRangePicker
              format={"dd/MM/yyyy"}
              value={usageDateValue}
              onChange={handleDate}
              startDateInput={customStartDateInput}
              endDateInput={customEndDateInput}
            />
          </div>
        </div>
        {usageView === "chart" ? (
          <div className="usage-chart-box">
            <div className="usage-chart-cont">
              <div className="align-self-start">
                <DropDownList
                  data={filterChart}
                  textField="text"
                  value={dropdownValue}
                  onChange={dropdownHandleChange}
                />
              </div>
              <div className="usage-chart-holder align-self-center">
                <Chart
                  pannable={{ lock: "y" }}
                  zoomable={{
                    mousewheel: {
                      lock: "y"
                    },
                    selection: {
                      lock: "y"
                    }
                  }}
                  style={{
                    width:
                      usageChartData.length > 5
                        ? "clamp(500px, 70vw, 100%)"
                        : "50%"
                  }}
                >
                  <ChartLegend position="bottom" orientation="horizontal" />

                  <ChartValueAxis>
                    <ChartValueAxisItem
                      title={{
                        font: "Poppins Bold",
                        text: dropdownValue.text
                      }}
                    />
                  </ChartValueAxis>
                  <ChartSeries>
                    {usageChartData?.map((item, idx) => (
                      <ChartSeriesItem
                        key={idx}
                        type="column"
                        tooltip={{
                          visible: true,
                          format: `${item.name}: {0}`
                        }}
                        color={item.color}
                        data={
                          dropdownValue.value === "Total active days"
                            ? item.totalActiveDays
                            : item.daysSinceLastLogin
                        }
                        name={item.name}
                      />
                    ))}
                  </ChartSeries>
                </Chart>
              </div>
            </div>
          </div>
        ) : (
          <div className="usage-report-cont">
            <div className="usage-grid-cont">
              <Grid
                data={reportsUsageGridList?.map((item) => ({
                  ...item,
                  lastLoginDate: item.lastLoginDate
                    ? new Date(item.lastLoginDate)
                    : null
                }))}
                skip={usageGridSetting.page.skip}
                take={usageGridSetting.page.take}
                total={reportsUsageGridListPage?.totalRecords}
                filter={usageGridSetting.filter}
                sort={usageGridSetting.sort}
                filterable={true}
                sortable={true}
                onSortChange={handleSort}
                onDataStateChange={handleFilterChange}
                pageable={{
                  buttonCount:
                    reportsUsageGridListPage?.totalPages > 10
                      ? 10
                      : reportsUsageGridListPage?.totalPages,
                  info: true,
                  pageSizes: [5, 10, 20],
                  pageSizeValue: usageGridSetting.pageSizeValue
                }}  
                onPageChange={pageChange}
              >
                <Column
                  field="userFullName"
                  title={getTranslation("Name", t)}
                  width="159px"
                />
                <Column
                  field="roleDescription"
                  title={getTranslation("Role", t)}
                  width="200px"
                />
                <Column
                  field="region"
                  title={getTranslation("Region", t)}
                  width="159px"
                />
                <Column
                  field="market"
                  title={`${getTranslation("Market", t)}(${getTranslation(
                    "Country",
                    t
                  )})`}
                  width="159px"
                />
                <Column
                  field="brand"
                  title={getTranslation("Brand", t)}
                  width="174px"
                />
                <Column
                  field="branch"
                  title={getTranslation("Branch", t)}
                  width="159px"
                />
                <Column
                  field="daysSinceLastLogin"
                  title={getTranslation("Days since last login", t)}
                  width="174px"
                  filter="numeric"
                />
                <Column
                  field="lastLoginDate"
                  title={getTranslation("Last login", t)}
                  width="200px"
                  filter="date"
                  format="{0:dd/MM/yyyy}"
                />
                <Column
                  field="totalActiveDays"
                  title={getTranslation("Total active days", t)}
                  width="174px"
                  filter="numeric"
                />
              </Grid>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UsageReport;
