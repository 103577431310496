import { useEffect } from "react";
import moment from "moment";
import store from "../../store";

const IdleTimeOutHandler = (props) => {
  let timer = undefined;
  const events = ["click", "scroll", "load", "keydown"];

  const eventHandler = (eventType) => {
    localStorage.setItem("lastInteractionTime", moment());
    if (timer) {
      startTimer();
    }
  };

  useEffect(() => {
    addEvents();

    return () => {
      removeEvents();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startTimer = () => {
    timer = setTimeout(
      () => {
        let lastInteractionTime = localStorage.getItem("lastInteractionTime");
        const diff = moment.duration(
          moment().diff(moment(lastInteractionTime))
        );
        let timeOutInterval = props.timeOutInterval
          ? props.timeOutInterval
          : 900000;
        if (diff._milliseconds < timeOutInterval) {
          startTimer();
        } else {
          store.dispatch({ type: "SET_AUTH", action: false });
          window.location.replace(`${window.location.origin}/inactive`);
        }
      },
      props.timeOutInterval ? props.timeOutInterval : 900000
    );
  };

  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });

    startTimer();
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  return null;
};

export default IdleTimeOutHandler;
