const BASE_URL =
  "https://azweuglostdevret01.blob.core.windows.net/az-weu-glo-st-dev-ret-cnt-01";

const BASE_URL_BLOB_STORAGE = `https://azweuglostdevret01.blob.core.windows.net/az-weu-glo-st-dev-ret-cnt-01/assets/images/`;

export const getIcon = (name) => {
  return `${BASE_URL}/assets/icons/${name}`;
};

export const fetchImg = (name) => {
  return BASE_URL_BLOB_STORAGE + name;
};
