import React, { cloneElement, useState } from "react";
import "../../styles/serviceAlerts/serviceAlerts.css";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { getIcon } from "../../utils/iconUtils";
import SuccessModal from "../shared/SuccessModal";
import { Pager } from "@progress/kendo-react-data-tools";
import { serviceAlertsGridColumn } from "./gridColumn";
import ColumnHider from "../shared/ColumnHider";
import { Checkbox } from "@progress/kendo-react-inputs";
import {
  validateUserPermission,
  formatCurrency,
  checkMarketAu
} from "../../utils/helpers.js";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import { DateFilterCell } from "../shared/DateFilterCell";

const ServiceAlertsTable = ({
  data,
  serviceAlertsListPage,
  pageChange,
  handleSort,
  handleFilterChange,
  gridSetting,
  alertsModal,
  createAftersalesLeads,
  selectedIvIds,
  setSelectedIvIds,
  marketSettings,
  handleRowClick,
  currentUser,
  unselected,
  setUnselected
}) => {
  const { t } = useTranslation();
  //useStates
  const [showColumnHider, setShowColumnHider] = useState(false);
  const [columns, setColumns] = useState(serviceAlertsGridColumn);

  const isDisabled = data.every((item) => !item.isAllowed);
  const customPager = (props) => {
    return (
      <div className="custom-pager">
        <img
          onClick={() => setShowColumnHider(true)}
          src={getIcon("settings.png")}
          alt="column hider icon"
        />
        <Pager
          skip={props.skip}
          take={props.take}
          type="numeric"
          total={props.total}
          buttonCount={props.buttonCount}
          previousNext={true}
          pageSizes={props.pageSizes}
          pageSizeValue={props.pageSizeValue}
          onPageChange={pageChange}
        />
      </div>
    );
  };

  const handleSelectAll = () => {
    if (selectedIvIds.length > 0 || unselected.length > 0) {
      setSelectedIvIds([]);
      setUnselected([]);
    } else {
      setSelectedIvIds([""]);
    }
  };

  const handleSelect = (dataItem) => {
    if (selectedIvIds.length === 0) {
      if (unselected.includes(dataItem.ivId)) {
        setUnselected(unselected.filter((item) => item !== dataItem.ivId));
      } else {
        setUnselected(unselected.concat(dataItem.ivId));
      }
    } else {
      if (selectedIvIds.includes(dataItem.ivId)) {
        const holdArr = selectedIvIds.filter((item) => item !== dataItem.ivId);
        setSelectedIvIds(holdArr.length > 0 ? holdArr : [""]);
      } else {
        setSelectedIvIds(
          !selectedIvIds[0]
            ? [dataItem.ivId]
            : selectedIvIds.concat(dataItem.ivId)
        );
      }
    }
  };

  const selectAllCell = () => {
    return (
      <div
        onClick={handleSelectAll}
        className="d-flex align-items-center justify-content-center gap-2"
      >
        <Checkbox
          value={selectedIvIds.length === 0 && unselected.length === 0}
        />
        <span className="k-cell-inner">
          <span className="k-column-title">
            {getTranslation("Select All", t)}
          </span>
        </span>
      </div>
    );
  };

  const checkboxCell = ({ dataItem }) => {
    return (
      <td onClick={() => handleSelect(dataItem)}>
        <Checkbox
          disabled={!dataItem.isAllowed}
          value={
            !dataItem.isAllowed
              ? false
              : (selectedIvIds.length === 0 && unselected.length === 0) ||
                (selectedIvIds.length === 0 &&
                  !unselected.includes(dataItem.ivId)) ||
                selectedIvIds.includes(dataItem.ivId)
          }
        />
      </td>
    );
  };

  const rowRender = (trElement, props) => {
    const clickable = props.dataItem.isAllowed;
    const trProps = {
      className: clickable ? "k-selectable" : " k-disabled"
    };
    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  const currencyOptions = {
    symbol: marketSettings.currencySymbol
  };

  //handle pageChange
  const equityCell = ({ dataItem }) => {
    const equityValue = dataItem.equityFormatted;
    return (
      <td colSpan={1} role="gridCell">
        <span className="equity-cell">
          {equityValue
            ? formatCurrency(equityValue, currencyOptions.symbol)
            : null}
        </span>
      </td>
    );
  };

  const currencyCell = (props) => {
    const { dataItem, field } = props;
    const value = dataItem[field];

    return (
      <td colSpan={1} role="gridCell" className="k-table-td">
        {value ? formatCurrency(value, currencyOptions.symbol) : null}
      </td>
    );
  };

  return (
    <div className="service-alerts-grid">
      <div className="mb-3">
        <Grid
          data={data?.map((item) => ({
            ...item,
            lastActivityDate: item.lastActivityDate
              ? new Date(item.lastActivityDate)
              : null
          }))}
          filterable={true}
          skip={gridSetting.page.skip}
          take={gridSetting.page.take}
          sort={gridSetting.sort}
          filter={gridSetting.filter}
          total={serviceAlertsListPage.totalRecords}
          sortable={true}
          onSortChange={handleSort}
          onDataStateChange={handleFilterChange}
          pager={customPager}
          onPageChange={pageChange}
          pageable={{
            buttonCount:
              serviceAlertsListPage.totalPages > 10
                ? 10
                : serviceAlertsListPage.totalPages,
            info: true,
            pageSizes: [5, 10, 20],
            pageSizeValue: gridSetting.pageSizeValue
          }}
          onRowClick={({ dataItem }) => handleRowClick(dataItem)}
          rowRender={rowRender}
        >
          {columns.map((column, idx) => {
            if (idx === 0) {
              return validateUserPermission(
                currentUser.permissions,
                "canCreate",
                "Service Alerts"
              ) ? (
                <Column
                  key={idx}
                  filterable={false}
                  width={column.width}
                  field={column.column}
                  headerCell={selectAllCell}
                  cell={checkboxCell}
                />
              ) : null;
            } else if (column.title === "Equity") {
              return column.isShown ? (
                <Column
                  key={idx}
                  field={column.column}
                  width={column.width}
                  title={getTranslation(column.title, t)}
                  cell={
                    column.column === "equityFormatted" ? equityCell : undefined
                  }
                  filterable={column.filter}
                  filter={column.filter}
                />
              ) : null;
            } else if (
              [
                getTranslation("Payment Diff", t),
                getTranslation("Cost to Change", t),
                getTranslation("New Payment", t),
                getTranslation("Payment", t)
              ].includes(getTranslation(column.title, t))
            ) {
              return column.isShown ? (
                <Column
                  key={idx}
                  field={column.column}
                  width={column.width}
                  cell={currencyCell}
                  title={getTranslation(column.title, t)}
                  filterable={true}
                />
              ) : null;
            } else if (
              [getTranslation("Customer Name", t)].includes(
                getTranslation(column.title, t)
              )
            ) {
              return column.isShown && !checkMarketAu() ? (
                <Column
                  key={idx}
                  field={column.column}
                  width={column.width}
                  title={getTranslation(column.title, t)}
                  filterable={true}
                />
              ) : null;
            } else {
              return column.isShown ? (
                <Column
                  key={idx}
                  field={column.column}
                  width={column.width}
                  title={getTranslation(column.title, t)}
                  filterable={true}
                  filterCell={
                    column.filter === "date" ? DateFilterCell : undefined
                  }
                  format={
                    column.filter === "date" ? "{0:dd/MM/yyyy}" : undefined
                  }
                />
              ) : null;
            }
          })}
        </Grid>
      </div>
      <div>
        {validateUserPermission(
          currentUser.permissions,
          "canCreate",
          "Service Alerts"
        ) && (
          <button
            className="primary-btn"
            onClick={createAftersalesLeads}
            disabled={selectedIvIds[0] === "" || isDisabled}
          >
            <img src={getIcon("plus.svg")} alt="search icon" />
            <span>{getTranslation("Create aftersales leads", t)}</span>
          </button>
        )}
      </div>

      {alertsModal && (
        <SuccessModal
          toggleSuccessModal={createAftersalesLeads}
          message={"Aftersales leads made successfully"}
        />
      )}
      {showColumnHider && (
        <ColumnHider
          closeHider={() => setShowColumnHider(false)}
          columns={
            checkMarketAu()
              ? columns.filter(
                  (column) =>
                    getTranslation(column.title, t) !==
                    getTranslation("Customer Name", t)
                )
              : columns
          }
          setColumns={setColumns}
        />
      )}
    </div>
  );
};

export default ServiceAlertsTable;
