import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_MARKET_SETTINGS } from "../actions/types";
import { setMarketSettings, setLoading } from "../actions";
import { getMarketSettingsApi } from "../api/handler";

function* handleLoadMarketSettings(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getMarketSettingsApi);
    if (response.status === 200) {
      yield put(setMarketSettings(response.data.data));
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

export default function* marketSettingsSaga() {
  yield all([takeLatest(GET_MARKET_SETTINGS, handleLoadMarketSettings)]);
}
