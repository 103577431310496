import Cookies from "universal-cookie";

// null instance
let instance = null;

// Create if the instance is not created
// this better for performance purposes
if (instance === null) {
  instance = new Cookies();
}

const Cookie = {
  get: (key) => {
    return instance.get(key);
  },
  set: (key, value, options) => {
    // if (!options) {
    //   let separate = window.location.hostname.split(".");
    //   separate.shift();
    //   const currentdomain = separate.join(".");
    //   options = {
    //     domain: "." + currentdomain,
    //     path: "/"
    //   };
    // }
    // return instance.set(key, value, options);
    return instance.set(key, value, { path: "/" });
  },
  remove: (key, options) => {
    // if (!options) {
    //   let separate = window.location.hostname.split(".");
    //   separate.shift();
    //   const currentdomain = separate.join(".");
    //   options = {
    //     domain: "." + currentdomain,
    //     path: "/"
    //   };
    // }
    // return instance.remove(key, options);
    return instance.remove(key, { path: "/" });
  },
  token: () => instance.get("access_token")
};

export default Cookie;
