import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import fetchClient from "../../../../api/fetch";

const PingLogin = () => {
  const Navigate = useNavigate();
  const [data, setData] = useState({
    email: null,
    samlSessionId: null,
  });

  const pushLogin = (e) => {
    e.preventDefault();
    fetchClient()
      .post(`/v1/auth`, data)
      .then((res) => {
        console.log(res.data.data);
        Navigate(`/login?sid=${data.samlSessionId}`);
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <div
        style={{
          width: "380px",
          height: "340px",
          border: "1px solid #666666",
          padding: "20px 30px",
          display: "flex",
          flexDirection: "column",
          margin: " 2rem auto",
          gap: "1.5rem",
          alignItems: "center",
        }}
      >
        <h1>Single Sign On</h1>
        <form className="d-flex flex-column">
          <div class="form-group mb-3">
            <label for="exampleInputEmail1">Email address:</label>
            <input
              type="email"
              class="form-control"
              name="email"
              placeholder="Enter email"
              onChange={handleChange}
            />
          </div>
          <div class="form-group mb-4">
            <label>Enter Session Id:</label>
            <input
              type="text"
              class="form-control"
              name="samlSessionId"
              placeholder="Enter Session Id"
              onChange={handleChange}
            />
          </div>
          <div className="align-self-center">
            <button class="btn btn-dark" onClick={(e) => pushLogin(e)}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PingLogin;
