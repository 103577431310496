import React from "react";
import "../../styles/campaigns/campaignBuilder.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const BuilderExistingDeal = ({
  showVS,
  mode,
  financeTypeData,
  financeTypeValue,
  handleDropdownChange,
  payload,
  uncheckedIds,
  totalRecords,
  handleDecimalNumberChange,
  handleIntNumberChange,
  blockInvalidChar,
  blockDotInvalidChar,
  existRegDate,
  invalidRegDate,
  handleDateChange,
  hasFilters,
  handleOpenFinanceTypeDropdown
}) => {
  const { t } = useTranslation();

  return (
    <div className="existing-deal">
      <h2>{getTranslation("Existing deal", t)}</h2>
      <div className="existing-deal-cont">
        <div>
          <p>{getTranslation("Finance type", t)}</p>
          <DropDownList
            name="existingFinanceType"
            data={financeTypeData}
            onOpen={handleOpenFinanceTypeDropdown}
            textField="textField"
            value={financeTypeValue.existingFinanceType}
            onChange={handleDropdownChange}
          />
        </div>
        <div>
          <p>{getTranslation("Vehicle", t)}</p>
          <Input
            readOnly={true}
            value={
              mode === "edit" && totalRecords.existingVS === 0
                ? getTranslation("All", t)
                : payload.campaignExistingVehicles.length === 0
                ? uncheckedIds.existingVS.length > 0
                  ? totalRecords.existingVS - uncheckedIds.existingVS.length > 0
                    ? `${
                        totalRecords.existingVS - uncheckedIds.existingVS.length
                      } `+ getTranslation("items selected",t)
                    : "All"
                  : hasFilters
                  ? `${totalRecords.existingVS} `+ getTranslation("items selected",t)
                  : getTranslation("All", t)
                : `${payload.campaignExistingVehicles.length} `+ getTranslation("items selected",t)
            }
            className="k-input-flat"
          />
        </div>
        <div onClick={showVS}>
          <button className="secondary-btn w-100">
            {getTranslation("Vehicles selector", t)}
          </button>
        </div>
        <div>
          <p>{getTranslation("Vehicle registration date", t)}</p>
          <div className="d-flex align-items-end">
            <DatePicker
              className="k-input-flat"
              value={existRegDate.start}
              name="existingRegistrationDateFrom"
              onChange={handleDateChange}
              format={"dd/MM/yyyy"}
            />
            <span className="span-to">{getTranslation("to", t)}</span>
            <DatePicker
              className="k-input-flat"
              value={existRegDate.end}
              name="existingRegistrationDateTo"
              onChange={handleDateChange}
              format={"dd/MM/yyyy"}
            />
          </div>
          {invalidRegDate && (
            <span className="error-input-text">{invalidRegDate}</span>
          )}
        </div>
        <div>
          <p>{getTranslation("Original finance term", t)}</p>
          <div className="d-flex align-items-end">
            <Input
              min={0}
              step={1}
              type="number"
              className="k-input-flat"
              onKeyDown={blockDotInvalidChar}
              onChange={handleIntNumberChange}
              name="existingOriginalFinanceTermFrom"
              value={payload.existingOriginalFinanceTermFrom}
            />
            <span className="span-to">{getTranslation("to", t)}</span>
            <Input
              min={0}
              step={1}
              type="number"
              className="k-input-flat"
              onKeyDown={blockDotInvalidChar}
              onChange={handleIntNumberChange}
              name="existingOriginalFinanceTermTo"
              value={payload.existingOriginalFinanceTermTo}
            />
          </div>
        </div>
        <div>
          <p>{getTranslation("Percent elapsed", t)}</p>
          <div className="d-flex align-items-end">
            <Input
              min={0}
              step="0.01"
              type="number"
              className="k-input-flat"
              onKeyDown={blockInvalidChar}
              name="existingPercentElapsedFrom"
              onChange={handleDecimalNumberChange}
              value={payload.existingPercentElapsedFrom}
            />
            <span className="span-to">{getTranslation("to", t)}</span>
            <Input
              min={0}
              step="0.01"
              type="number"
              className="k-input-flat elapsed-input"
              onKeyDown={blockInvalidChar}
              name="existingPercentElapsedTo"
              onChange={handleDecimalNumberChange}
              value={payload.existingPercentElapsedTo}
            />
            <span className="percent">%</span>
          </div>
        </div>
        <div>
          <p>{getTranslation("Months remaining", t)}</p>
          <div className="d-flex align-items-end">
            <Input
              min={0}
              step={1}
              type="number"
              className="k-input-flat"
              onKeyDown={blockDotInvalidChar}
              onChange={handleIntNumberChange}
              name="existingMonthsRemainingFrom"
              value={payload.existingMonthsRemainingFrom}
            />
            <span className="span-to">{getTranslation("to", t)}</span>
            <Input
              min={0}
              step={1}
              type="number"
              className="k-input-flat"
              onKeyDown={blockDotInvalidChar}
              onChange={handleIntNumberChange}
              name="existingMonthsRemainingTo"
              value={payload.existingMonthsRemainingTo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuilderExistingDeal;
