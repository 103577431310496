import React from "react";
import "../../styles/campaigns/campaignBuilder.css";
import { Dialog } from "@progress/kendo-react-dialogs";
import { getIcon } from "../../utils/iconUtils";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const InterestRateModalConfirmation = ({
  handleProceedSave,
  handleCancelSave
}) => {  
  const { t } = useTranslation();
  return (
    <>
      <Dialog>
        <div className="campaign-interest-modal-confirmation d-flex flex-column">
          <img
            className="close"
            onClick={handleCancelSave}
            src={getIcon("close.svg")}
            alt="close icon"
          />
          <p>{getTranslation("Do you want to save changes on interest rate?", t)}</p>
          <span className="text-center">
            {getTranslation("All of the finance calculations for this campaign will override the default interest rate.", t)}
          </span>
          <div className="btn-group d-flex justify-content-evenly">
            <button className="secondary-btn" onClick={handleCancelSave}>
              {getTranslation("Cancel", t)}
            </button>
            <button className="primary-btn" onClick={handleProceedSave}>
              {getTranslation("Proceed", t)}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default InterestRateModalConfirmation;
