/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getIcon } from "../../utils/iconUtils";
import { Checkbox } from "@progress/kendo-react-inputs";
import "../../styles/campaigns/campaignLeads.css";
import { campaignLeadsListColumns } from "./gridColumns";
import { useEffect, useState, useRef } from "react";
import { Pager } from "@progress/kendo-react-data-tools";
import ColumnHider from "../shared/ColumnHider";
import { getStatusClass } from "../../utils/statusUtil";
import fetchClient from "../../api/fetch";
import {
  checkMarketAu,
  formatCurrency,
  validateUserPermission
} from "../../utils/helpers";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import LeadsBulkAction from "./LeadsBulkAction";
import { DateFilterCell } from "../shared/DateFilterCell";
import defaultMs from "../../api/debounce";
import { format } from "date-fns";

const CampaignLeads = ({
  getCampaignLeadsList,
  campaignLeadsList,
  campaignLeadsListPage,
  currentUser,
  exportCampaignToExcel,
  setLoading,
  marketSettings
}) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [columns, setColumns] = useState(
    checkMarketAu()
      ? campaignLeadsListColumns
      : campaignLeadsListColumns.filter(
          (column) => column.title !== "Proposed Vehicle"
        )
  );
  const [showColumnHider, setShowColumnHider] = useState(false);
  const [gridSetting, setGridSetting] = useState({
    sort: [],
    filter: undefined,
    pageSizeValue: 10,
    page: {
      skip: 0,
      take: 10
    }
  });
  const [searchValue, setSearchValue] = useState("");
  const [params, setParams] = useState({
    sort: "",
    search: "",
    filter: ""
  });
  const [campaignType, setCampaignType] = useState({});
  const [campaignName, setCampaignName] = useState("");
  const [selectedLeads, setSelectedLeads] = useState([""]);
  const [showBulkActionModal, setShowBulkActionModal] = useState(false);
  const [unselected, setUnselected] = useState([]);
  const firstRender = useRef(true);

  useEffect(() => {
    fetchClient()
      .get(`/v1/campaigns/${id}`)
      .then((res) => {
        const resData = res.data.data;
        setCampaignType(resData.campaignType);
        setCampaignName(resData.campaignName);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      if (localStorage.getItem("campaignLeadsFilters")) {
        setLoading(true);
        const campaignLeadsFilters = JSON.parse(
          localStorage.getItem("campaignLeadsFilters")
        );
        setGridSetting({
          ...gridSetting,
          page: campaignLeadsFilters.page,
          sort: campaignLeadsFilters.sort,
          filter: {
            ...campaignLeadsFilters.filter,
            filters: campaignLeadsFilters.filter?.filters?.map((filter) => {
              return ["lastActivity"].includes(filter.field)
                ? { ...filter, value: new Date(filter.value) }
                : filter;
            })
          },
          pageSizeValue: campaignLeadsFilters.pageSizeValue
        });
        setSelectedLeads(campaignLeadsFilters.selectedLeads);
        setSearchValue(campaignLeadsFilters.searchValue);
        setParams(campaignLeadsFilters.params);
        localStorage.removeItem("campaignLeadsFilters");
      } else {
        getCampaignLeadsList(id, queryBuilder());
      }
      firstRender.current = false;
    } else {
      const getData = setTimeout(
        () => getCampaignLeadsList(id, queryBuilder()),
        defaultMs
      );
      return () => clearTimeout(getData);
    }
  }, [id, params]);

  const queryBuilder = (newpage = null) => {
    const filter = `filters=[${params.filter.concat(params.search)}]`;

    return `?${filter}${params.sort}&page=${JSON.stringify(
      newpage ? newpage : gridSetting.page
    )}`;
  };

  const handleBack = () => {
    switch (location.state?.prevPage) {
      case "campaigns":
        Navigate(`/campaigns`, {
          state: { prevPage: location.state?.prevPage }
        });
        break;
      case "dashboard":
        Navigate(`/dashboard`, {
          state: { prevPage: location.state?.prevPage }
        });
        break;
      default:
        Navigate("/dashboard");
        break;
    }
  };

  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.take;
    setGridSetting({
      ...gridSetting,
      pageSizeValue: targetEvent.value
        ? targetEvent.value
        : gridSetting.pageSizeValue,
      page: {
        ...gridSetting.page,
        skip: e.skip,
        take
      }
    });
    getCampaignLeadsList(
      id,
      queryBuilder({ ...gridSetting.page, skip: e.skip, take })
    );
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value) {
      setParams({
        ...params,
        search: JSON.stringify({
          field: "customerName",
          value: e.target.value,
          condition: "contains"
        })
      });
      // setPage({ ...page, skip: 0 });
      setGridSetting({
        ...gridSetting,
        page: {
          skip: 0
        }
      });
    } else {
      setParams({
        ...params,
        search: ""
      });
    }
  };

  const handleSort = (e) => {
    setGridSetting({
      ...gridSetting,
      sort: e.sort
    });
    if (e.sort.length > 0) {
      let obj = { ...e.sort[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      setParams({ ...params, sort: `&sort=${obj}` });
    } else {
      setParams({ ...params, sort: "" });
    }
  };

  const handleFilterChange = (e) => {
    setGridSetting({
      ...gridSetting,
      filter: e.dataState.filter
    });
    if (e.dataState.filter !== undefined) {
      setParams({
        ...params,
        filter: e.dataState.filter.filters.map((item) => {
          let obj = { ...item };
          if (obj.value instanceof Date) {
            obj.value = format(obj.value, 'yyyy-MM-dd');
          }
          obj["condition"] = obj["operator"];
          delete obj["operator"];
          obj = JSON.stringify(obj);
          return obj;
        })
      });
      setGridSetting((prevState) => ({
        ...prevState,
        page: { ...prevState.page, skip: 0 }
      }));
    } else {
      setParams({ ...params, filter: "" });
    }
  };

  const handleExportCampaign = () => {
    exportCampaignToExcel(id);
  };

  const handleSelectAll = () => {
    if (selectedLeads.length > 0 || unselected.length > 0) {
      setSelectedLeads([]);
      setUnselected([]);
    } else {
      setSelectedLeads([""]);
    }
  };

  const handleSelect = (dataItem) => {
    if (selectedLeads.length === 0) {
      if (unselected.includes(dataItem.leadId)) {
        setUnselected(unselected.filter((item) => item !== dataItem.leadId));
      } else {
        setUnselected(unselected.concat(dataItem.leadId));
      }
    } else {
      if (selectedLeads.includes(dataItem.leadId)) {
        const holdArr = selectedLeads.filter(
          (item) => item !== dataItem.leadId
        );
        setSelectedLeads(holdArr.length > 0 ? holdArr : [""]);
      } else {
        setSelectedLeads(
          !selectedLeads[0]
            ? [dataItem.leadId]
            : selectedLeads.concat(dataItem.leadId)
        );
      }
    }
  };

  const handleLeadsRowClick = (dataItem) => {
    // save filter settings
    localStorage.setItem(
      "campaignLeadsFilters",
      JSON.stringify({
        sort: gridSetting.sort,
        page: gridSetting.page,
        filter: gridSetting.filter,
        pageSizeValue: gridSetting.pageSizeValue,
        selectedLeads: selectedLeads,
        searchValue: searchValue,
        params: params
      })
    );
    Navigate(`/customer-record/${dataItem.leadId}`, {
      state: {
        prevPage: location.state?.prevPage,
        campaignId: dataItem.campaignId
      }
    });
  };

  const selectAllCell = () => {
    return (
      <span className="k-cell-inner" onClick={handleSelectAll}>
        <span className="k-link">
          <span>
            <Checkbox
              value={selectedLeads.length === 0 && unselected.length === 0}
            />
          </span>
          <span className="k-column-title select-all">
            {getTranslation("Select all", t)}
          </span>
        </span>
      </span>
    );
  };

  const checkboxCell = ({ dataItem }) => {
    return (
      <td className="text-center" onClick={() => handleSelect(dataItem)}>
        <Checkbox
          value={
            dataItem.statusName === "Record"
              ? false
              : (selectedLeads.length === 0 && unselected.length === 0) ||
                (selectedLeads.length === 0 &&
                  !unselected.includes(dataItem.leadId)) ||
                selectedLeads.includes(dataItem.leadId)
          }
        />
      </td>
    );
  };

  const customName = (props) => {
    const { dataItem, field } = props;
    const customerName = dataItem[field];

    return (
      <td role="gridCell" className="k-table-td">
        <div onClick={() => handleLeadsRowClick(dataItem)}>
          {checkMarketAu() ? <span>-</span> : <span>{customerName}</span>}
        </div>
      </td>
    );
  };

  const statusCell = (props) => {
    const { dataItem } = props;
    const { status } = dataItem;
    return (
      <td className="status-row-cell">
        <div
          className={`status-holder ${getStatusClass(status)}`}
          onClick={() => handleLeadsRowClick(dataItem)}
        >
          <span className="status-label">{status}</span>
        </div>
      </td>
    );
  };

  const currencyCell = (props) => {
    const { dataItem, field } = props;
    const value = dataItem[field];
    const currency = marketSettings.currencySymbol;

    return (
      <td colSpan={1} role="gridCell" className="k-table-td">
        {`${formatCurrency(value, currency)}`}
      </td>
    );
  };

  const customPager = (props) => {
    return (
      <div className="custom-pager">
        <div className="imgs">
          <img
            onClick={() => setShowColumnHider(true)}
            src={getIcon("settings.png")}
            alt="column hider icon"
          />
        </div>
        <Pager
          skip={props.skip}
          take={props.take}
          type="numeric"
          total={props.total}
          buttonCount={props.buttonCount}
          previousNext={true}
          pageSizes={props.pageSizes}
          pageSizeValue={props.pageSizeValue}
          onPageChange={pageChange}
        />
      </div>
    );
  };

  return (
    <div className="campaign-leads">
      <div className="d-flex gap-5 align-items-center mb-4">
        <img
          onClick={handleBack}
          src={getIcon("arrow-back.svg")}
          alt="back icon"
        />
        <p className="h1">{campaignName}</p>
        {campaignType === 1 && (
          <>
            {validateUserPermission(
              currentUser.permissions,
              "canEdit",
              "Campaigns"
            ) && (
              <button
                className="edit-campaign primary-btn"
                onClick={() => {
                  Navigate(`/campaigns/${id}`, {
                    state: {
                      prevPage: location.state?.prevPage
                        ? location.state.prevPage
                        : "campaigns"
                    }
                  });
                }}
              >
                <p className="m-0">{getTranslation("Edit campaign", t)}</p>
              </button>
            )}
          </>
        )}
      </div>
      <div className="module-cont">
        <div className={"header d-flex grid-view gap-4"}>
          <div className="search d-flex gap-4">
            <div className="d-flex flex-column">
              <label>{getTranslation("Search", t)}</label>
              <input
                name="search"
                onChange={handleSearch}
                className="primary-input"
                type="text"
                value={searchValue}
              />
            </div>
            <button className="primary-btn">
              <img src={getIcon("searchInput.png")} alt="search icon" />
            </button>
          </div>
          {validateUserPermission(
            currentUser.permissions,
            "canEdit",
            "Leads"
          ) && (
            <button
              className="primary-btn"
              disabled={selectedLeads[0] === ""}
              onClick={() => setShowBulkActionModal(true)}
            >
              <p className="m-0">{getTranslation("Bulk actions", t)}</p>
            </button>
          )}
          <button className="secondary-btn" onClick={handleExportCampaign}>
            <p className="m-0">{getTranslation("Export", t)}</p>
          </button>
        </div>
        <div className={"grid-cont grid-view"}>
          <Grid
            data={campaignLeadsList.map((item) => ({
              ...item,
              lastActivity: new Date(item.lastActivity),
              financeType: getTranslation(item.financeType, t),
              type: item.type
            }))}
            skip={gridSetting.page.skip}
            take={gridSetting.page.take}
            total={campaignLeadsListPage.totalRecords}
            filterable={true}
            filter={gridSetting.filter}
            sortable={true}
            sort={gridSetting.sort}
            onSortChange={handleSort}
            onDataStateChange={handleFilterChange}
            pager={customPager}
            onRowClick={({ dataItem }) => handleLeadsRowClick(dataItem)}
            pageable={{
              buttonCount:
                campaignLeadsListPage.totalPages > 10
                  ? 10
                  : campaignLeadsListPage.totalPages,
              pageSizes: [5, 10, 15],
              pageSizeValue: gridSetting.pageSizeValue
            }}
          >
            {columns.map((column, idx) => {
              if (idx === 0) {
                return validateUserPermission(
                  currentUser.permissions,
                  "canEdit",
                  "Leads"
                ) ? (
                  <Column
                    key={idx}
                    width={column.width}
                    cell={checkboxCell}
                    headerCell={selectAllCell}
                    filterable={false}
                    title={getTranslation(column.title, t)}
                  />
                ) : null;
              } else if (idx === 2) {
                return column.isShown ? (
                  <Column
                    key={idx}
                    field={column.column}
                    width={column.width}
                    cell={statusCell}
                    title={getTranslation(column.title, t)}
                    filterable={true}
                    filter={column.filter}
                  />
                ) : null;
              } else if (
                [getTranslation("Customer Name", t)].includes(
                  getTranslation(column.title, t)
                )
              ) {
                return column.isShown && !checkMarketAu() ? (
                  <Column
                    key={idx}
                    field={column.column}
                    width={column.width}
                    cell={customName}
                    title={getTranslation(column.title, t)}
                    filterable={true}
                  />
                ) : null;
              } else if (
                [getTranslation("Payment Difference", t)].includes(
                  getTranslation(column.title, t)
                )
              ) {
                return column.isShown ? (
                  <Column
                    key={idx}
                    field={column.column}
                    width={column.width}
                    cell={currencyCell}
                    title={getTranslation(column.title, t)}
                    filterable={true}
                  />
                ) : null;
              } else {
                return column.isShown ? (
                  <Column
                    key={idx}
                    field={column.column}
                    width={column.width}
                    title={getTranslation(column.title, t)}
                    filterable={column.filter}
                    filter={column.filter}
                    filterCell={
                      column.filter === "date" ? DateFilterCell : undefined
                    }
                    format={
                      column.filter === "date" ? "{0:dd/MM/yyyy}" : undefined
                    }
                  />
                ) : null;
              }
            })}
          </Grid>
        </div>
      </div>
      {showColumnHider && (
        <ColumnHider
          closeHider={() => setShowColumnHider(false)}
          columns={columns}
          setColumns={setColumns}
        />
      )}
      {showBulkActionModal && (
        <LeadsBulkAction
          closeDialog={() => setShowBulkActionModal(false)}
          leadIds={selectedLeads}
          setLoading={setLoading}
          queryBuilder={queryBuilder}
          getCampaignLeadsList={getCampaignLeadsList}
          setSelectedLeads={setSelectedLeads}
          filter={params.filter}
          unselected={unselected}
          setUnselected={setUnselected}
          id={id}
          currentUser={currentUser}
        />
      )}
    </div>
  );
};

export default CampaignLeads;
