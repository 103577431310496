export const newOrUsedData = [
  {
    newOrUsed: "New",
    textField: "New",
    value: "new"
  },
  {
    newOrUsed: "Used",
    textField: "Used",
    value: "used"
  }
];

export const activityData = [
  {
    activityType: "Lead created",
    textField: "Lead created",
    value: 1
  },
  {
    activityType: "Lead changed",
    textField: "Lead changed",
    value: 2
  },
  {
    activityType: "Lead removed",
    textField: "Lead removed",
    value: 3
  }
];

export const leadData = [
  {
    leadType: "Dealer",
    textField: "Dealer",
    value: 1
  },
  {
    leadType: "Aftersales",
    textField: "Aftersales",
    value: 2
  },
  {
    leadType: "Central",
    textField: "Central",
    value: 3
  }
];
